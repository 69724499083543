import React, { useCallback } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import styled from 'styled-components';

import { Card } from '../../../components/card/card';
import { Loading } from '../../../components/loading/loading';
import { PageHeader } from '../../../layout/body/pageHeader';
import { useWorldRequest } from '../../../lib/useWorldRequest';
import { getCorePerformanceDataUsage, getCorePerformanceEvent, getCorePerformanceDevicesUtilization, IDeviceEventCount } from '../../../services/core/performance';
import { CorePerformanceDevicesUtilization } from './charts/corePerformanceDevicesUtilization';
import { CorePerformanceDataUsage } from './charts/corePerformanceDataUsage';
import { CorePerformanceEvents } from './charts/corePerformanceEvents';
import { RequestInitWithRetry } from '../../../lib/request';
import { getUserTimeZone } from '../../../lib/timeZone';
import { useAlignCharts } from '../../../lib/useAlignCharts';
import { useFilterPicker } from '../../../components/timePeriodAndFilterPicker/useFilterPicker';
import { TimePeriodAndFilterPicker } from '../../../components/timePeriodAndFilterPicker/timePeriodAndFilterPicker';
import { useTimePeriodPicker } from '../../../components/timePeriodAndFilterPicker/useTimePeriodPicker';

import './core-performance.css';

export const timePeriods = [30, 60, 90];

const TimePeriodAndFilterSelection = styled(TimePeriodAndFilterPicker)`
    border-bottom: 1px solid #d7e2eb;
`;

export const CorePerformance = () => {
  const { t } = useTranslation('translation');
  const timeZone = getUserTimeZone();
  const chartCount = 7;
  const { addChart, clearCharts } = useAlignCharts(chartCount);

  const {
    filter,
    optionsLoading,
    ...filterPickerProps
  } = useFilterPicker();
  const {
    timePeriod,
    ...timePeriodPickerProps
  } = useTimePeriodPicker({ timePeriods });


  const dataFetcher = useCallback(() => {
    clearCharts();
    const params = {
      days: timePeriod.value,
      homeLocationId: filter.homeLocation?.value.id,
      group: filter.group?.value,
      zoneId: filter.zone?.value.id,
      timeZone
    };
    return (options: RequestInitWithRetry) => {
      return Promise.all([
        getCorePerformanceDevicesUtilization({ ...params })(options),
        getCorePerformanceDataUsage({ ...params })(options),
        getCorePerformanceEvent({ name: 'deviceRebooted', ...params })(options),
        getCorePerformanceEvent({ name: 'deviceLowPower', ...params })(options),
        getCorePerformanceEvent({ name: 'timeError', ...params })(options),
        getCorePerformanceEvent({ name: 'batteryChanged', ...params })(options),
        getCorePerformanceEvent({ name: 'deviceDropped', ...params })(options)
      ]);
    };
  }, [clearCharts, timeZone, filter, timePeriod]);

  const {
    loading: dataLoading,
    data: [devicesUtilizationData, dataUsageData, deviceRebootedData, deviceLowPowerData, timeErrorData, batteryChangedData, deviceDroppedData]
  } = useWorldRequest(dataFetcher, { initialData: [[], [], [], [], [], [], []], initialLoading: true });

  return (
    <div className="core-performance-container">
      <Card className="core-performance" noPadding>
        <PageHeader header={t('PERFORMANCE')} />
        <TimePeriodAndFilterSelection
          filter={filter}
          {...filterPickerProps}
          timePeriod={timePeriod}
          {...timePeriodPickerProps}
        />
        <Loading isLoading={dataLoading || optionsLoading} transparentOverlay={false}>
          <div className="core-performance__chart__devices-utilization"><CorePerformanceDevicesUtilization data={devicesUtilizationData} days={timePeriod.value} filter={filter} onMount={addChart}/></div>
          <div className="core-performance__chart__data-usage"><CorePerformanceDataUsage data={dataUsageData} days={timePeriod.value} filter={filter} onMount={addChart} /></div>
          <div className="core-performance__chart__reboots"><CorePerformanceEvents data={deviceRebootedData} days={timePeriod.value} filter={filter} name="deviceRebooted" onMount={addChart} /></div>
          <div className="core-performance__chart__low-power"><CorePerformanceEvents data={deviceLowPowerData} days={timePeriod.value} filter={filter} name="deviceLowPower" onMount={addChart} /></div>
          <div className="core-performance__chart__time-errors"><CorePerformanceEvents data={timeErrorData} days={timePeriod.value} filter={filter} name="timeError" onMount={addChart} /></div>
          <div className="core-performance__chart__battery-swaps"><CorePerformanceEvents data={batteryChangedData} days={timePeriod.value} filter={filter} name="batteryChanged" onMount={addChart} /></div>
          <div className="core-performance__chart__drops"><CorePerformanceEvents data={deviceDroppedData} days={timePeriod.value} filter={filter} name="deviceDropped" onMount={addChart} /></div>
        </Loading>
      </Card>
    </div>
  );
};

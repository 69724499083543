import * as React from 'react';
import { compact, union } from 'lodash';
import './tabs.css';

export interface ITabControlProps {
  defaultTabIndex?: number,
  enableStatePreservation?: boolean,
  orientation?: 'default' | 'vertical',
  tabAlignment?: 'start' | 'end'
}

export interface ITabControlState {
  selected: number,
  renderedContent: number[]
}

export interface IBaseTabProps {
  id?: string,
  index?: number, //always set by TabPanel
  selected?: boolean, //always set by TabPanel
  onClick?: (index: number) => void, //always set by TabPanel,
  children?: any,
  passSelectedToChild?: boolean
}

export enum ITabComponentType {
  Tab = "Tab",
  Header = "TabPanelHeader"
}

export class TabPanel extends React.Component<React.PropsWithChildren<ITabControlProps>, ITabControlState> {
  constructor(props: ITabControlProps) {
    super(props);
    const selected = props.defaultTabIndex || 0;
    this.state = {
      selected: selected,
      renderedContent: [selected]
    };

    this.selectTab = this.selectTab.bind(this);
  }

  componentDidUpdate(prevProps: ITabControlProps) {
    if (prevProps.defaultTabIndex !== this.props.defaultTabIndex) {
      this.setState(TabPanel.getSelectedTabState(this.props.defaultTabIndex, this.state.renderedContent));
    }
  }

  public render() {
    let tabId = 0;
    let header: React.ReactElement;

    const tabsContent: any[] = [];
    const tabs = compact<React.ReactElement>(React.Children.map(this.props.children, (child: any) => {
      const typeTag = child && child.type ? child.type.Tag : undefined;
      if (typeTag === ITabComponentType.Tab) {
        const id = tabId++;
        tabsContent.push(child.props.children);
        return React.cloneElement(child as React.ReactElement<IBaseTabProps>, { index: id, key: id, selected: this.state.selected === id, onClick: this.selectTab(child.props.onClick) });
      }
      if (typeTag === ITabComponentType.Header) {
        header = child;
      }
      return undefined;
    }));

    const currentIndex = Math.min(tabId, this.state.selected);
    const content = this.state.renderedContent.map(contentIndex => {
      const currentContentId = tabs[contentIndex]?.props?.id;
      const isSelected = contentIndex === currentIndex;
      if (!this.props.enableStatePreservation && !isSelected) {
        return undefined;
      }

      let tabContent;
      if (tabs.length) {
        if (tabs[contentIndex].props.passSelectedToChild) {
          tabContent = React.cloneElement(tabsContent[contentIndex], { selected: isSelected, key: contentIndex }, tabsContent[contentIndex].props.children);
        } else {
          tabContent = tabsContent[contentIndex];
        }
      }

      return <div key={currentContentId || contentIndex.toString()} id={currentContentId} data-id='tab-content' className={isSelected ? "tab-content" : "tab-content-invisible"}>
        {tabContent}
      </div>;
    });

    const outsideTitle = this.props.orientation === 'vertical' ? <div data-id='nav-title' className="nav-title">{header}</div> : '';
    const insideTitle = this.props.orientation !== 'vertical' ? <div data-id='nav-title' className="nav-title">{header}</div> : '';

    return <div data-id='tab-panel' className={`tab-panel ${this.props.orientation || 'default'} ${this.props.tabAlignment || 'end'}`}>
      {outsideTitle}
      <div data-id='tab-panel-content' className='tab-panel-content'>
        <div data-id='nav-tabs' className="nav-tabs">
          {insideTitle}
          <ul data-id='nav-header' className="nav nav-header">
            {tabs}
          </ul>
        </div>
        {content}
      </div>
    </div>;
  }

  private selectTab(onClick?: (i: number) => void) {
    return (index: number) => {
      onClick && onClick(index);
      this.setState(TabPanel.getSelectedTabState(index, this.state.renderedContent));
    };
  }

  private static getSelectedTabState(index: number, renderedContent: number[]) {
    return { selected: index || 0, renderedContent: union(renderedContent, [index || 0]) };
  }
}

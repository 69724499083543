import { Button } from "@components/controls/button";
import styled from "styled-components";

export const totalLimit = 10000;

export const getPageFromOffset = (offset: number, limit: number) => {
  return Math.floor(offset / limit) + 1;
};

export const getOffsetFromPage = (page: number, limit: number) => {
  return (page - 1) * limit;
};

export const StyledPaginationSelectButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 28px;
    height: 28px;
  `;

StyledPaginationSelectButton.displayName = 'PaginationSelectButton';

export function getListTotal(totalItems: number) {
  return Math.min(totalItems, totalLimit);
}

export const getLastPage = (total: number, limit: number) => {
  const listTotal = getListTotal(total);
  const lastPageOffset = Math.max(0, Math.floor((listTotal - 1) / limit) * limit);
  const lastPage = getPageFromOffset(lastPageOffset, limit);

  return { lastPage, lastPageOffset };
};

import * as React from 'react';
import Control from 'react-leaflet-control';

import { LatLngBounds } from 'leaflet';
import { Map, TileLayer } from 'react-leaflet';

import { useDebounce } from '../../../../lib/useDebounce';
import { RequestInitWithRetry } from '../../../../lib/request';
import { useTranslation } from '@lib/useTypedTranslation';
import { useWorldRequest } from '../../../../lib/useWorldRequest';

import { Dropdown } from 'react-bootstrap';
import { NoSelectionOverlay } from '../../../card/noSelectionOverlay';
import { CheckBox } from '../../../controls/checkbox';
import { DropdownMenu } from '../../../controls/dropdownMenu';
import { Legend } from './legend';
import { OverlayLayer, TooltipContentFC } from './overlayLayer';

import { getSignalLevelGeoBounds } from '../../../../services/core/maps';
import { getGeotilePrecisionFromMapZoom, getDataRequestBounds, useInitialBoundsSet, useZoom } from '../../utils';
import { DataPoint } from './types';
import { ColorVariant } from './utils';

import '../../sharedMap.css';

export const CheckboxDropdownItem: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <Dropdown.Item as='label' data-id="checkbox-dropdown-item" onClick={e => { e.stopPropagation(); }}>
    {children}
  </Dropdown.Item>;
};

interface IntensityMapProps<TData extends DataPoint> {
  fetchMapData: (boundingLatLong: string, geotilePrecision: number) => (options: RequestInitWithRetry) => Promise<TData[]>,
  TooltipContent: TooltipContentFC<TData>,
  groups?: string[],
  homeLocations?: string[],
  zoneId?: string,
  deviceId?: string,
  fixMaxBounds?: boolean,
  colorVariant?: ColorVariant
}

const fixMapBoundsGetter = () => getSignalLevelGeoBounds({});

export const IntensityMap = <TData extends DataPoint>({
  groups,
  homeLocations,
  zoneId,
  deviceId,
  fetchMapData,
  TooltipContent,
  colorVariant = 'negative',
  fixMaxBounds = false,
}: IntensityMapProps<TData>) => {
  const mapRef = React.useRef<Map>();
  const { t } = useTranslation('translation');
  const { t: t2 } = useTranslation('maps');

  // CONFIG STATE
  const [grayscale, setGrayscale] = React.useState(true);
  const [gridShown, setGridShown] = React.useState(true);
  const [tooltip, setTooltip] = React.useState(false);
  const [discrete, setDiscrete] = React.useState(false);
  const [legend, setLegend] = React.useState(true);

  // INTERACTION STATE
  const [bounds, setBounds] = React.useState<LatLngBounds>();
  const [geotilePrecision, setGeotilePrecision] = React.useState<number>();

  //#region API
  const { addDebounceEvent, isDebouncing } = useDebounce(500);

  // Initial Bounds
  const fetchInitialBoundsRequest = React.useCallback(
    () => {
      return getSignalLevelGeoBounds({
        groups: groups,
        homeLocations: homeLocations,
        zoneId: zoneId,
        deviceId: deviceId
      });
    },
    [groups, homeLocations, zoneId, deviceId]
  );

  const getFetch = React.useMemo(() => {
    return fixMaxBounds ?
      fixMapBoundsGetter
      :
      fetchInitialBoundsRequest;
  }, [fixMaxBounds, fetchInitialBoundsRequest]);

  const initialBoundsRequest = useWorldRequest(getFetch);

  const { resetInitialBounds, mapTilesShown } = useInitialBoundsSet(
    'intensity',
    initialBoundsRequest,
    mapRef,
  );

  // Map Data
  const fetchMapDataRequest = React.useCallback(() => {
    // no fetcher returned if no bounds or precision
    if (!bounds || !geotilePrecision) {
      return undefined;
    }

    const boundsSw = bounds.getSouthWest();
    const boundsNe = bounds.getNorthEast();

    const mapBounds = getDataRequestBounds('intensity', boundsSw, boundsNe, geotilePrecision);
    const boundingLatLong = `${mapBounds.getSouth()},${mapBounds.getWest()}:${mapBounds.getNorth()},${mapBounds.getEast()}`;

    return fetchMapData(boundingLatLong, geotilePrecision);
  }, [bounds, geotilePrecision, fetchMapData]);

  const mapDataRequest = useWorldRequest(fetchMapDataRequest);

  // triggers fresh map data fetching via useWorldRequest
  const prepareMapDataRequest = React.useCallback(() => {
    const bounds = mapRef.current?.leafletElement?.getBounds();
    const zoom = mapRef.current?.leafletElement?.getZoom();
    const geotilePrecision = getGeotilePrecisionFromMapZoom('intensity', zoom);

    setBounds(bounds);
    setGeotilePrecision(geotilePrecision);
  }, []);

  const handleChange = React.useCallback(() => {
    addDebounceEvent(async () => { prepareMapDataRequest(); });
  }, [addDebounceEvent, prepareMapDataRequest]);

  //#endregion

  const isLoading = isDebouncing || mapDataRequest.loading || initialBoundsRequest.loading;

  const { smoothZoomIn, smoothZoomOut, zoomInDisabled, zoomOutDisabled } = useZoom(mapRef, 1);

  return <React.Fragment>
    <div className="intensity-map shared-map" data-id="intensity-map" data-grayscale={grayscale}>
      {/* cannot be with other leaflet controls: https://github.com/LiveBy/react-leaflet-control/issues/16 */}
      <div className="mapZoomButtonContainer">
        <i
          className="mapZoomButton fas fa-plus"
          onPointerDown={!zoomInDisabled ? smoothZoomIn.pointerDown : undefined}
          onPointerUp={!zoomInDisabled ? smoothZoomIn.pointerUp : undefined}
          aria-disabled={zoomInDisabled}
          aria-label="Zoom in"
          title="Zoom in"
        />
        <i
          className="mapZoomButton fas fa-minus"
          onPointerDown={!zoomOutDisabled ? smoothZoomOut.pointerDown : undefined}
          onPointerUp={!zoomOutDisabled ? smoothZoomOut.pointerUp : undefined}
          aria-disabled={zoomOutDisabled}
          aria-label="Zoom out"
          title="Zoom out"
        />
      </div>
      <NoSelectionOverlay
        show={!isLoading && (initialBoundsRequest.data === null)}
        noSelectionText={t('NO_DATA_AVAILABLE')}
      />
      <Map
        ref={ref => { if (ref) mapRef.current = ref; }}
        zoomControl={false}
        bounds={[[-90, -180], [90, 180]]}
        onmoveend={handleChange}
        onzoomend={handleChange}
        onresize={resetInitialBounds}
        maxZoom={19}
        zoomSnap={1}
      >
        <OverlayLayer
          colorVariant={colorVariant}
          data={mapDataRequest.data}
          discrete={discrete}
          gridShown={gridShown}
          mapRef={mapRef}
          tooltip={tooltip}
          TooltipContent={TooltipContent}
        />
        <Control position="topright">
          <i className="mapResetButton fas fa-undo-alt" aria-label="Reset map" onClick={resetInitialBounds} />
        </Control>
        <Control position="bottomright">
          {legend && <Legend colorVariant={colorVariant} discrete={discrete} />}
        </Control>
        <Control position="topleft">
          <DropdownMenu buttonContent={<i className="fas fa-cog" />} data-id='map-config'>
            <CheckboxDropdownItem>
              <CheckBox
                checked={gridShown}
                onChange={() => { setGridShown(!gridShown); }}
                name={t2('CONFIG_GRID_SHOWN')}
              />
              {t2('CONFIG_GRID_SHOWN')}
            </CheckboxDropdownItem>
            <CheckboxDropdownItem>
              <CheckBox
                checked={grayscale}
                onChange={() => { setGrayscale(!grayscale); }}
                name={t2('CONFIG_GRAYSCALE')}
              />
              {t2('CONFIG_GRAYSCALE')}
            </CheckboxDropdownItem>
            <CheckboxDropdownItem>
              <CheckBox
                checked={legend}
                onChange={() => {
                  setLegend(!legend);
                }}
                name={t2('CONFIG_LEGEND')}
              />
              {t2('CONFIG_LEGEND')}
            </CheckboxDropdownItem>
            <CheckboxDropdownItem>
              <CheckBox
                checked={tooltip}
                onChange={() => {
                  setTooltip(!tooltip);
                }}
                name={t2('CONFIG_TOOLTIP')}
              />
              {t2('CONFIG_TOOLTIP')}
            </CheckboxDropdownItem>
            <CheckboxDropdownItem>
              <CheckBox
                checked={discrete}
                onChange={() => {
                  setDiscrete(!discrete);
                }}
                name={t2('CONFIG_DISCRETE')}
              />
              {t2('CONFIG_DISCRETE')}
            </CheckboxDropdownItem>
          </DropdownMenu>
        </Control>
        {isLoading && <Control position="bottomleft">
          <div className='loading-indicator'>
            <i className="fas fa-spinner fa-spin" />
            {t('LOADING')}
          </div>
        </Control>}
        {mapTilesShown && <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          noWrap
        />}
      </Map>
    </div>
  </React.Fragment>;
};

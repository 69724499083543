import React, { useMemo } from 'react';
import { FeatureTogglesProvider } from '../../context/featureToggles';

interface Props {
  featureToggles: string[],
  children: React.ReactNode
}

export function FeatureTogglesWrapper(props: Props) {
  const { children, featureToggles } = props;
  const value = useMemo(() => {
    return {
      hideEnrollmentPage: featureToggles.includes('hide-enrolment-page'),
      hideUserManagement: featureToggles.includes('hide-user-management'),
      showLocationMapTab: featureToggles.includes('show-location-map-tab'),
      getDropsEnabledOnDevice: (platformType: string) => platformType === 'android'
    };
  }, [featureToggles]);
  return (
    <FeatureTogglesProvider value={value}>
      {children}
    </FeatureTogglesProvider>
  );
}

import React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { FallbackProps } from 'react-error-boundary';
import styled from 'styled-components';

import { Button, Variants } from '../controls/button';
import Header from '../../layout/header/Header';
import { useHistory } from 'react-router-dom';
import { ButtonColours } from '../../containers/app/themes';

const StyledErrorFallback = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledErrorFallbackMessage = styled.div`
  margin-bottom: 1.625rem;
`;

const StyledButton = styled(Button)`
  background-color: #0072af;
  height: 2rem;
  line-height: 2rem;
  box-shadow: 0 2px 4px 0 rgba(176, 176, 176, 0.5);
  font-size: 0.875rem;
  padding: 0 1rem;
  font-weight: 600;
  align-self: unset;
  &:hover {
    background-color: #004c89;
    color: #fff;
    cursor: pointer;
    transition: 0.3s;
  }
  & > i {
    margin-right: 10px;
  }
`;

/**
 * Fallback to be rendered by react-error-boundary
 * https://www.npmjs.com/package/react-error-boundary
 */
export const ErrorFallback = (props: FallbackProps) => {
  const { t } = useTranslation('error');
  const history = useHistory();
  return (
    <>
      <Header onLogoClick={() => { history.push('/'); window.location.reload(); }} />
      <StyledErrorFallback data-id='error-fallback'>
        <StyledErrorFallbackMessage data-id='error-fallback__message'>{t('SOMETHING_WRONG')}</StyledErrorFallbackMessage>
        <StyledButton
          data-id='error-fallback__button'
          className='panel-button'
          colour={ButtonColours.blue}
          iconStyle="fas fa-sync-alt"
          text={t('RELOAD_PAGE')}
          onClick={() => window.location.reload()}
          iconBeforeText
        />
      </StyledErrorFallback>
    </>
  );
};

import React, { useMemo, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { mergeWith, cloneDeep, isEqual } from 'lodash';
import styled from 'styled-components';

import { Button } from '../../../../../../components/controls/button';
import { CheckBox, ICheckboxValue } from '../../../../../../components/controls/checkbox';
import { ModalPopup } from '../../../../../../components/controls/modalPopup';
import { DeviceChartEventsFilterState, DeviceEventFilters, DeviceNetworkChartEventsFilterState, DeviceTableEventsFilterState } from './deviceEventsTypes';
import { ChartName, NetworkChartName, chartNamesArray, isChartName } from '../charts/lib/chartNames';
import { ChartEventFilters, TableEventFilters, setTableEventFiltersAction, setChartEventFiltersAction, SetTableEventFiltersRequest, SetChartEventFiltersRequest, NetworkChartEventFilters } from '../../../../../../services/config/config';
import logger from '../../../../../../lib/logger';
import { isTableName, TableName } from '../../../../../../components/data-table/lib';
import { isDevelopment } from '../../../../../../lib/env';
import { checkNodeEnv } from '../../../../../../lib/checkNodeEnv';
import { ActionButton } from '../../../../../../components/controls/formActionButtons';
import { ButtonColours } from '../../../../../app/themes';
import { Variants } from '../../../../../../components/controls/button';
import { UpdateProperties, useUserSettingsContext } from '../../../../../../context/userSettings';
import { useNonWorldAction } from '../../../../../../lib/useNonWorldAction';

import './edit-events.css';
import './deviceEvents.css';
import { ITranslationKeys } from 'components/i18n/keys';

export interface EditEventsDispatchHandlers {
  handleCancel: () => void,
  handleUpdate: (events: DeviceEventFilters) => void,
  handleApply: () => void,
  handleApplyAll?: () => void
}

interface IEditEventsProps extends EditEventsDispatchHandlers {
  subheader?: string,
  forTable?: boolean,
  eventGroupNames: IEventGroupNames,
  eventFilterGroups: IEventFilterGroups,
  eventFilterState: DeviceEventFilters,
  modalSubHeader?: string
}

interface IEditEventsModalProps extends IEditEventsProps {
  show: boolean
}

export interface IEventFilter {
  name: string,
  displayName: string,
  icon: string // fa icon classname, plus additional custom classes for colour etc.
}

export type IEventFilterGroups = Map<string, IEventFilter[]>;

export interface IEventGroupNames {
  [eventGroup: string]: string
}

/** Get a list of active event filter names from event filter state objects */
export function getActiveEventFilters(eventFilters: DeviceEventFilters | DeviceEventFilters[]): (keyof DeviceEventFilters & string)[] {
  if (Array.isArray(eventFilters)) {
    // If we have multiple filters, combine them such that true values override false values.
    eventFilters = mergeWith({}, ...eventFilters, (object: boolean, source: boolean) => object || source);
  }
  return Object.entries(eventFilters)
    .filter(([_, active]) => active)
    .map(([event, _]) => event as keyof DeviceEventFilters & string);
}

export const DISPLAY_ALL = 'DISPLAY_ALL';

export type ChartOrTableFilters<T> = T extends ChartName ? ChartEventFilters | NetworkChartEventFilters : TableEventFilters;
export type ChartOrTableFiltersState<T> = T extends ChartName ? DeviceChartEventsFilterState | DeviceNetworkChartEventsFilterState : DeviceTableEventsFilterState;

export function saveEventFilters(
  state: DeviceChartEventsFilterState,
  prevState: DeviceChartEventsFilterState,
  updated: ChartName[],
  updateUserSettings: UpdateProperties,
  setChartEventFilters: SetChartEventFiltersRequest,
  setTableEventFilters: SetTableEventFiltersRequest
): void;
export function saveEventFilters(
  state: DeviceNetworkChartEventsFilterState,
  prevState: DeviceNetworkChartEventsFilterState,
  updated: NetworkChartName[],
  updateUserSettings: UpdateProperties,
  setChartEventFilters: SetChartEventFiltersRequest,
  setTableEventFilters: SetTableEventFiltersRequest
): void;
export function saveEventFilters(
  state: DeviceTableEventsFilterState,
  prevState: DeviceTableEventsFilterState,
  updated: TableName[],
  updateUserSettings: UpdateProperties,
  setChartEventFilters: SetChartEventFiltersRequest,
  setTableEventFilters: SetTableEventFiltersRequest
): void;
export function saveEventFilters(
  state: DeviceChartEventsFilterState | DeviceTableEventsFilterState | DeviceNetworkChartEventsFilterState,
  prevState: any,
  updated: any[],
  updateUserSettings: UpdateProperties,
  setChartEventFilters: SetChartEventFiltersRequest,
  setTableEventFilters: SetTableEventFiltersRequest
): void {
  if (isEqual(state, prevState)) {
    return;
  }
  const paths: string[] = [];

  const typedState = state as ChartOrTableFiltersState<typeof updated[number]>;
  const property = isChartName(updated[0]) ? "chartEventFilters" : "tableEventFilters";
  const eventFiltersService = isChartName(updated[0]) ? setChartEventFilters : setTableEventFilters;
  const updates = Object.entries(typedState).reduce((acc: any, [name, filters]) => {
    const check = isChartName(updated[0]) ? isChartName : isTableName;
    if (check(name) && updated.includes(name)) {
      acc[name] = filters.appliedEventFilters;
      paths.push(`${property}.${name}`);
    }
    return acc;
  }, {} as ChartOrTableFilters<typeof updated[number]>);
  updateUserSettings(paths, { [property]: updates });
  // N.B. we do not wait for the chart/table filter request to resolve/reject
  eventFiltersService(updates)
    .then(({ set }) => {
      if (isDevelopment()) {
        logger.log(`${eventFiltersService.name} for ${updated} ok, set: ${set}`);
      }
    }).catch((err) => {
      if (isDevelopment()) {
        logger.warn(`${eventFiltersService.name} for ${updated} failed: ${err}`);
      }
    });
}

// mockable local dependencies
export const dependencies = {
  checkNodeEnv,
  saveEventFilters
};

export type EditEventsAction<T extends ChartName | TableName | NetworkChartName> =
  | { type: 'onUpdate', filterState: DeviceEventFilters, name: T }
  | { type: 'onApply', name: T, updateProperties: UpdateProperties, setChartEventFilters: SetChartEventFiltersRequest, setTableEventFilters: SetTableEventFiltersRequest }
  | { type: 'onApplyAll', name: T, updateProperties: UpdateProperties, setChartEventFilters: SetChartEventFiltersRequest, setTableEventFilters: SetTableEventFiltersRequest }
  | { type: 'onCancel', name: T };

export function editEventsReducer(state: DeviceChartEventsFilterState, action: EditEventsAction<ChartName>): DeviceChartEventsFilterState;
export function editEventsReducer(state: DeviceNetworkChartEventsFilterState, action: EditEventsAction<NetworkChartName>): DeviceNetworkChartEventsFilterState;
export function editEventsReducer(state: DeviceTableEventsFilterState, action: EditEventsAction<TableName>): DeviceTableEventsFilterState;
export function editEventsReducer(state: any, action: EditEventsAction<any>): any {
  if (action.type === 'onUpdate') {
    const { filterState: currentEventFilters, name } = action;
    return { ...state, [name]: { ...state[name], currentEventFilters } };
  }
  if (action.type === 'onApply') {
    const { name, updateProperties, setChartEventFilters, setTableEventFilters } = action;
    const appliedEventFilters = state[name].currentEventFilters;
    const newState = { ...state, [name]: { ...state[name], appliedEventFilters } };
    dependencies.saveEventFilters(newState, state, [name], updateProperties, setChartEventFilters, setTableEventFilters);
    return newState;
  }
  if (action.type === 'onCancel') {
    const { name } = action;
    const currentEventFilters = state[name].appliedEventFilters;
    return { ...state, [name]: { ...state[name], currentEventFilters } };
  }
  if (action.type === 'onApplyAll') {
    const { name, updateProperties, setChartEventFilters, setTableEventFilters } = action;
    const newState = cloneDeep(state);
    const appliedFilters = state[name].currentEventFilters;
    Object.keys(newState).forEach((chart) => {
      newState[chart as ChartName] = {
        appliedEventFilters: appliedFilters,
        currentEventFilters: appliedFilters
      };
    });
    dependencies.saveEventFilters(newState, state, [...chartNamesArray], updateProperties, setChartEventFilters, setTableEventFilters);
    return newState;
  }
  return state;
}

export function useEditEventsDispatch(
  dispatcher: React.Dispatch<EditEventsAction<ChartName>>,
  name: ChartName,
  setLoading: () => void,
): EditEventsDispatchHandlers;
export function useEditEventsDispatch(
  dispatcher: React.Dispatch<EditEventsAction<NetworkChartName>>,
  name: NetworkChartName,
  setLoading: () => void,
): EditEventsDispatchHandlers;
export function useEditEventsDispatch(
  dispatcher: React.Dispatch<EditEventsAction<TableName>>,
  name: TableName,
  setLoading: () => void,
): EditEventsDispatchHandlers;
export function useEditEventsDispatch(
  dispatcher: any,
  name: any,
  setLoading: () => void
): EditEventsDispatchHandlers {
  const { updateProperties } = useUserSettingsContext();
  const setTableEventFilters = useNonWorldAction(setTableEventFiltersAction);
  const setChartEventFilters = useNonWorldAction(setChartEventFiltersAction);
  return useMemo(() => ({
    handleUpdate: (events: DeviceEventFilters) => dispatcher({ type: 'onUpdate', name, filterState: events }),
    handleApply: () => { setLoading(); dispatcher({ type: 'onApply', name, updateProperties, setChartEventFilters, setTableEventFilters }); },
    handleApplyAll: () => { setLoading(); dispatcher({ type: 'onApplyAll', name, updateProperties, setChartEventFilters, setTableEventFilters }); },
    handleCancel: () => dispatcher({ type: 'onCancel', name }),
  }), [dispatcher, name, setLoading, updateProperties, setChartEventFilters, setTableEventFilters]);
}

interface FooterProps {
  subheader?: string,
  handleCancel: () => void,
  handleApply: () => void,
  handleApplyAll?: () => void
}

const FooterButton = styled(ActionButton)`
  white-space: nowrap;
  margin-bottom: 0.3rem;
`;
FooterButton.displayName = 'FooterButton';

const ApplyAllButton = styled(FooterButton)`
  white-space: nowrap;
`;
ApplyAllButton.displayName = 'ApplyAllButton';

function Footer(props: FooterProps) {
  const { t } = useTranslation(['editEvents', 'translation']);
  return <div className="edit-events__modal__footer">
    {props.subheader && <div className="subheader"><div className="editing-text">{t('EDITING', { ns: 'editEvents' })}:</div>{props.subheader}</div>}
    <div className="buttons">
      <FooterButton dataId="apply-button" colour={ButtonColours.blue} text={t('APPLY_BUTTON', { ns: 'editEvents' })} onClick={props.handleApply} />
      {props.handleApplyAll && <ApplyAllButton dataId="apply-all-button" colour={ButtonColours.blue} text={t('APPLY_ALL_BUTTON', { ns: 'editEvents' })} onClick={props.handleApplyAll} />}
      <FooterButton dataId="cancel-button" colour={ButtonColours.grey} variant={Variants.ghost} text={t('CANCEL', { ns: 'translation' })} onClick={props.handleCancel} />
    </div>
  </div>;
}

export function EditEventsModal(props: IEditEventsModalProps) {
  const {
    subheader,
    forTable,
    eventGroupNames,
    eventFilterGroups,
    eventFilterState,
    handleUpdate,
    handleApply,
    handleCancel,
    handleApplyAll
  } = props;

  const { t } = useTranslation('editEvents');

  const allDisplayed: boolean = Object.values(eventFilterState).every((active) => active);

  const updateEventFilterState = ({ name, checked }: ICheckboxValue) => {
    const updatedEvents = Object.entries(eventFilterState)
      .reduce((result, [event, currentStatus]) => {
        const eventUpdated = [DISPLAY_ALL, event].includes(name);
        result[event as keyof DeviceEventFilters] = (eventUpdated ? checked : currentStatus);
        return result;
      }, {} as DeviceEventFilters);
    handleUpdate(updatedEvents);
  };

  const groups: JSX.Element[] = [];
  eventFilterGroups.forEach((filters, groupName) => {
    const eventRows = filters.map(({ name, displayName, icon }) => {
      const iconBaseClass = 'edit-events__modal__icon';
      const iconClass = `${iconBaseClass} ${icon}`;
      return (
        <div key={`event-${name}`} className="edit-events__modal__checkbox" data-cy={`checkbox-${name}`}>
          <CheckBox
            key={`event-${name}`}
            className="fancy-checkbox"
            name={name}
            value={t(displayName as keyof ITranslationKeys['editEvents'])}
            icon={iconClass}
            checked={eventFilterState[name as keyof DeviceEventFilters]}
            onChange={updateEventFilterState}
          />
        </div>
      );
    });
    const groupKey = `group-${groupName}`;
    groups.push(
      <div className="edit-events__modal__group" key={groupKey} data-cy={groupKey}>
        <div className="edit-events__modal__group-header">
          <div className="edit-events__modal__category">{t(eventGroupNames[groupName] as keyof ITranslationKeys['editEvents'])}</div>
          <div className="edit-events__modal__icon-header">{t('ICON')}</div>
        </div>
        {eventRows}
      </div>
    );
  });

  const modalHeader = forTable ? t('MODAL_TABLE_HEADER') : t('MODAL_HEADER');
  const modalSubHeader = forTable ? t('MODAL_TABLE_SUBHEADER') : (props?.modalSubHeader ? props?.modalSubHeader : t('MODAL_SUBHEADER'));

  return (
    <ModalPopup
      show={props.show}
      handleClose={handleCancel}
      header={modalHeader}
      subheader={modalSubHeader}
      classname="core-device-performance_edit-events"
      footer={<Footer
        subheader={subheader}
        handleCancel={handleCancel}
        handleApply={handleApply}
        handleApplyAll={handleApplyAll}
      />}
    >
      <div className="edit-events__modal">
        <div className="edit-events__modal__checkbox edit-events__modal__checkbox--display-all" data-cy="checkbox-displayAll">
          <CheckBox
            className="fancy-checkbox"
            name={DISPLAY_ALL}
            value={t('DISPLAY_ALL')}
            checked={allDisplayed}
            onChange={updateEventFilterState}
          />
        </div>
        <div className="edit-events__modal__groups">
          {groups}
        </div>
      </div>
    </ModalPopup>
  );
}

export function EditEventsButton(props: { forTable: boolean, onClick: () => void }) {
  const { t } = useTranslation('editEvents');
  const buttonText = props.forTable ? t('FILTER_BUTTON') : t('OPEN_BUTTON');
  const buttonIcon = props.forTable ? "fas fa-sliders-h" : "fas fa-eye";

  return (
    <Button
      colour={ButtonColours.grey}
      className='edit-events__button panel-button'
      text={buttonText}
      iconBeforeText={true}
      iconStyle={buttonIcon}
      onClick={props.onClick}
    />
  );
}

export function EditEvents(props: IEditEventsProps) {
  const [show, setShow] = useState(false);

  function handleCancel() {
    setShow(false);
    props.handleCancel();
  }

  function handleApply() {
    setShow(false);
    props.handleApply();
  }

  function handleApplyAll() {
    setShow(false);
    props.handleApplyAll();
  }

  return (
    <>
      <EditEventsButton forTable={props.forTable} onClick={() => setShow(true)} />
      <EditEventsModal
        {...props}
        show={show}
        handleApply={handleApply}
        handleCancel={handleCancel}
        handleApplyAll={props.handleApplyAll && handleApplyAll}
      />
    </>
  );
}

import React from "react";
import { useTimeout } from "./useTimeout";

export const useSingleClick = () => {
  const { addTimeout, timeoutIdsRef } = useTimeout();

  const handleSingleClick = (handler: () => void) => () => {
    if (timeoutIdsRef.current.length) {
      timeoutIdsRef.current.forEach(id => clearTimeout(id));
      timeoutIdsRef.current = [];
      return;
    }

    addTimeout(handler, 500);
  };

  return { handleSingleClick };
};

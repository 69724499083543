import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { useState } from 'react';
import moment from 'moment';

import ThresholdSettings from '../thresholdSettings';
import { WorldSettings } from '../../../../services/config/config';

import '../settings.css';

export interface IProps {
  worldSettings: WorldSettings,
  submitInProgress: boolean,
  timeStamp: number,
  onDataChanged: (data: WorldSettings) => void,
  disabled?: boolean
}

const ns = 'settings' as const;

export function AverageDischargeDeviceSettings(props: IProps) {
  const { t } = useTranslation(ns);
  const [alertTimeStamp, setAlertTimeStamp] = useState<number>(props.timeStamp);

  if (props.timeStamp > alertTimeStamp) {
    setAlertTimeStamp(props.timeStamp);
  }

  function onDataChanged(data: number, field?: string) {
    if (field === 'warning') {
      props.onDataChanged({ ...props.worldSettings, averageDischargeYellow: data });
    } else if (field === 'problem') {
      props.onDataChanged({ ...props.worldSettings, averageDischargeRed: data });
    }
  }

  function onAlertToggled(data: boolean, initialSettings: { warning: number, problem: number }) {
    setAlertTimeStamp(moment.utc().valueOf());
    props.onDataChanged({
      ...props.worldSettings,
      averageDischargeEnabled: data,
      averageDischargeYellow: initialSettings.warning,
      averageDischargeRed: initialSettings.problem,
    });
  }

  return (
    <ThresholdSettings
      key={alertTimeStamp}
      title={t('AVG_DISCHARGE_TITLE', { ns })}
      message={t('AVG_DISCHARGE_MESSAGE', { ns })}
      popoverText={t('AVG_DISCHARGE_POPOVER', { ns })}
      unit={{ key: 'AVG_DISCHARGE_UNIT' }}
      className="averageDischarge"
      maxValue={99}
      warning={props.worldSettings.averageDischargeYellow}
      problem={props.worldSettings.averageDischargeRed}
      dataUpdated={onDataChanged}
      disabled={props.submitInProgress || !props.worldSettings.averageDischargeEnabled}
      alertEnabled={props.worldSettings.averageDischargeEnabled}
      alertToggled={onAlertToggled}
    />
  );
}

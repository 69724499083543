import * as React from 'react';

import { AverageDischargeSettings } from './averageDischargeSettings';
import { BatteryAlertsSettings } from './batteryAlerts';
import { BatteryTypeSettings, WorldSettings } from '../../../../services/config/config';

import '../settings.css';

export enum BatterySettingsLicense {
  none = "none",
  onlyBattery = "onlyBattery",
  onlyDevices = "onlyDevices",
  batteryAndDevices = "batteryAndDevices"
}

export interface IProps {
  batteryTypeSettings: BatteryTypeSettings[],
  worldSettings: WorldSettings,
  licensed: BatterySettingsLicense,
  onDataChanged: (data: WorldSettings | BatteryTypeSettings[]) => void,
  submitInProgress: boolean,
  dataTimestamp: number,
  initialBatteryTypeSettings: BatteryTypeSettings[],
  initialWorldSettings: WorldSettings
}

export function BatterySettings(props: IProps) {

  const settings = [
    <BatteryAlertsSettings key='battery-alerts' batteryTypeSettings={props.batteryTypeSettings} dataTimestamp={props.dataTimestamp} onDataChanged={props.onDataChanged} worldSettings={props.worldSettings} submitInProgress={props.submitInProgress} initialBatteryTypeSettings={props.initialBatteryTypeSettings} initialWorldSettings={props.initialWorldSettings}/>,
    <AverageDischargeSettings key='average-discharge' dataTimestamp={props.dataTimestamp} onDataChanged={props.onDataChanged} worldSettings={props.worldSettings} submitInProgress={props.submitInProgress} />
  ];

  if (props.licensed === BatterySettingsLicense.onlyBattery) {
    settings.splice(-1, 1);
  } else if (props.licensed === BatterySettingsLicense.onlyDevices) {
    settings.splice(0, 1);
  }

  return <div className="battery threshold-settings-composition">
    {settings}
  </div>;
}

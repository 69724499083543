import styled from 'styled-components';
import React, { useContext } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import { groupBy, get, uniq, concat, keys } from 'lodash';
import * as am4charts from '@amcharts/amcharts4/charts';

import { IApplicationDataUsage, IApplicationUsageData } from '../../../../../services/core/deviceApplications';
import { IApplicationEvent } from '../../../../../services/core/eventsTypes';
import { CloseButton } from '../../../../../components/controls/closeButton';
import { createYAxis } from '../../../../../lib/usageChartYAxis';
import { ChipButton } from '../../../../../components/controls/chipButton';
import { DeviceInfoContext } from '../../index';

import './30DayApplicationUsage.css';
import { StackedColumnsDateChart } from '../../../../../components/chart/stackedColumnsDateChart';
import { drawYAxis } from '../../../../../lib/dataUsageChart';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import { ChartTimePeriod, formatDurationData, formatTooltipDate, isTimePeriod30Days } from '../../deviceFormat';
import { drawXAxisLabels, getDaysOfChartTimePeriod } from '../../../../../components/chart/lib';

interface IProps {
  data: IApplicationUsageData,
  events: IApplicationEvent[],
  handleClose: () => void,
  chartTimePeriod: ChartTimePeriod,
  setChartTimePeriod: (chartTimePeriod: ChartTimePeriod) => void,
  dataUsage: IApplicationDataUsage[],
  series: Series,
  setSeries: (series: Series) => void,
  sharedUserId?: string
}

interface IButtons {
  Component: (props: any) => JSX.Element,
  props: any
}

export enum Series {
  usage = 'userActivity',
  data = 'dataUsage'
}

export function isTimePeriod7Days(chartTimePeriod: ChartTimePeriod): boolean {
  return chartTimePeriod === ChartTimePeriod.last7Days;
}

export function generateButtons(setChartTimePeriod: IProps["setChartTimePeriod"], chartTimePeriod: ChartTimePeriod, setSeries: IProps["setSeries"], series: Series, t: any): any {
  const timePeriod = Object.values(ChartTimePeriod);

  const buttons : IButtons[] = timePeriod.map((time) => ({
    'Component': Button,
    'props': {
      active: time === chartTimePeriod,
      text: isTimePeriod30Days(time) || isTimePeriod7Days(time) ? t('DAY_COUNT', { ns: 'timeState', count: getDaysOfChartTimePeriod(time) }) : t('HOURS_48', { ns: 'timeState' }),
      onClick: () => setChartTimePeriod(time)
    }
  }));
  const seriesButtons : IButtons[] = Object.values(Series).map((seriesValue) => ({
    'Component': Button,
    'props': {
      active: series === seriesValue,
      text: isSeriesData(seriesValue) ? t('DATA', { ns: 'translation' }) : t('USAGE', { ns: 'deviceProcesses' }),
      onClick: () => { setSeries(seriesValue); }
    }
  }));

  return [...buttons, ...seriesButtons];
}

export function isSeriesData(series: Series): boolean {
  return series === Series.data;
}

export function generateTitle(chartTimePeriod: ChartTimePeriod, series: Series, timePeriodTranslation: string, dailyOrHourlyTranslationContext: any, t: TTypedTFunction, applicationName: string, sharedUserId?: string): string {
  if (isSeriesData(series)) {
    return (sharedUserId ? t('deviceApplications:SHARED_ID') + ` "${sharedUserId}"` : `${applicationName}`) + ` - ` + t(isTimePeriod30Days(chartTimePeriod) ? `DATA_USAGE_TITLE_daily` : `DATA_USAGE_TITLE_hourly`, { ns: 'deviceApplications', timePeriod: timePeriodTranslation, context: dailyOrHourlyTranslationContext });
  } else {
    return `${applicationName} - ` + t(isTimePeriod30Days(chartTimePeriod) ? `USAGE_TITLE_daily` : `USAGE_TITLE_hourly`, { ns: 'deviceProcesses', timePeriod: timePeriodTranslation, context: dailyOrHourlyTranslationContext });
  }
}

export function ApplicationUsage30Days(props: IProps) {
  const timeZoneOffset = useContext(DeviceInfoContext).timeZoneOffset || 0;
  const { t } = useTranslation(['performance', 'editEvents', 'translation', 'deviceApplications']);
  const timePeriodTranslation = (isTimePeriod30Days(props.chartTimePeriod) || isTimePeriod7Days(props.chartTimePeriod)) ? t('OVER_THE_PAST_X_DAYS', { count: getDaysOfChartTimePeriod(props.chartTimePeriod), ns: 'timeState' }) : t('OVER_THE_PAST_X_HOURS', { count: 48, ns: 'timeState' });
  const dailyOrHourlyTranslationContext = isTimePeriod30Days(props.chartTimePeriod) ? 'daily' : 'hourly';
  const dayOrHourTime = isTimePeriod30Days(props.chartTimePeriod) ? 'day' : 'hour';
  const data = getData();

  return (
    <div className="application-usage-chart">
      <CloseButton handleClose={props.handleClose} className='application-usage-chart-close' />
      <div className="core-device_application-30-day-application-usage">
        <StackedColumnsDateChart<any>
          data={data.map((data) => (
            !isSeriesData(props.series) ? { userActivity: data.userActivity || 0, date: data.date, events: data.events } :
              { wifi: data?.dataUsage?.[0]?.wifi || 0, mobile: data?.dataUsage?.[0]?.mobile || 0, date: data.date, events: data.events }))
          }
          buttons={generateButtons(props.setChartTimePeriod, props.chartTimePeriod, props.setSeries, props.series, t)}
          days={30}
          dateAxisEndDuration={isTimePeriod30Days(props.chartTimePeriod) ? { amount: '1', unit: 'day' } : { amount: '1', unit: 'hour' }}
          series={!isSeriesData(props.series) ? [{
            visible: true,
            dataKey: 'userActivity',
            description: t('IN_USE', { ns: 'performance' }),
            colour: '#4AA4BA'
          }] : [
            {
              visible: true,
              dataKey: 'mobile',
              description: t('MOBILE_DATA', { ns: 'translation' }),
              colour: '#49a1a9'
            },
            {
              visible: true,
              dataKey: 'wifi',
              description: t('WIFI_DATA', { ns: 'translation' }),
              colour: '#2c678c'
            }
          ]}
          totalDescription={isSeriesData(props.series) ? t('TOTAL_DATA', { ns: 'translation' }) : undefined}
          amchartsYAxisNumberFormat={isSeriesData(props.series) ? "#.00b" : undefined}
          formatData={isSeriesData(props.series) ? undefined : formatDurationData(t) }
          title={generateTitle(props.chartTimePeriod, props.series, timePeriodTranslation, dailyOrHourlyTranslationContext, t, props.data.applicationName, props?.sharedUserId)}
          cypressId={isSeriesData(props.series) ? "application-data-chart" : "application-usage-chart"}
          yAxisType={'duration'}
          drawYAxis={(chart, yAxis, enabledSeries) => {
            if (!isSeriesData(props.series)) {
              const dataKey = 'userActivity';
              const maxUserActivitySeconds = Math.max(...chart.data.map((data) => data[dataKey]));
              const maxUserActivityHours = maxUserActivitySeconds / 60 / 60;
              (yAxis as am4charts.DurationAxis).baseUnit = "second";

              createYAxis(yAxis as am4charts.DurationAxis, maxUserActivityHours, t);
            } else {
              yAxis.calculateTotals = true;
              yAxis.strictMinMax = true;
              drawYAxis(chart.data, yAxis, enabledSeries);
            }
            yAxis.renderer.grid.template.disabled = true;
            yAxis.renderer.labels.template.disabled = true;
          }}
          hasLegend={true}
          formatTooltipDate={formatTooltipDate(props.chartTimePeriod)}
          xAxisType={'date'}
          drawXAxis={(_, xAxis) => drawXAxisLabels(xAxis as am4charts.DateAxis, getDaysOfChartTimePeriod(props.chartTimePeriod), timeZoneOffset)}
          hasNoSelectionOverlay={false}
          eventOverlay={true}
          useAxisTooltip
        />
      </div>
    </div>
  );

  function getData() {
    const dataByDay = groupBy(props.data.applicationUsageData, (datum) => moment.utc(datum.date).startOf(dayOrHourTime).valueOf());
    const eventsByDay = groupBy(props.events, (event) => moment.utc(event.local).startOf(dayOrHourTime).valueOf());
    const dataUsageByDay = groupBy(props.dataUsage, (dataUsage) => moment.utc(dataUsage.date).startOf(dayOrHourTime).valueOf());

    const dates = uniq(concat(keys(dataByDay), keys(eventsByDay), keys(dataUsageByDay)));

    return dates.map((dateKey: string) => {
      const userActivity = get(dataByDay, `${dateKey}[0].userActivity`) || 0;
      const events = get(eventsByDay, dateKey, []);
      const dataUsage = get(dataUsageByDay, dateKey, []);
      return { date: +dateKey, userActivity, events, dataUsage };
    });
  }
}

const Button = styled(ChipButton)`
  z-index: 1000; /* visible over no selection overlay */
  margin-right: 0.75rem;

  &:nth-child(3) {
    margin-right: 3rem;
  }
`;

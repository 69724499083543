import * as React from "react";
import { LatLngBounds } from "leaflet";

import { degreesToRads } from "../utils";
import { DeviceClusterResponse } from "../../../services/core/maps";
import { getGeotileBounds } from "../intensityMaps/shared/utils";
import { IClusterProps } from "./overlayLayer";

export const useClusterProps = (bounds: LatLngBounds, data?: DeviceClusterResponse): Omit<IClusterProps, 'loading'>[] => {
  return React.useMemo(() => {
    const groundResolution = 40075016.686;

    if (data) {
      return data.clusters
        .filter(dataPoint =>
          dataPoint.lat >= bounds.getSouth() && dataPoint.lat <= bounds.getNorth() &&
            dataPoint.lon >= bounds.getWest() && dataPoint.lon <= bounds.getEast()
        )
        .map(({ lat, lon: lng, keys, count }) => {
          const radius = (groundResolution / Math.pow(2, data.precision)) * Math.cos(degreesToRads(lat)) * Math.sqrt(keys.length);

          const bounds = keys.map(geotile => {
            const bounds = getGeotileBounds(geotile);
            return `${bounds.getSouth()},${bounds.getWest()}:${bounds.getNorth()},${bounds.getEast()}`;
          });

          return {
            lat,
            lng,
            radius,
            count,
            bounds
          };
        });
    }
    return [];
  }, [bounds, data]);
};

import * as React from 'react';
import { find, forEach, get, isEqual, isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useTranslation } from '@lib/useTypedTranslation';

import { Estate, IEstateCategory } from '../../components/chart/estate';
import { DataLoading } from '../../components/loading/dataLoading';
import { BatteryDevicesService, IDeviceWithDischargeStatus } from '../../services/shared/device/batteryDevicesService';
import { VIEW, ISortResult } from '../../services/shared/common';
import { FilterSelectionControl, IFilterGroup, IFilterResult } from '../../components/filters/filterSelectionControl';
import { getGroups, IGroups } from '../../services/config/config';
import { DeviceDetailsCard } from './compositions/deviceDetailsCard';
import { PageConfig } from '../../routes/battery-route';
import { PaginationBar } from '../../components/data-table/pagination/pagination';
import { Loading } from '../../components/loading/loading';
import ETableHeader from '../../components/table/header';
import Header from '../../components/listCard/header';
import { StatusText } from '../../components/table/statusText';
import { ListCategory } from '../battery-centric';
import { InitialTableState, useTableReducer } from '../../components/data-table/lib';
import { ITableColumn, SortDirection } from '../../components/data-table/types';
import { FilterStatus, IFilterStatus } from '../../components/filters/filterStatus';
import { omitShallow } from '../../lib/omitShallow';
import { DataTable } from '../../components/data-table/dataTable';
import { formatDateLastUpdated, formatPercentage, formatStatus, formatValue } from '../../components/data-table/dataFormatters';
import { useUserSettingsContext } from '../../context/userSettings';
import { RequestInitWithRetry } from '../../lib/request';
import { useWorldRequest } from '../../lib/useWorldRequest';
import { useWorldAction } from '../../lib/useWorldAction';

import './styles.css';
import { ITranslationKeys } from 'components/i18n/keys';

export const AppContext = React.createContext({
  setSelectedItem: null,
  getSelectedItem: null
});

export const tableName = 'batteryDevicesList';
export const defaultPageSize = 20;
const service = new BatteryDevicesService();

interface Props {
  view: string,
  selectedId: string,
  selectedTimestamp: number,
  config: PageConfig
}

type Mode = 'List' | 'Filter';

export function changeFilterMode(mode: string, setMode: React.Dispatch<React.SetStateAction<Mode>>) {
  const filterMode = mode === 'List' ? 'Filter' : 'List';
  setMode(filterMode);
}

const initialTableSortField: string = 'updated';
const initialTableSortColumn: number = 6;
const initialTableSortDirection: SortDirection = 'desc';
const initialSort = { column: initialTableSortColumn, direction: initialTableSortDirection, field: initialTableSortField };

export function BatteryDevicesContainer(props: Props) {
  const ns = 'translation';
  const { t } = useTranslation(ns);

  const { tablePageSizes } = useUserSettingsContext();

  const [selectedId, setSelectedId] = useState(props.selectedId || '-');
  const [idUpdateTimestamp, setIdUpdateTimestamp] = useState(props.selectedTimestamp || 0);
  const [listResetTimestamp, setListResetTimestamp] = useState<number>(undefined);
  const [currentFilter, setCurrentFilter] = useState<IFilterResult>(undefined);
  const [statusMessage, setStatusMessage] = useState('DEVICE');
  const [resultStatus, setResultStatus] = useState<boolean>(false);
  const [initialRenderComplete, setInitialRenderComplete] = useState<boolean>(false);
  const [availableDeviceGroups, setAvailableDeviceGroups] = useState<string[]>(undefined);
  const [mode, setMode] = useState<Mode>('List');


  const doGetList = useWorldAction(service.getList);
  const doGetDeviceEstateData = useWorldAction(service.getDeviceEstateData);
  const doGetDeviceDetails = useWorldAction(service.getDeviceDetails);
  const doExportList = useWorldAction(service.exportList);

  const initialTableState: InitialTableState = {
    initialRows: 4,
    limit: tablePageSizes?.[tableName] || defaultPageSize,
    sort: initialSort
  };

  async function loadDetails(): Promise<any> {
    if (selectedId === '-') {
      return '-';
    }
    return doGetDeviceDetails(selectedId);
  }

  async function loadStateCounts(): Promise<any> {
    return await doGetDeviceEstateData();
  }

  function getEstateCategories() {
    const CATEGORIES: IEstateCategory[] = [
      { id: VIEW.red, label: t('PROBLEM'), colour: "#DC7F7F", icon: 'fa fa-times-circle circle_red', path: props.config.link + "/problem" },
      { id: VIEW.yellow, label: t('WARNING'), colour: "#FBCD76", icon: 'fa fa-exclamation-triangle circle_orange enhanced-warning-icon', path: props.config.link + "/warning" },
      { id: VIEW.green, label: t('GOOD'), colour: "#3FA67D", icon: 'fa fa-check circle_green', path: props.config.link + "/good" }];
    return CATEGORIES;
  }

  function getCategories() {
    const CATEGORIES: ListCategory[] = [
      {
        id: VIEW.all, title: t('ALL'), icon: '', link: `${props.config.link}/all`
      },
      {
        id: VIEW.red, title: t('PROBLEM'), icon: 'fa fa-times-circle circle_red', link: `${props.config.link}/problem`
      },
      {
        id: VIEW.yellow, title: t('WARNING'), icon: 'fa fa-exclamation-triangle circle_orange', link: `${props.config.link}/warning`
      },
      {
        id: VIEW.green, title: t('GOOD'), icon: 'fa fa-check circle_green', link: `${props.config.link}/good`
      }
    ];
    return CATEGORIES;
  }

  const filterGroups: IFilterGroup[] = [
    { id: "groups", filterDisplayName: t('GROUPS_FILTER_HEADER', { ns: 'batteryEssentials' }), valueDisplayName: t('GROUPS'), availableValues: availableDeviceGroups },
  ];

  const [
    { offset, limit, total, sort, dataRequestId, data, isLoading, search, selectedRow },
    { onSort, onPageChange, onPageSizeChange, onDataLoaded, onSearch, onRowSelected, onLoading }
  ] = useTableReducer<IDeviceWithDischargeStatus>(tableName, initialTableState);

  async function exportList(): Promise<string> {
    return await doExportList({ statusType: props.view, limit: 10000, offset, search, groups: get(currentFilter, 'groups') });
  }

  function getStatusFilters(): IFilterStatus[] {
    const statuses: IFilterStatus[] = [];
    forEach(currentFilter, (selectedItems: string[], groupId: string) => {
      const filterGroup = find(filterGroups, { id: groupId });
      const displayState = selectedItems.length > 1 ? `${selectedItems.length} ${filterGroup.valueDisplayName}` : selectedItems[0];
      displayState && statuses.push({ id: groupId, display: displayState });
    });
    return statuses;
  }

  const handleFilterRemoved = (removedFilterGroup: string) => {
    const newFilters = omitShallow(currentFilter, removedFilterGroup);
    setCurrentFilter(newFilters);
  };

  const handleSort = (column: number, direction: SortDirection, info: ITableColumn) => {
    onSort(!isNil(column) ? column : 0, !isNil(direction) ? direction : null, info);
  };

  const handleRowSelected = (rowIndex: number) => {
    const rowDataId = get(data[rowIndex], "id");
    if (rowIndex !== selectedRow) {
      setSelectedId(rowDataId);
      onRowSelected(rowIndex);
    }
  };

  const pinned = React.useMemo(() => {
    let pinned;
    const sortSet = (sort && !isEqual(sort, initialSort)) ? true : false;
    const filtersSet = (currentFilter && currentFilter?.groups?.length > 0) ? true : false;
    if (!isNil(props.selectedId)) {
      const isCategoryValid = (props.view === 'all' || props.view === undefined);
      pinned = (isCategoryValid && !search && !sortSet && !filtersSet) ? props.selectedId : undefined;
    }
    return pinned;
  }, [currentFilter, props.selectedId, props.view, search, sort]);

  const [groupsDataFetcher, onDeviceGroupsFetched] = useMemo(() => {
    return [
      () => (options: RequestInitWithRetry) => getGroups()(options),
      (groupsRaw :IGroups) => {
        const groups = get(groupsRaw, 'groups.nodes', []).map((o: any) => o.name);
        setInitialRenderComplete(true);
        setAvailableDeviceGroups(groups);
      }
    ];
  }, []);

  useWorldRequest(groupsDataFetcher, { onSuccess: onDeviceGroupsFetched });

  useEffect(() => {
    let selectedIndex = -1;
    if (!isLoading) {
      selectedIndex = data.findIndex(battery => battery?.id === selectedId);
    }
    if (props.selectedId && isNil(selectedRow) && !isLoading) {
      setSelectedId(props.selectedId);
      selectedIndex = 0;
    }
    if (selectedIndex !== selectedRow && !isLoading) {
      onRowSelected(selectedIndex);
    }
  }, [onRowSelected, selectedRow, isLoading, data, selectedId, props.selectedId]);

  useEffect(() => {
    if (search) {
      setStatusMessage('SEARCH_MATCH_STATEMENT');
      setResultStatus(true);
    } else {
      setStatusMessage('DEVICE');
      setResultStatus(false);
    }
  }, [search, t, total]);

  useEffect(() => {
    if (props.selectedTimestamp && idUpdateTimestamp !== props.selectedTimestamp) {
      setIdUpdateTimestamp(props.selectedTimestamp);
      setListResetTimestamp(props.selectedTimestamp);
    }
  }, [idUpdateTimestamp, props.selectedTimestamp, selectedId]);

  useEffect(() => {
    onLoading();
  }, [onLoading, props.view, props.selectedId, props.selectedTimestamp, currentFilter]);

  useEffect(() => {
    onSearch(undefined);
    setCurrentFilter(undefined);
  }, [props.selectedId, onSearch]);

  useEffect(() => {
    async function getList(pinnedId: string, statusType: string, search: string, limit: number, offset: number, filters: IFilterResult, sort: ISortResult): Promise<any[]> {
      return doGetList({ statusType: statusType as VIEW, limit, offset, pinnedId: pinnedId, search, sort, groups: get(filters, 'groups') });
    }

    async function getCount(statusType: string, search: string, filters: IFilterResult): Promise<number> {
      const data: { [key: string]: number } = await doGetDeviceEstateData(search, filters) as any;
      if (isNil(statusType)) {
        statusType = 'all';
      }
      return data[statusType] as number;
    }

    if (isLoading && initialRenderComplete) {
      Promise.all([
        getList(pinned, props.view, search, limit, offset, currentFilter, { field: sort.field, order: sort.direction }),
        getCount(props.view, search, currentFilter)
      ]).then(([list, count]) => {
        onDataLoaded(list, count, dataRequestId);
      });
    }
  }, [onDataLoaded, currentFilter, dataRequestId, isLoading, limit, offset, pinned, props.view, search, sort, initialRenderComplete, doGetList, doGetDeviceEstateData]);

  const { path } = useRouteMatch();
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // following condition handles devices linked to deleted batteries
    if (params.has('id') && !props.selectedId && path.includes('/battery/devices')) {
      setSelectedId('-');
    }
  }, [props.selectedId, path, location.search]);

  const filter = <FilterSelectionControl
    onFilterChange={(result) => { setCurrentFilter(result); setMode('List'); }}
    filterGroups={filterGroups}
    currentFilter={currentFilter}
  />;

  const filterStatus = <FilterStatus
    onNavigateFilters={() => { changeFilterMode(mode, setMode); }}
    selectedFilters={getStatusFilters()}
    onFilterRemoved={handleFilterRemoved}
  />;

  const list = <DataTable
    data={data}
    columns={[
      { id: 'manufacturer', title: t('MANUFACTURER'), dataPath: 'manufacturer', sortable: true, processor: formatValue, width: '8rem' },
      { id: 'model', title: t('MODEL'), dataPath: 'model', sortable: true, processor: formatValue, width: '5rem' },
      { id: 'serialNumber', title: t('SERIAL_NUMBER'), dataPath: 'serialNumber', sortable: true, processor: formatValue, width: '8rem' },
      { id: 'imei', title: 'IMEI', dataPath: 'imei', sortable: false, processor: formatValue, width: '6.8rem' },
      { id: 'assetTag', title: t('ASSET_TAG'), dataPath: 'assetTag', sortable: true, processor: formatValue, width: '4.5rem' },
      { id: 'deviceName', title: t('DEVICE_NAME'), dataPath: 'deviceName', sortable: true, processor: formatValue, width: '6rem' },
      { id: 'lastSeen', title: t('LAST_SEEN'), dataPath: 'updated', sortable: true, processor: formatDateLastUpdated, width: '6rem' },
      { id: 'groups', title: t('GROUPS'), dataPath: 'group', sortable: false, processor: formatValue, width: '7rem' },
      { id: 'discharge', title: t('AVERAGE_HOURLY_DISCHARGE', { ns: 'batteryEssentialsDeviceView' }), dataPath: 'averageDischarge', sortable: true, processor: formatPercentage, width: '7.5rem', textAlign: 'right' },
      { id: 'status', title: t('STATUS'), dataPath: 'averageDischargeStatus', sortable: false, processor: formatStatus, width: '3rem', textAlign: 'center' }
    ]}
    className={isLoading ? '' : 'data-ready'}
    sortDirection={sort.direction}
    sortedColumnIndex={sort.column}
    options={{ rowIdDataPath: 'id' }}
    onSort={(column, direction, info) => handleSort(column, direction, info)}
    onRowSelected={handleRowSelected}
    selectedRow={selectedRow}
  />;

  const devicesHeaderText: { [key: string]: string } = {
    'all': t('All_DEVICES_TITLE', { ns: 'batteryEssentialsDeviceView' }),
    'green': t('GOOD_DEVICES_TITLE', { ns: 'batteryEssentialsDeviceView' }),
    'yellow': t('WARNING_DEVICES_TITLE', { ns: 'batteryEssentialsDeviceView' }),
    'red': t('PROBLEM_DEVICES_TITLE', { ns: 'batteryEssentialsDeviceView' })
  };

  const isDeviceListData = !isLoading && data?.length > 0;

  const caseCheckedStatusMessage = statusMessage === 'DEVICE' ? t(statusMessage, { count: total }).toLowerCase() : t(statusMessage as keyof ITranslationKeys['translation'], { count: total });
  return (
    <div className="batteryEssentialsTable_devices_container" >
      <div className="row">
        <div className="batteryCentric_devices_estate col-xl-3 col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <DataLoading loadData={loadStateCounts}>
            <Estate
              title={t('DEVICE_ESTATE_OVERVIEW_TITLE')}
              toolLabelSngl={t('DEVICE')}
              toolLabelPlrl={t('DEVICE_other')}
              data={null}
              categories={getEstateCategories()}
              htmlIcons={true}
            />
          </DataLoading>
        </div>
        <div className="batteryCentric_devices_details col-xl-9 col-lg-8 col-md-12 col-sm-12 mt-sm-2 mt-2 mt-lg-0">
          <DataLoading key={`${selectedId}:${idUpdateTimestamp}`} loadData={loadDetails}>
            <DeviceDetailsCard id={selectedId} data={null} />
          </DataLoading>
        </div>
      </div>
      <div className="row batteryEssentialsTable_container batteryCentric" key={listResetTimestamp}>
        <div className="col-md-12">
          <div className={"eTable-display-" + props.view}>
            <ETableHeader>
              <Header
                categories={getCategories()}
                currentCategoryId={props.view}
                onFilterClicked={() => { changeFilterMode(mode, setMode); }}
                onExportClicked={exportList}
                exportButtonDisabled={!isDeviceListData}
                onSearchClicked={(input) => onSearch(input)}
                headerTitle={devicesHeaderText[props.view]} />
            </ETableHeader>
            <div className="statusText" style={{ display: currentFilter && !isEqual(currentFilter, {}) && mode === 'List' ? 'block' : 'none' }}>
              {filterStatus}
            </div>
            {mode === 'Filter' && filter}
            <Loading isLoading={isLoading} transparentOverlay={false}>
              <div className='eTable_list' style={{ display: mode === 'List' ? 'block' : 'none' }}>
                {list}
                <PaginationBar
                  offset={offset}
                  limit={limit}
                  total={total}
                  onNewPage={(newOffset) => onPageChange(newOffset)}
                  onNewLimit={(newLimit) => onPageSizeChange(newLimit)}
                >
                  <StatusText
                    searchString={search}
                    total={total}
                    offset={offset}
                    limit={limit}
                    item={caseCheckedStatusMessage}
                    fullStatusText={resultStatus}
                  />
                </PaginationBar>
              </div>
            </Loading>
          </div>
        </div>
      </div>
    </div>
  );
}

import { isEmpty } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import styled from 'styled-components';

import { Button } from '../../../components/controls/button';
import { useWorldRequest } from '../../../lib/useWorldRequest';
import { useWait } from '../../../lib/wait';
import { getDeviceToken } from '../../../services/config/config';
import { ButtonColours } from '../../app/themes';

const StyledElemezToken = styled.div`
  border: 1px solid #d7e2eb;
  margin-left: 0.625em;
  background: #fff;
  margin-bottom: 1.5rem;
`;

const StyledHeader = styled.div`
  border: 0;
  border-bottom: 1px solid #d7e2eb;
  background: #f3f7fb;
  padding: 0.3rem;
  font-weight: 600;
`;

const StyledSubHeader = styled.div`
  padding-top: 0.5rem;
  margin-left: 0.7rem;
  padding-bottom: 0.5rem;
`;

const StyledElemezTokenContent = styled.div`
  padding: 0.5rem 0.6rem 0.7rem 0.6rem;
  display: flex;
  flex-direction: column;
`;

const StyledNoTokenMessage = styled.div`
  text-align: center;
  font-weight: 400;
  color: #007bff;
  position: relative;
`;

const StyledTokenSection = styled.div`
  text-align: center;
  font-size: 0.9rem;
  display: flex;
`;

const StyledTokenText = styled.div`
  font-family: "Courier New";
  outline: solid 0.01rem #333;
  border-radius: 0.1rem;
  padding: 0.3rem;
  width: 650px;
  text-align: justify;
  -webkit-user-select: all; /* Safari */
  -moz-user-select: all; /* Firefox */
  -ms-user-select: all; /* Internet Explorer/Edge */
  user-select: all; /* Chrome and Opera */
`;

const StyledTokenBody = styled.div`
  margin-left: 0.8rem;
`;

const StyledTokenButtonsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
`;

const StyledCopyButtonWithMessage = styled.div`
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-self: baseline;
`;

const getSubmitStyles = (status: string) => {
  if (status === 'success') {
    return `color: #298d3a;
    background-color: #fff;
    transition: opacity 3s ease-in-out;
    opacity: 0;`;
  } else if (status === 'failure') {
    return `color: #c03b3b;
    background-color: #fff;
    transition: opacity 3s ease-in-out;
    opacity: 0;`;
  } else {
    return '';
  }
};

export const CopyTextWrapper = styled.span<{ status: string }>`
  font-size: 0.846rem;
  border-radius: 3px;
  width: 3rem;
  height: 2.5rem;
  padding-left: 0.36rem;
    
  @media (max-width: 992px) { 
    margin-right: 0.996rem;
  }

  ${({ status }) => getSubmitStyles(status)};
`;

interface ITokenProps {
  worldToken: string
}

export const ElemezToken = (props: ITokenProps) => {
  const { t } = useTranslation('enrollment');
  const tokenFileName = 'elemez.token';
  const deviceTokenFetcher = useCallback(() => getDeviceToken(), []);
  const { data } = useWorldRequest(deviceTokenFetcher);
  const encryptedToken = data?.deviceToken?.data;
  const [copySuccess, setCopySuccess] = useState<string>('');
  const [copyStatus, setCopyStatus] = useState<string>('');
  const { wait } = useWait();

  const onDownloadClicked = () => {
    const blob = new Blob([new Uint8Array(encryptedToken).buffer], { type: 'application/octet-stream' });
    downloadBlob(tokenFileName, blob);
  };

  const downloadBlob = (fileName: string, blob: Blob) => {
    const blobURL = window.URL.createObjectURL(blob);
    const link = createLink(blobURL, fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobURL);
  };

  const createLink = (blobURL: string, fileName: string) => {
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', fileName);
    if (isEmpty(tempLink.download)) {
      tempLink.setAttribute('target', '_blank');
    }
    return tempLink;
  };

  const copyToClipBoard = async (copyText: string) => {
    try {
      await navigator.clipboard.writeText(copyText);
      setCopyStatus('success');
      setCopySuccess(t('TOKEN_COPY_SUCCESS'));
    } catch (err) {
      setCopySuccess(t('TOKEN_COPY_FAIL'));
      setCopyStatus('failure');
    } finally {
      await wait(3000);
      setCopyStatus('');
      setCopySuccess('');
    }
  };

  return (
    <StyledElemezToken className="enrollment_elemez_token-container">
      <StyledHeader className="elemez_token-container__header">
        {t('TOKEN_HEADER')}
      </StyledHeader>
      <StyledElemezTokenContent className="elemez_token-container__content">
        <StyledSubHeader className="elemez_token-sub_header">{t('TOKEN_SUBHEADER')}</StyledSubHeader>
        <StyledTokenBody>
          {props.worldToken ?
            <StyledTokenSection className="token-section">
              <StyledTokenText className="token-text" data-id='elemezTokenText'>{props.worldToken}</StyledTokenText>
              <StyledTokenButtonsSection>
                <StyledCopyButtonWithMessage>
                  <Button
                    dataId="copy-button"
                    className='elemez-token__copy-button'
                    colour={ButtonColours.grey}
                    onClick={() => copyToClipBoard(props.worldToken)}
                    iconBeforeText={true}
                    iconStyle="fas fa-copy"
                  />
                  <CopyTextWrapper status={copyStatus} className={`copyTextWrapper ${copyStatus}`}>{copySuccess}</CopyTextWrapper>
                </StyledCopyButtonWithMessage>
                <Button
                  dataId="download-button"
                  className='elemez-token__download-button'
                  colour={ButtonColours.grey}
                  onClick={onDownloadClicked}
                  iconBeforeText={true}
                  iconStyle="fas fa-download"
                />
              </StyledTokenButtonsSection>
            </StyledTokenSection>
            :
            <StyledNoTokenMessage className='no_token-message' data-id="noTokenMessage">{t('NO_TOKEN_TEXT')}</StyledNoTokenMessage>
          }
        </StyledTokenBody>
      </StyledElemezTokenContent>
    </StyledElemezToken>
  );
};

import React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { Button } from '../../../../../../../components/controls/button';
import { ButtonColours } from '../../../../../../../containers/app/themes';

interface Props {
  zoomedIn: boolean,
  resetZoom: () => void
}
export function ResetZoomButton(props: Props) {
  const { t } = useTranslation('performance');
  const { resetZoom, zoomedIn } = props;
  return (
    <Button
      colour={ButtonColours.grey}
      className={`reset-zoom__button ${zoomedIn ? '' : 'reset-zoom__button--disabled'} panel-button`}
      text={t('RESET_ZOOM')}
      iconBeforeText={true}
      iconStyle="fas fa-undo-alt"
      disabled={!zoomedIn}
      onClick={resetZoom}
    />
  );
}

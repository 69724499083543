import React, { useEffect, useState, useMemo, useContext, useCallback, useReducer, Reducer } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import * as am4charts from '@amcharts/amcharts4/charts';

import {
  getSelectedDateDeviceEventsList
} from '../../../../../services/core/devicePerformance';

import { DeviceEvent, DeviceEventName } from '../../../../../services/core/eventsTypes';
import { DeviceInfoContext } from '../../index';


import { useWorldRequest } from '../../../../../lib/useWorldRequest';
import { useFeatureTogglesContext } from '../../../../../context/featureToggles';

import { DeviceEventFilters, DeviceNetworkChartEventsFilterState, deviceEventFilterGroupNames } from '../performance/events/deviceEventsTypes';
import { EditEvents, EditEventsAction, editEventsReducer, getActiveEventFilters, useEditEventsDispatch } from '../performance/events/edit-events';
import { NetworkChartName, networkChartName, networkChartNamesArray } from '../performance/charts/lib/chartNames';
import { filterDeviceEvents, getDeviceEventFilterGroups, getDeviceEvents } from '../performance/events/deviceEvents';
import { EventsShown } from '../performance/events-shown';
import { StackedColumnsDateChart } from '@components/chart/stackedColumnsDateChart';

import '../performance/events/deviceEvents.css';
import '../performance/events/edit-events.css';
import '../performance/events-shown.css';
import { SignalStrengthChartData, getLevelToDescriptionMapping, getTooltipAdapter } from './index';
import { Color } from '@amcharts/amcharts4/core';
import { drawSelectedDateXAxisLabels } from '@components/chart/lib';
import { useTimeout } from '@lib/useTimeout';
import './edit-events.css';
import './charts.css';
import { useUserSettingsContext } from '../../../../../context/userSettings';
import { roundToNearest5Minutes } from '@lib/timeUtils';
import { getInitialFilters } from '../performance/index';

interface IProps {
  selectedDate: number,
  barColours: string[],
  activeBar: { local: number } | undefined,
  setActiveMapMarker: React.Dispatch<React.SetStateAction<{ local: number, zoomTo: boolean } | undefined>>,
  numberOfBars: number,
  data: SignalStrengthChartData[],
  noSignalDataAvailable: boolean
}

interface IRequestParams {
  deviceId: string,
  to: number,
  events?: DeviceEventName[]
}

type EventsWithRoundedTime = {
  event: DeviceEvent,
  date: string,
  tooltipDate: number
};

function getRequestParams(deviceId: string, dates: { to: number }): IRequestParams {
  const { to } = dates;
  return { deviceId, to };
}

export const initialNetworkEventFilters: DeviceEventFilters = {
  applicationInstalled: false,
  applicationUninstalled: false,
  applicationUpdated: false,
  batteryChanged: false,
  deviceOnPower: false,
  deviceOffPower: false,
  deviceLowPower: false,
  deviceRebooted: true,
  deviceDropped: true,
  timeError: false,
  timeRecovery: false,
  networkChanged: true,
  networkAvailable: true,
  networkLost: true,
  bearerChanged: true,
  simChanged: true,
  airplaneModeOff: false,
  airplaneModeOn: false,
  mobileRadioOff: false,
  mobileRadioOn: false
};

export const getEventsWithRoundedTime = (deviceEvents: DeviceEvent[]): EventsWithRoundedTime[] => {
  return deviceEvents.map((event: DeviceEvent) => {
    const local = roundToNearest5Minutes(event.local);

    return {
      event,
      date: moment.utc(local).format('HH:mm'),
      tooltipDate: event.local
    };
  });
};

export const getAllSeriesData = (signalStrengthData: SignalStrengthChartData[], deviceEvents: DeviceEvent[]): SignalStrengthChartData[] => {

  const signalDataWithEvents: SignalStrengthChartData[] = signalStrengthData.slice();
  const eventsWithRoundedTime = getEventsWithRoundedTime(deviceEvents);

  // Find the index of the signal data that has data avalable
  const createDateLookup = signalDataWithEvents.reduce((acc: any, data: any, index: number) => {
    acc[data.date] = { index };
    return acc;
  }, {});

  // Update signal data with the events data if  timestamp is same
  eventsWithRoundedTime.forEach((event: EventsWithRoundedTime) => {
    const dateEntry = createDateLookup[event.date];
    if (dateEntry) {
      const data = signalDataWithEvents[dateEntry.index];
      const events = data.events ? data.events.concat(event.event) : [event.event];

      if (data.noDataAvailable || data.radioOff) {
        signalDataWithEvents[dateEntry.index] = { ...data, networkEvents: 0, events: events, tooltipDate: event.tooltipDate };
      } else {
        signalDataWithEvents[dateEntry.index] = { ...data, events: events, tooltipDate: event.tooltipDate };
      }
    }
  });

  return signalDataWithEvents;
};

export function SelectedDateChart(props: IProps) {
  const {
    selectedDate,
    barColours,
    activeBar,
    setActiveMapMarker,
    numberOfBars,
    data,
    noSignalDataAvailable
  } = props;
  const featureToggles = useFeatureTogglesContext();
  const userSettings = useUserSettingsContext();
  const { platformType, id: deviceId, timeZone } = useContext(DeviceInfoContext);
  const { t } = useTranslation('deviceNetwork');

  const [allEvents, setAllEvents] = useState([]);
  const [deviceNetworkEvents, setDeviceNetworkEvents] = useState([]);
  const [chartDataLoading, setChartDataLoading] = useState(false);

  const initialChartFilters = getInitialFilters(userSettings, featureToggles, platformType, 'chart', true);
  const [chartEventFilters, dispatchChartEventFilters] = useReducer<Reducer<DeviceNetworkChartEventsFilterState, EditEventsAction<NetworkChartName>>>(
    editEventsReducer,
    initialChartFilters
  );

  const eventFilterDeviceNetwork = chartEventFilters.deviceNetwork;

  const { addTimeout } = useTimeout();

  const deviceEventNames = useMemo(() => {
    return Object.values(getDeviceEvents(platformType, featureToggles)).map(event => event.name);
  }, [platformType, featureToggles]);

  const dates = useMemo(() => ({
    from: moment.utc(selectedDate).startOf('day').valueOf(),
    to: moment.utc(selectedDate).endOf('day').valueOf()
  }), [selectedDate]);

  const {
    handleUpdate, handleApply, handleCancel
  } = useEditEventsDispatch(
    dispatchChartEventFilters, networkChartName.deviceNetwork, setLoadingTrue
  );
  const onEventsFetched = useCallback((events: DeviceEvent[]) => {
    setAllEvents(events);
  }, []);

  function setLoadingTrue() {
    setChartDataLoading(true);
  }

  const eventsDataFetcher = useCallback(() => {
    if (deviceId && !noSignalDataAvailable) {
      const params = getRequestParams(deviceId, dates);
      return getSelectedDateDeviceEventsList({ ...params, events: deviceEventNames });
    }
  }, [deviceId, dates, deviceEventNames, noSignalDataAvailable]);

  useWorldRequest(eventsDataFetcher, { initialLoading: false, initialData: [], onSuccess: onEventsFetched });

  const activeEventsDeviceNework = useMemo(() => getActiveEventFilters(eventFilterDeviceNetwork.appliedEventFilters), [eventFilterDeviceNetwork.appliedEventFilters]);

  useEffect(() => {
    const [deviceNetworkEvents] = filterDeviceEvents(
      allEvents, activeEventsDeviceNework
    );
    setDeviceNetworkEvents(deviceNetworkEvents);
  }, [allEvents, activeEventsDeviceNework]);

  return (
    <div className={`core-device_signal-strength ${!noSignalDataAvailable && !chartDataLoading ? 'chart-ready' : '' }`}>
      <div className="chart-header">
        <div className="chart-title">{t(`SIGNAL_LEVEL`)}</div>
        <div className="chart-edit-events">
          <EventsShown eventFilters={eventFilterDeviceNetwork.appliedEventFilters} />
          <EditEvents
            subheader={t(`SIGNAL_LEVEL`)}
            modalSubHeader={t('EDIT_EVENTS_MODAL_HEADER')}
            eventGroupNames={deviceEventFilterGroupNames}
            eventFilterGroups={getDeviceEventFilterGroups(platformType, featureToggles)}
            eventFilterState={eventFilterDeviceNetwork.currentEventFilters}
            handleUpdate={handleUpdate}
            handleApply={handleApply}
            handleCancel={handleCancel}
          />
        </div>
      </div>
      <StackedColumnsDateChart<SignalStrengthChartData>
        data={getAllSeriesData(data, deviceNetworkEvents)}
        onlyShowCurrentSeriesInTooltip={true}
        series={[
          ...barColours.map(/* istanbul ignore next - acceptance tested due to jsdom limitations */(colour, i) => {
            const seriesKey = i === 0 ? 'noService' : (i - 1).toString() as keyof (Omit<SignalStrengthChartData, 'date'>);

            return {
              linePattern: seriesKey === 'noService' ? {
                width: 5,
                height: 5,
                strokeWidth: 1,
                stroke: new Color({ r: 0, g: 0, b: 0 }),
                rotation: -45
              } : undefined,
              visible: true,
              dataKey: seriesKey,
              description: seriesKey,
              colour,
              animate: (column: any) => { return column.dataItem.dataContext.local === activeBar?.local; },
              onBarClick: (ev: any) => {
                const data = ev.target.dataItem.dataContext;
                setActiveMapMarker({ local: data.local, zoomTo: false });

                addTimeout(() => {
                  setActiveMapMarker(s => {
                    return data.local === s?.local ? undefined : s;
                  });
                }, 2000);
              },
              onBarDblClick: (ev: any) => {
                const data = ev.target.dataItem.dataContext;
                setActiveMapMarker({ local: data.local, zoomTo: true });

                addTimeout(() => {
                  setActiveMapMarker(s => {
                    return data.local === s?.local ? undefined : s;
                  });
                }, 2000);
              }
            };
          }),
          {
            visible: true,
            dataKey: 'greyedOut',
            description: '',
            colour: '#D9D9D9'
          },
          {
            visible: true,
            dataKey: 'networkEvents',
            description: '',
            colour: '#FFFFFF '
          }
        ]}
        title=''
        cypressId='signalStrength'
        days={1}
        drawXAxis={(_, xAxis) => {
          drawSelectedDateXAxisLabels(xAxis as am4charts.CategoryAxis, dates.to, 5);
          xAxis.title.text = `[font-style: italic]${t('DEVICE_LOCAL_TIME', { ns: 'translation', timeZone: timeZone?.name ? ` (${timeZone.name})` : '' })}[/]`;
        }}
        tooltipDateKey='local'
        hasLegend={false}
        columnWidth={70}
        drawYAxis={(_, yAxis) => {
          const levelToDescriptionMapping = getLevelToDescriptionMapping(numberOfBars, t);

          yAxis.min = 0;
          yAxis.max = barColours.length - 1;
          yAxis.strictMinMax = true;

          yAxis.title.dx = -15;

          yAxis.renderer.grid.template.disabled = true;

          yAxis.renderer.labels.template.disabled = true;
          yAxis.renderer.labels.template.horizontalCenter = "middle";
          yAxis.renderer.ticks.template.disabled = true;
          yAxis.renderer.ticks.template.hidden = true;

          yAxis.axisRanges.clear();

          new Array(barColours.length - 1).fill(0).forEach((_, barLevel) => {
            const range = yAxis.axisRanges.create();
            range.label.text = levelToDescriptionMapping[barLevel];
            range.value = barLevel + 0.5;
            range.label.disabled = false;
          });
        }}
        hasNoSelectionOverlay={false}
        eventOverlay={true}
        dateAxisEndDuration={{ amount: "5", unit: 'minute' }}
        useAxisTooltip={true}
        getAxisTooltipAdapter={() => getTooltipAdapter({ numberOfBars, t })}
        className='core-device_signal-strength-chart-container'
      />
    </div>
  );
}

import React from "react";
import { useTranslation } from "@lib/useTypedTranslation";
import { useWorldRequest } from "@lib/useWorldRequest";
import { Option } from "@components/controls/optionPickerLib";
import { getSimProviders } from "../../../services/core/maps";

export const useSimProviderOptions = () => {
  const { t } = useTranslation('maps');
  const simProvidersRequest = React.useCallback(() => getSimProviders({ timePeriod: 30 }), []);
  const { data: simProviders, loading: simProvidersLoading } = useWorldRequest(simProvidersRequest, { initialLoading: true });

  const simProviderOptions = React.useMemo(() => {
    const apiOptions = simProviders?.map((i: string) => ({ name: i, value: i })) ?? [];
    return [{ name: t('ALL_SIM_PROVIDERS'), value: undefined }, ...apiOptions];
  }, [simProviders, t]);
  const [simProvider, setSimProvider] = React.useState<Option>(simProviderOptions[0]);

  React.useEffect(() => {
    if (!simProvider && simProviderOptions.length > 0) {
      setSimProvider(simProviderOptions[0]);
    }
  }, [simProvider, simProviderOptions]);

  return {
    simProviderOptions,
    simProvider,
    setSimProvider,
    simProvidersLoading
  };
};

import * as React from "react";
import { ClusterMap } from "./map";
import { DevicesCard } from "./devicesCard";

import './clusterMap.css';
import { DeviceClusterResponse, DeviceStatsResponse } from "services/core/maps";
import { RequestInitWithRetry } from "@lib/request";

export const ClusterContext = React.createContext<{
  selectedCluster: string[],
  setSelectedCluster: React.Dispatch<React.SetStateAction<string[]>>
}>({
  selectedCluster: undefined,
  setSelectedCluster: undefined
});

export interface ClusterMapContainerProps {
  deviceStats: DeviceStatsResponse,
  fetchMapData: (boundingLatLong: string, geotilePrecision: number) => (options: RequestInitWithRetry) => Promise<DeviceClusterResponse>
}
export const ClusterMapContainer = (props: ClusterMapContainerProps) => {
  const [selectedCluster, setSelectedCluster] = React.useState<string[]>();

  return <ClusterContext.Provider value={{ selectedCluster, setSelectedCluster }}>
    <div className="cluster-map-container">
      <ClusterMap deviceStats={props.deviceStats} fetchMapData={props.fetchMapData} />
      <DevicesCard key={selectedCluster?.join()} />
    </div>
  </ClusterContext.Provider>;
};

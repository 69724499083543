import React, { ReactNode, ChangeEvent, KeyboardEvent, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import './pagination.css';
import { ButtonColours } from '../../../containers/app/themes';
import styled from 'styled-components';
import { getLastPage, getOffsetFromPage, StyledPaginationSelectButton } from './paginationLib';
import { PaginationNavigation } from './paginationNavigation';

interface IProps {
  total: number,
  offset: number,
  limit: number,
  pageSizes?: number[],
  onNewPage: (newOffset: number) => void,
  onNewLimit: (newLimit: number) => void,
  children?: ReactNode
}


const StyledPageInput = styled.input`
  width: 60px;
  border: 1px solid #c2d1e0;
  border-radius: 3px;
  padding: 0.125rem;
`;

StyledPageInput.displayName = 'PageInput';

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
`;

export const defaultPageSizes = [10, 20, 30, 40, 50];


export function PaginationBar(props: IProps) {
  const { total, offset, limit, onNewPage, onNewLimit, pageSizes = defaultPageSizes, children } = props;
  const [goToPage, setGoToPage] = useState('');
  const { t } = useTranslation();

  const sortedPageSizes = [...new Set([...pageSizes, limit])].sort();

  const { lastPage } = getLastPage(total, limit);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const numbersRe = /^[0-9]+$/;
    if (e.target.value === '' || numbersRe.test(e.target.value)) {
      setGoToPage(e.target.value);
    }
  };

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onGoToPage();
    }
  };

  const onGoToPage = () => {
    if (!goToPage) { return; }
    const page = Math.max(1, Math.min(Number(goToPage), lastPage));
    onNewPage(getOffsetFromPage(page, limit));
  };

  const select = (
    <div className="pagination-bar-component__page-size">
      <span className="pagination-bar-component__page-size__text">{t('PER_PAGE')}:</span>
      <select aria-label="pagination page size selection" className="pagination-bar-component__page-size__select" value={limit} onChange={(e) => onNewLimit(Number(e.target.value))} >
        {sortedPageSizes.map(s => <option className="pagination-bar-component__page-size__option" value={s} key={s}>{s}</option>)}
      </select>
    </div>
  );

  const message = children && (
    <div className="pagination-bar-component__message">
      {children}
    </div>
  );


  const goTo = (
    <div className="pagination-bar-component__go-to-page">
      <span className="pagination-bar-component__go-to-page__text">{t('JUMP_TO_PAGE')}:</span>
      <StyledForm onSubmit={e => { e.preventDefault(); }}>
        <StyledPageInput
          autoFocus={Boolean(goToPage)}
          type="text"
          onChange={onChange}
          onKeyPress={onKeyPress}
          value={goToPage}
          disabled={!total}
          data-id='pagination-goTo-input'
          aria-label="navigation jump to page number"/>
        <StyledPaginationSelectButton
          colour={ButtonColours.grey}
          onClick={onGoToPage}
          disabled={!goToPage}
          text={t('GO')}
          dataId='pagination-goTo-button'
          ariaLabel="navigation jump to page"
        />
      </StyledForm>
    </div>
  );

  return (
    <div className="pagination-bar-component">
      <div className="pagination-bar-component__left">
        {message}
        {select}
      </div>
      <div className="pagination-bar-component__right">
        {goTo}
        <PaginationNavigation {...{ limit, offset, onNewPage, total }} />
      </div>
    </div>
  );
}

import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { useLanguageAndLocaleContext } from '../../context/languageAndLocale';
import { getListTotal } from '../data-table/pagination/paginationLib';

export const calculatePageRange = (offset: number, limit: number, total: number, locale: string) => {
  return total === 0
    ? '0'
    : `${(offset + 1).toLocaleString(locale)} - ${Math.min(offset + limit, total).toLocaleString(locale)}`;
};

interface IProps {
  loading?: boolean,
  offset: number,
  limit: number,
  total: number,
  item: string,
  searchString?: string,
  fullStatusText?: boolean
}

export function StatusText(props: IProps) {
  const { loading, offset, limit, total, item, searchString } = props;
  const { t } = useTranslation('translation');
  const { locale } = useLanguageAndLocaleContext();
  const countReady = loading ? '' : 'count-ready';

  let count = (
    <span className={`status-text__count ${countReady}`}>
      <b>{calculatePageRange(offset, limit, getListTotal(total), locale)}</b> {t('OF')} <b>{total.toLocaleString(locale)}</b>
    </span>
  );

  if (props.fullStatusText) {
    if (total < limit) {
      count = <span className={`status-text__count ${countReady}`}><b>{total.toLocaleString(locale)}</b></span>;
    }
    return <span className="status-text">{count} {item}</span>;
  }

  const search = searchString && <span className='status-text__search'> {t('MATCHING_SEARCH')} "<b>{searchString}</b>"</span>;

  return <span className="status-text">{count} {item}{search}</span>;
}

import React, { useContext } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import * as am4charts from '@amcharts/amcharts4/charts';

import { exportDeviceDataUsageChartAction, IDevicesDataUsage } from '../../../../services/core/performance';
import { getFormattedTitle, getTimeZoneOffset, getTitle } from './lib';
import { getUserTimeZone } from '../../../../lib/timeZone';
import { useWorldAction } from '../../../../lib/useWorldAction';
import { Filter } from '../../../../components/timePeriodAndFilterPicker/useFilterPicker';
import { drawYAxis } from '../../../../lib/dataUsageChart';
import { StackedColumnsDateChart } from '../../../../components/chart/stackedColumnsDateChart';
import { useDataUsageSeriesSwitch } from '../../../../components/chart/useDataUsageSeriesSwitch';
import { drawXAxisLabels } from '../../../../components/chart/lib';
import { formatTooltipDay } from '../../../../components/chart/tooltips';

interface IProps {
  data: IDevicesDataUsage[],
  filter: Filter,
  days: number,
  onMount: (chart: am4charts.XYChart) => am4charts.XYChart
}

export function CorePerformanceDataUsage(props: IProps) {
  const exportChartData = useWorldAction(exportDeviceDataUsageChartAction);
  const ns = 'translation';
  const { t } = useTranslation(['performance', ns, 'timeState', 'filters']);
  const title = getTitle(props.filter, props.days, t);

  const {
    buttons: dataUsageSwitchButtons,
    mobileSeriesVisible,
    wifiSeriesVisible
  } = useDataUsageSeriesSwitch();

  const formattedTitle = getFormattedTitle(title);

  const onExportClicked = async (): Promise<string> => {
    const params = {
      days: props.days,
      timeZone: getUserTimeZone(),
      homeLocationId: props.filter.homeLocation?.value.id,
      group: props.filter.group?.value,
      zoneId: props.filter.zone?.value.id
    };
    return exportChartData(params);
  };

  const timeZoneOffset = getTimeZoneOffset(getUserTimeZone());

  return (
    <div className= 'core-performance-chart-section'>
      <StackedColumnsDateChart<any>
        data={props.data.map((d) => ({
          date: parseInt(d.date),
          mobile: d.mobile,
          wifi: d.wifi
        }))}
        className={`core-performance-data-usage-chart`}
        series={[{
          visible: mobileSeriesVisible,
          dataKey: 'mobile',
          description: t('MOBILE_DATA', { ns: 'translation' }),
          colour: '#49a1a9'
        },
        {
          visible: wifiSeriesVisible,
          dataKey: 'wifi',
          description: t('WIFI_DATA', { ns: 'translation' }),
          colour: '#2c678c'
        }]}
        totalDescription={t('TOTAL_DATA', { ns: 'translation' })}
        days={props.days}
        cypressId="corePerformanceDataUsage"
        title={title}
        onMount={props.onMount}
        drawYAxis={(chart, axis, enabledSeries) => drawYAxis(chart.data, axis, enabledSeries)}
        amchartsYAxisNumberFormat="#.00b"
        buttons={dataUsageSwitchButtons}
        export={{
          filename: `${formattedTitle}.${moment.utc().toISOString()}.csv`,
          onExportClicked: () => onExportClicked(),
          csv: true
        }}
        xAxisType={'date'}
        drawXAxis={(_, xAxis) => drawXAxisLabels(xAxis as am4charts.DateAxis, props.days, timeZoneOffset)}
        formatTooltipDate={formatTooltipDay}
        hasLegend={true}
        useAxisTooltip
      />
    </div>
  );
}

import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import styled from 'styled-components';

import {
  getLast24HourDeviceEventsList,
  getLast30DayDeviceEventsList,
  getSelectedDateDeviceEventsList,
  getLast24HoursDeviceEventsCount,
  getLast30DaysDeviceEventsCount,
  getSelectedDateDeviceEventsCount
} from '../../../../../services/core/devicePerformance';
import { useTableReducer, InitialTableState, TableName } from '../../../../../components/data-table/lib';
import { DeviceInfoContext } from '../../index';
import { EditEvents, EditEventsAction, useEditEventsDispatch, getActiveEventFilters } from './events/edit-events';
import { deviceEventFilterGroupNames, IDeviceEventFilterState } from './events/deviceEventsTypes';
import { getDeviceEventFilterGroups } from './events/deviceEvents';
import { EventsShown } from './events-shown';
import { useUserSettingsContext } from '../../../../../context/userSettings';
import { DeviceEvent } from '../../../../../services/core/eventsTypes';
import { useWorldRequest } from '../../../../../lib/useWorldRequest';
import { useFeatureTogglesContext } from '../../../../../context/featureToggles';
import { AdditionalItems, List } from '../../../../../components/list/list';
import { usePerformanceEventsListColumns } from './usePerformanceEventsListColumns';
import { Button } from '../../../../../components/controls/button';
import { ButtonColours } from '../../../../app/themes';
import { TTypedTFunction } from '@lib/useTypedTranslation';

import './performance-events.css';
import './events/deviceEvents.css';

const StyledEventsList = styled.div`
  border-top: 1px solid #d7e2eb;  
`;

export enum PerformanceView {
  last24Hours = '24-hour',
  last30days = '30-day',
  selectedDate = 'selected-date'
}

interface BaseProps {
  eventFilters: IDeviceEventFilterState,
  dispatchEventFilters: React.Dispatch<EditEventsAction<TableName>>
}

interface Props24Hours extends BaseProps {
  currentView: PerformanceView.last24Hours
}

interface Props30Days extends BaseProps {
  currentView: PerformanceView.last30days,
  to: number,
  from: number
}

interface PropsSelectedDate extends BaseProps {
  currentView: PerformanceView.selectedDate,
  to: number
}

export const tableName30Days = 'eventsList30Days';
export const tableName24Hours = 'eventsList24Hours';
export const defaultPageSize = 20;

const EventsListText = (props: { showList: boolean, setShowList: (state: boolean) => void, t: TTypedTFunction }) => {
  return (
    <button className="performance-events-list-text_button" type="button" onClick={() => { props.setShowList(!props.showList); }}>{props.showList ? props.t('HIDE_EVENTS_TEXT') : props.t('SHOW_EVENTS_TEXT')}</button>
  );
};

const EventsListIcon = (props: { showList: boolean, setShowList: (state: boolean) => void }) => {
  return (
    <Button
      colour={ButtonColours.grey}
      ariaLabel={props.showList ? "events list minus icon button" : "events list plus icon button"}
      dataId="performance-events-list-icon_button"
      onClick={() => props.setShowList(!props.showList)}
      iconBeforeText={true}
      iconStyle={props.showList ? 'fa fa-minus' : 'fa fa-plus'}
    />
  );
};


export function PerformanceEvents(props: Props24Hours | Props30Days | PropsSelectedDate) {
  const { t } = useTranslation('performance');
  const { currentView, eventFilters, dispatchEventFilters } = props;
  const to = 'to' in props ? props.to : undefined;
  const from = 'from' in props ? props.from : undefined;
  const tableName = currentView === PerformanceView.last30days ? tableName30Days : tableName24Hours;
  const { id: deviceId, platformType } = useContext(DeviceInfoContext);
  const featureToggles = useFeatureTogglesContext();
  const { tablePageSizes } = useUserSettingsContext();
  const [showList, setShowList] = useState(false);
  const [eventsListFetched, setEventsListFetched] = useState(false);
  const columns = usePerformanceEventsListColumns();

  // loading list data is sometimes deferred (e.g. when props change but list is hidden),
  // so we fetch data when the list is visible if the fetchingId is not equal to the current dataRequestId,
  // and set fetchingId to the current dataRequestId when we actually fetch data.
  const [fetchingId, setFetchingId] = useState<number>();

  const events = useMemo(() => getActiveEventFilters(eventFilters.appliedEventFilters), [eventFilters.appliedEventFilters]);

  const eventsCountDataFetcher = useCallback(() => {
    if (deviceId) {
      let getCountPromise;
      if (currentView === PerformanceView.last30days) {
        getCountPromise = getLast30DaysDeviceEventsCount({ deviceId, to, from, events });
      } else if (currentView === PerformanceView.selectedDate) {
        getCountPromise = getSelectedDateDeviceEventsCount({ deviceId, to, events });
      } else {
        getCountPromise = getLast24HoursDeviceEventsCount({ deviceId, to: moment.utc().endOf('hour').valueOf(), events });
      }
      return getCountPromise;
    }
  }, [
    deviceId,
    currentView,
    from,
    to,
    events
  ]);

  const {
    loading: totalLoading,
    data: total
  } = useWorldRequest(eventsCountDataFetcher, { initialLoading: true, initialData: { count: 0 } });

  const initialTableState: InitialTableState = {
    limit: tablePageSizes?.[tableName] || defaultPageSize,
    sort: { column: 3, direction: 'desc', field: 'local' }
  };

  const [tableReducerProperties, tableReducerFunctions] = useTableReducer<DeviceEvent>(tableName, initialTableState);
  const { offset, limit, sort, dataRequestId } = tableReducerProperties;
  const { onDataLoaded, onLoading } = tableReducerFunctions;

  useEffect(() => {
    onLoading();
  }, [to, from, onLoading]);

  const {
    handleUpdate, handleApply, handleCancel
  } = useEditEventsDispatch(
    dispatchEventFilters, props.currentView === PerformanceView.last30days ? tableName30Days : tableName24Hours, onLoading
  );

  const shouldFetchEventData = showList && (fetchingId !== dataRequestId || !eventsListFetched);

  const onEventsListFetched = useCallback((events: DeviceEvent[]) => {
    setEventsListFetched(true);
    onDataLoaded(events, total.count, dataRequestId);
    showList && window.scrollTo(0, document.body.scrollHeight);
  }, [dataRequestId, onDataLoaded, showList, total.count]);

  const eventsListDataFetcher = useCallback(() => {
    if (shouldFetchEventData) {
      setFetchingId(dataRequestId);
      setEventsListFetched(false);
      const params = { limit, offset, sort: { field: sort.field, order: sort.direction }, deviceId, events };
      if (currentView === PerformanceView.last30days) {
        return getLast30DayDeviceEventsList({ ...params, to, from });
      } else if (currentView === PerformanceView.selectedDate) {
        return getSelectedDateDeviceEventsList({ ...params, to });
      } else {
        return getLast24HourDeviceEventsList({ ...params, to: moment.utc().endOf('hour').valueOf() });
      }
    }
  }, [
    shouldFetchEventData, dataRequestId, limit, offset, sort.field,
    sort.direction, deviceId, events, currentView, to, from,
  ]);

  const translationItems = {
    statusText: {
      text: t('EVENTS_FOOTER', { count: total.count })
    },
    tableTitle: {
      text: t('EVENTS_HEADER_other', { count: total.count })
    }
  };

  const additionalHeaderButtons: AdditionalItems = [{
    Component: EventsListText,
    props: {
      showList,
      setShowList,
      t
    }
  },
  {
    Component: EventsListIcon,
    props: {
      showList,
      setShowList
    }
  }];

  const tableClass = `core-device_performance-${currentView}-events-list performance-events-list_table`;
  return (
    <StyledEventsList className={`${tableClass} ${totalLoading ? '' : 'data-loaded'}`} data-id={`${totalLoading ? '' : 'data-loaded'}`}>
      <List<DeviceEvent, DeviceEvent[]>
        dataId={`${currentView}-performance-events-list`}
        total={total.count}
        customHeader={false}
        hasCheckboxes={false}
        hasFilters={false}
        hasSearch={false}
        translationItems={translationItems}
        columns={columns}
        fetcher={eventsListDataFetcher}
        onListFetched={onEventsListFetched}
        tableReducerFunctions={tableReducerFunctions}
        tableReducerProperties={tableReducerProperties}
        useRequestHook={useWorldRequest}
        options={{ getKey: (item) => `${item?.name}--${item.local}` }}
        additionalHeaderButtons={additionalHeaderButtons}
        listNotVisible={!showList}
        additionalHeaderItemsVisible={showList}
        additionalHeaderItems={[{
          Component: EventsShown,
          props: {
            eventFilters: eventFilters.appliedEventFilters
          }
        },
        {
          Component: EditEvents,
          props: {
            forTable: true,
            eventGroupNames: deviceEventFilterGroupNames,
            eventFilterGroups: getDeviceEventFilterGroups(platformType, featureToggles),
            eventFilterState: eventFilters.currentEventFilters,
            handleUpdate: handleUpdate,
            handleApply: handleApply,
            handleCancel: handleCancel
          }
        }]}
      />
    </StyledEventsList>
  );
}

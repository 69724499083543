import * as React from "react";
import { useTimePeriodPicker } from "../../../../../components/timePeriodAndFilterPicker/useTimePeriodPicker";
import { ChipButton } from "../../../../../components/controls/chipButton";
import { useTranslation } from "@lib/useTypedTranslation";

import { TimePeriodPicker } from "../../../../../components/timePeriodAndFilterPicker/timePeriodAndFilterPicker";
import { DeviceInfoContext } from "../..";
import { DeviceCoverageMap, MapData } from "../../../../../components/maps/intensityMaps/deviceCoverage";

import './deviceCoverage.css';

export const DeviceCoverage = () => {
  const { t } = useTranslation('maps');

  const {
    timePeriod,
    timePeriodOptions,
    handleTimePeriodSelect
  } = useTimePeriodPicker({ timePeriods: [30, 7, 2] });

  const [selectedMapData, setSelectedMapData] = React.useState<MapData>(MapData.poorNoSignal);

  const { id: deviceId } = React.useContext(DeviceInfoContext);

  return <div className="core-device-coverage">
    <div className="core-device-coverage_header">
      <div className="core-device-coverage_headings">
        <h2>{t(`${selectedMapData}_HEADING`)}</h2>
        <h3>{t(`${selectedMapData}_SUBHEADING`)}</h3>
      </div>
      <div className="core-device-coverage_filters">
        <div className="core-device-coverage_time-picker-container">
          <TimePeriodPicker
            handleTimePeriodSelect={handleTimePeriodSelect}
            timePeriod={timePeriod}
            timePeriodOptions={timePeriodOptions}
          />
        </div>
        <div className="core-device-coverage_radio-container">
          {Object.keys(MapData).map(key => {
            const mapData = MapData[key as keyof typeof MapData];
            return <ChipButton
              key={key}
              active={mapData === selectedMapData}
              text={t(`${mapData}_TAB`)}
              onClick={() => setSelectedMapData(mapData)}
            />;
          })}
        </div>
      </div>
    </div>
    <DeviceCoverageMap
      deviceId={deviceId}
      mapData={selectedMapData}
      timePeriod={timePeriod.value}
    />
  </div>;
};

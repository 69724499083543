import * as React from "react";
import { range } from 'lodash';
import classNames from 'classnames';
import styled from 'styled-components';

import { ButtonColours } from "../../../containers/app/themes";
import { getLastPage, getOffsetFromPage, getPageFromOffset, StyledPaginationSelectButton } from "./paginationLib";


const getPageRage = (first: number, last: number, limit: number): [number, number][] => {
  return range(first, last + 1).map(p => [p, getOffsetFromPage(p, limit)]);
};

const getLinkPages = (currentPage: number, lastPage: number, limit: number): [number, number][] => {
  if (lastPage <= 5) {
    return getPageRage(1, lastPage, limit);
  }
  const isStart = currentPage < 4;
  const isEnd = currentPage > lastPage - 3;
  const first: [number, number] = [1, 0];
  const last: [number, number] = [lastPage, getOffsetFromPage(lastPage, limit)];
  if (isStart) {
    return [...getPageRage(1, 4, limit), last];
  }
  if (isEnd) {
    return [first, ...getPageRage(lastPage - 3, lastPage, limit)];
  }
  return [first, ...getPageRage(currentPage - 1, currentPage + 1, limit), last];
};

const StyledNavIcon = styled.i<{ disabled: boolean }>`
  ${({ disabled }) => disabled ? 'color: #d8d8d8' : 'color: inherit'}
`;

StyledNavIcon.displayName = 'NavIcon';

const StyledPaginationPrev = styled(StyledPaginationSelectButton)`
  margin-right: 8px;
  margin-left: 0;
`;

StyledPaginationPrev.displayName = 'PaginationPrev';

const StyledPaginationNext = styled(StyledPaginationSelectButton)`
  margin-left: 8px;
  margin-right: 0;
`;

StyledPaginationNext.displayName = 'PaginationNext';

const StyledPageLinkButton = styled.button`
  border: none;
  background: none;
  &:active && :hover {
    outline: none;
    border: none;
  }
`;

StyledPageLinkButton.displayName = 'PageLinkButton';


export interface IProps {
  total: number,
  offset: number,
  limit: number,
  onNewPage: (newOffset: number) => void,
  className?: string
}

export const PaginationNavigation: React.FC<IProps> = ({ total, offset, limit, onNewPage, className }) => {
  const currentPage = getPageFromOffset(offset, limit);

  const { lastPage, lastPageOffset } = getLastPage(total, limit);

  const links = getLinkPages(currentPage, lastPage, limit)
    .map(([page, offset], i, pages) => {
      const ellipsis = page > 1 && (page - 1 !== pages[i - 1][0]) && <span aria-label="navigation ellipsis abbreviation" className="pagination-bar-component__page-link__ellipsis">…</span>;
      const isCurrent = page === currentPage;
      const className = classNames(
        'pagination-bar-component__page-link',
        { 'pagination-bar-component__page-link--active': isCurrent }
      );
      return (
        <div className={className} key={`page-link-${page}`}>
          {ellipsis}
          <StyledPageLinkButton aria-label={`navigation jump to page ${page}`} className="pagination-bar-component__page-link__number" onClick={() => !isCurrent && onNewPage(offset)}>
            {page}
          </StyledPageLinkButton>
        </div>
      );
    });

  return <div className={classNames("pagination-bar-component__navigation", className)}>
    <StyledPaginationPrev
      colour={ButtonColours.grey}
      iconStyle="fa fa-chevron-left"
      disabled={currentPage === 1}
      onClick={() => onNewPage(Math.max(offset - limit, 0))}
      iconComponent={<StyledNavIcon disabled={currentPage === 1} className="fa fa-chevron-left"/>}
      dataId='pagination-pagePrev'
      ariaLabel="navigation previous page"
    />
    <div className="pagination-bar-component__page-links">
      {Boolean(total) && links}
    </div>
    <StyledPaginationNext
      colour={ButtonColours.grey}
      iconStyle="fa fa-chevron-right"
      disabled={currentPage === lastPage}
      onClick={() => onNewPage(Math.min(offset + limit, lastPageOffset))}
      iconComponent={<StyledNavIcon disabled={currentPage === lastPage} className="fa fa-chevron-right"/>}
      dataId='pagination-pageNext'
      ariaLabel="navigation next page"
    />
  </div>;
};

import React, { useContext } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';

import * as am4charts from '@amcharts/amcharts4/charts';

import { exportDeviceEventChartAction, IDeviceEventCount, IDevicePerformanceEventsParams } from '../../../../services/core/performance';
import { getTitle, getFormattedTitle, getEventsChartData, getTimeZoneOffset } from './lib';
import { drawXAxisLabels } from '../../../../components/chart/lib';
import { DeviceEventCountName } from '../../../../services/core/eventsTypes';
import { getUserTimeZone } from '../../../../lib/timeZone';
import { useWorldAction } from '../../../../lib/useWorldAction';
import { Filter } from '../../../../components/timePeriodAndFilterPicker/useFilterPicker';

import './charts.css';
import { ITranslationKeys } from 'components/i18n/keys';
import { StackedColumnsDateChart } from '../../../../components/chart/stackedColumnsDateChart';
import { upperFirst } from 'lodash';
import { formatTooltipDay } from '@components/chart/tooltips';

interface IProps {
  data: IDeviceEventCount[],
  filter: Filter,
  days: number,
  name: DeviceEventCountName,
  onMount: (chart: am4charts.XYChart) => am4charts.XYChart
}

export function CorePerformanceEvents(props: IProps) {
  const { data, filter, days, name } = props;
  const exportChartData = useWorldAction(exportDeviceEventChartAction);
  const { translationKey, colourCode } = getEventsChartData(name);
  const ns = 'translation';
  const { t } = useTranslation(['performance', ns, 'timeState', 'filters']);
  const title = getTitle(filter, days, t, translationKey);

  const formattedTitle = getFormattedTitle(title);

  const onExportClicked = async (): Promise<string> => {
    const timeZone = getUserTimeZone();
    const params: IDevicePerformanceEventsParams = {
      name,
      days,
      homeLocationId: filter.homeLocation?.value.id,
      group: filter.group?.value,
      zoneId: filter.zone?.value.id,
      timeZone
    };
    return exportChartData(params);
  };

  const timeZoneOffset = getTimeZoneOffset(getUserTimeZone());

  return (
    <div className='core-performance-chart-section'>
      <StackedColumnsDateChart<any>
        data={data.map((d) => ({
          count: d.count,
          date: parseInt(d.date)
        }))}
        export={{
          filename: `${formattedTitle}.${moment.utc().toISOString()}.csv`,
          onExportClicked: () => onExportClicked(),
          csv: true
        }}
        series={[{
          dataKey: 'count',
          description: t(translationKey as keyof ITranslationKeys['performance'], { ns: 'performance' }),
          visible: true,
          colour: colourCode,
        }]}
        title={title}
        days={props.days}
        formatTooltipDate={formatTooltipDay}
        cypressId={`corePerformance${upperFirst(name)}`}
        hasLegend={true}
        xAxisType={'date'}
        drawXAxis={(_, xAxis) => drawXAxisLabels(xAxis as am4charts.DateAxis, days, timeZoneOffset)}
        onMount={props.onMount}
        useAxisTooltip
        bottomBorder={true}
      />
    </div>
  );
}

import { Moment } from 'moment';
import React from 'react';

import DateTime, { DatetimepickerProps } from 'react-datetime';

import 'react-datetime/css/react-datetime.css';

// The datetime types accidentally omit some properties from the props
// see https://github.com/YouCanBookMe/react-datetime/issues/604
export interface ExtendedDatetimepickerProps extends Omit<DatetimepickerProps, 'value'> {
  value?: Date | string | Moment | number,
  renderInput?: (props: any, openCalendar: Function, closeCalendar: Function) => JSX.Element,
  closeOnTab?: boolean
}

const TypedDateTime = DateTime as React.ComponentType<ExtendedDatetimepickerProps>;

export function DatePicker(props: ExtendedDatetimepickerProps) {
  return (
    <TypedDateTime
      inputProps={{ readOnly: true }}
      utc={props.utc}
      dateFormat={props.dateFormat}
      timeFormat={props.timeFormat}
      className={props.className}
      initialValue={props.initialValue}
      isValidDate={props.isValidDate}
      onChange={props.onChange}
      value={props.value}
      renderInput={props.renderInput}
      closeOnSelect
    />
  );
}

import styled from "styled-components";
import { LinkButton } from "../../../components/controls/linkButton";
import { DetailsItem } from "../../../components/details/detailsItem";
import { MiniHeader } from "../../../components/details/miniHeader";
import { encode } from "../../../lib/base64";
import { IBatteryModel } from "./deviceDetailsInformation";
import { useWorldRoutes } from "../../../routes/parts/allWorldRoutes";
import moment from "moment";
import { useTranslation } from "@lib/useTypedTranslation";

import { TTypedTFunction } from "@lib/useTypedTranslation";
import { RoundedLabel } from "../../../components/labels/roundedLabel";

const ViewInBatteries = styled(LinkButton)`
  display: inline-block;
  margin-top: 6rem;
`;
ViewInBatteries.displayName = 'ViewInBatteries';

export const getStatusLabel = (index: number, data: any, t: TTypedTFunction) => {
  if (data.smartBatteries && data.smartBatteries[index]) {
    const batteryStatus = data.smartBatteries[index].batteryStatus ? data.smartBatteries[index].batteryStatus : undefined;
    const capacityFactor = data.smartBatteries[index].capacityFactor ? data.smartBatteries[index].capacityFactor : undefined;

    if (batteryStatus === "green") {
      return <RoundedLabel title={t('NO_PROBLEMS')} icon="fas fa-check" type="green" />;
    }

    if (batteryStatus === "yellow") {
      const title = capacityFactor ? "HEALTH_WARNING" : "CYCLE_COUNT_WARNING";
      return <RoundedLabel title={t(title)} icon="fas fa-exclamation-triangle" type="yellow" />;
    }

    if (batteryStatus === "red") {
      const title = capacityFactor ? "POOR_HEALTH" : "HIGH_CYCLE_COUNT";
      return <RoundedLabel title={t(title)} icon="fas fa-times-circle" type="red" />;
    }
  }
};

export const createBatteryModel = (index: number, data: any, t: TTypedTFunction): IBatteryModel => {
  const battery = data.smartBatteries ? data.smartBatteries[index] : undefined;
  return {
    id: battery?.id ?? undefined,
    batteryPartNumber: battery?.partNumber ?? undefined,
    batterySerialNumber: battery?.serialNumber ?? undefined,
    capacityFactor: battery?.capacityFactor ? `${Math.round(Number.parseFloat(battery.capacityFactor) * 100)}%` : undefined,
    cycleCount: battery ? battery.cycleCount : undefined,
    statusLabel: battery ? getStatusLabel(index, data, t) : undefined
  };
};

interface IProps {
  data: any,
  showViewInBatteries: boolean,
  areMultipleBatteries: boolean,
  index: number
}

export function LastKnownBatteryColumn(props: IProps) {
  const linkToBatteries = useWorldRoutes().batteryEssentials.battery;

  const ns = 'batteryEssentials';
  const ns2 = 'batteryEssentialsDeviceView';

  const { t } = useTranslation(['translation', ns, ns2]);

  const batteryModel = createBatteryModel(props.index, props.data, t);

  const batteryId = batteryModel?.id ?? '';
  const base64Id = encode(batteryId);

  return <div className="col-4 battery-information d-flex flex-column" key={batteryId} data-battery-index={props.index + ''}>
    <MiniHeader title={`${t('LAST_KNOWN_BATTERY', { ns: ns2 })}${props.areMultipleBatteries ? ' ' + (props.index + 1) : ''}`} icon="fas fa-battery-three-quarters" />

    <DetailsItem data={batteryModel} name={t('PART_NUMBER', { ns })} path='batteryPartNumber' />
    <DetailsItem data={batteryModel} name={t('SERIAL_NUMBER', { ns: 'translation' })} path='batterySerialNumber' />

    {batteryModel.capacityFactor ?
      <DetailsItem data={batteryModel} name={t('HEALTH', { ns })} path='capacityFactor' /> :
      <DetailsItem data={batteryModel} name={t('CYCLE_COUNT', { ns })} path='cycleCount' />
    }

    <DetailsItem data={batteryModel} name={t('STATUS', { ns: 'translation' })} path='statusLabel' />

    {!!props.showViewInBatteries && (
      <ViewInBatteries
        className='mt-auto align-self-center'
        dataId="view-in-batteries"
        to={`${linkToBatteries}?id=${encodeURIComponent(base64Id)}&ts=${moment.utc().valueOf()}`}
        external={false}
      >
        {t('VIEW_IN_BATTERIES', { ns: ns2 })}
      </ViewInBatteries>
    )}
  </div>;
}

import moment, { Moment } from 'moment-timezone';
import { get, snakeCase } from 'lodash';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import { DeviceEventCountName } from '../../../../services/core/eventsTypes';
import { Filter } from '../../../../components/timePeriodAndFilterPicker/useFilterPicker';
import { ITranslationKeys } from '@components/i18n/keys';

export function getEventsChartData(name: DeviceEventCountName) {
  return get({
    'deviceRebooted': {
      translationKey: 'REBOOTS',
      colourCode: '#4b6a88'
    },
    'deviceLowPower': {
      translationKey: 'LOW_POWER',
      colourCode: '#db7d1f'
    },
    'timeError': {
      translationKey: 'TIME_ERRORS',
      colourCode: '#e2756a'
    },
    'batteryChanged': {
      translationKey: 'BATTERY_SWAPS',
      colourCode: '#6989a9'
    },
    'deviceDropped': {
      translationKey: 'DROPS',
      colourCode: '#e86d84'
    }
  }, name);
}

export function getTitle(chartFilter: Filter, days: number, t: TTypedTFunction, name = 'DATA_USAGE', contextualTextApplied?: boolean) {
  let filter = chartFilter.group?.value || chartFilter.homeLocation?.value.name || chartFilter.zone?.value.name || t('ALL_DEVICES', { ns: 'filters' });
  if (chartFilter.group?.value === null) {
    filter = t('translation:NO_GROUP');
  } else if (chartFilter.homeLocation?.value.name === null) {
    filter = t('editRecord:NO_HOME_LOCATION');
  }
  const devicesFilter = ((chartFilter.group?.value || chartFilter.homeLocation?.value?.name) || (chartFilter.group?.value === null || chartFilter.homeLocation?.value.name === null || chartFilter.zone?.value.name)) && contextualTextApplied;
  const period = `${t('IN_THE_LAST_M_other', { ns: 'timeState' })} ${t('DAY_COUNT', { ns: 'timeState', count: days })}`.toLowerCase();
  return devicesFilter ?
    t(`PERFORMANCE_CHART_TITLE_${name}` as keyof ITranslationKeys['performance'], { ns: 'performance', filter, period, context: 'withFilter' })
    :
    t(`PERFORMANCE_CHART_TITLE_${name}` as keyof ITranslationKeys['performance'], { ns: 'performance', filter, period });
}

export function getFormattedTitle(title: string) {
  return snakeCase(title);
}

export const getUtcOffset = (date: Moment): number => {
  return date.isValid() ? date.utcOffset() : 0;
};

export const getTimeZoneOffset = (tz: string): number => {
  return moment.tz.names().includes(tz) ? getUtcOffset(moment.tz(tz)) : 0;
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import styled from 'styled-components';
import { isNull, isUndefined } from 'lodash';

import { Option, getHomeLocationWithDevicesOptionMapper, getHomeLocationWithDevicesSuggestionClasses } from '../../components/controls/optionPickerLib';
import { OptionPicker } from '../../components/controls/optionPicker';
import { getHomeLocationWithDevicesCount, IHomeLocationDevicesCount } from '../../services/bulkActions/homeLocationChange';
import { MoveHomeLocationModal } from './moveHomeLocationModal';
import { Button } from '../../components/controls/button';
import { ButtonColours } from '../app/themes';
import { useLanguageAndLocaleContext } from '../../context/languageAndLocale';
import { useWorldRequest } from '../../lib/useWorldRequest';

import './homeLocationChange.css';

const ns = 'bulkActions' as const;
const ns2 = 'translation' as const;

export function HomeLocationChange() {
  const { t } = useTranslation([ns, ns2]);
  const { locale } = useLanguageAndLocaleContext();

  const [homeLocationOptions, setHomeLocationOptions] = useState<Option<IHomeLocationDevicesCount>[]>([]);
  const [fromHomeLocationSelection, setFromHomeLocationSelection] = useState<IHomeLocationDevicesCount>(undefined);
  const [toHomeLocationSelection, setToHomeLocationSelection] = useState<IHomeLocationDevicesCount>(undefined);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [validationText, setValidationText] = useState<string>('');
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);

  const mapHomeLocationWithDevicesToOptions = useMemo(() => getHomeLocationWithDevicesOptionMapper(t, locale), [t, locale]);

  const onHomeLocationsFetched = useCallback((homeLocations: IHomeLocationDevicesCount[]) => {
    setHomeLocationOptions(mapHomeLocationWithDevicesToOptions(homeLocations));
  }, [mapHomeLocationWithDevicesToOptions]);
  const fetchHomeLocations = useCallback(() => { if (!showMoveModal) { return getHomeLocationWithDevicesCount(); } }, [showMoveModal]);
  const { loading } = useWorldRequest(fetchHomeLocations, { initialLoading: true, onSuccess: onHomeLocationsFetched });

  useEffect(() => {
    if (!showMoveModal) {
      setFromHomeLocationSelection(undefined);
      setToHomeLocationSelection(undefined);
    }
  }, [showMoveModal]);

  const onFromHomeLocationSelect = (selected: Option<IHomeLocationDevicesCount>): void => {
    setFromHomeLocationSelection(selected.value);
  };

  const onToHomeLocationSelect = (selected: Option<IHomeLocationDevicesCount>): void => {
    setToHomeLocationSelection(selected.value);
  };

  const getCurrentHomeLocationOption = (selection: IHomeLocationDevicesCount) => {
    return mapHomeLocationWithDevicesToOptions([selection])[0];
  };

  const fromHomeLocationOptions: Option<IHomeLocationDevicesCount>[] = homeLocationOptions.filter((item) => item.value.devicesCount !== 0);

  useEffect(() => {
    if (isUndefined(fromHomeLocationSelection) || isUndefined(toHomeLocationSelection)) {
      setButtonEnabled(false);
      setValidationText('');
    } else if ((isNull(fromHomeLocationSelection.homeLocation) && isNull(toHomeLocationSelection.homeLocation)) || (fromHomeLocationSelection.homeLocation?.id === toHomeLocationSelection.homeLocation?.id)) {
      setValidationText(t('MOVE_VALIDATION_ERR_TEXT', { ns }));
      setButtonEnabled(false);
    } else {
      setValidationText('');
      setButtonEnabled(true);
    }
  }, [fromHomeLocationSelection, toHomeLocationSelection, t]);

  const toHomeLocationClass = (validationText === '') ? '' : 'wrong-home-location-selection';

  return <div className='home-location-change_container'>
    <div className='home-location-change_header'>{t('HOME_LOCATION_CHANGE', { ns: 'bulkActions' })}</div>
    <div className='home-location-change_selection'>
      {t('MOVE_DEVICES_TEXT', { ns })}
      <div className="from-home-location_selector">
        <OptionPicker<IHomeLocationDevicesCount>
          options={fromHomeLocationOptions}
          current={getCurrentHomeLocationOption(fromHomeLocationSelection)}
          onSelect={onFromHomeLocationSelect}
          disabled={loading}
          getSuggestionClasses={getHomeLocationWithDevicesSuggestionClasses}
        />
      </div>
      {t('TO', { ns: ns2 })}
      <div className={`to-home-location_selector ${toHomeLocationClass}`}>
        <OptionPicker<IHomeLocationDevicesCount>
          options={homeLocationOptions}
          current={getCurrentHomeLocationOption(toHomeLocationSelection)}
          onSelect={onToHomeLocationSelect}
          disabled={loading}
          getSuggestionClasses={getHomeLocationWithDevicesSuggestionClasses}
        />
      </div>
      <MoveButton
        colour={ButtonColours.grey}
        className={'move_button'}
        text={t('MOVE', { ns })}
        onClick={() => setShowMoveModal(true)}
        disabled={!buttonEnabled}
      />
      {showMoveModal && <MoveHomeLocationModal showPopup={showMoveModal} from={fromHomeLocationSelection} to={toHomeLocationSelection} handleClose={() => setShowMoveModal(false)} />}
      {(!buttonEnabled && validationText) && <span className='selection-validation-error'>{validationText}</span>}
    </div>
  </div>;
}

const MoveButton = styled(Button)`
  align-self: center;
  white-space: nowrap;
  margin-top: 5px;
`;
MoveButton.displayName = 'Button';

import { isString, upperCase } from 'lodash';

import {
  FilterOption,
  IFilterOption,
  IFilterResult,
  FilterGroup,
  GroupFilterSettings,
  GroupFilterSort
} from './filterLib';
import { decode } from '../../lib/base64';
import { ALERT } from '../alertsPanel/alertMessages';
import { isAlertEnabled } from '../../services/config/config';
import { WorldSettings } from '../../services/config/config';
import { AlertStatus, alertStatuses } from '../../services/config/alertConstants';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import { ITranslationKeys } from 'components/i18n/keys';

export const devicesFilterClasses: GroupFilterSettings = {
  groups: {
    'no-group': 'filter-box--italic'
  },
  homeLocations: {
    'no-homelocation': 'filter-box--italic'
  }
};

export const devicesFilterIcons: GroupFilterSettings = {
  status: {
    red: 'fa fa-times-circle circle_red',
    yellow: 'fa fa-exclamation-triangle circle_orange enhanced-warning-icon',
    green: 'fa fa-check circle_green'
  }
};

export const devicesFilterSort: GroupFilterSort = {
  status: (filter: IFilterOption) => statusFilterValues.findIndex((s) => !isString(filter) && s.id === filter.id),
  alertStatus: (filter: IFilterOption) => alertStatusFilters.findIndex((alert) => !isString(filter) && filter.id.includes(`-${alert}`))
};

export const statusFilterValues: FilterOption[] = [
  { id: ALERT.red, value: 'PROBLEM' },
  { id: ALERT.yellow, value: 'WARNING' },
  { id: ALERT.green, value: 'GOOD' }
];

export const nullGroupFilter = { id: 'no-group', value: 'NO_GROUP_title', nullFilter: true };
export const nullHomeLocationFilter = { id: 'no-homelocation', value: 'NO_HOME_LOCATION', nullFilter: true };
export const groupFilterValues: FilterOption[] = [nullGroupFilter];
export const homeLocationFilterValues: FilterOption[] = [nullHomeLocationFilter];

export const alertStatusFilters = [ALERT.red, ALERT.yellow, ALERT.green]; // NB order here determines order in columns
// overrides for alerts that do not have all three alert levels:
export const alertStatusFilterOverrides: Partial<{ [alert in AlertStatus]: ALERT[]}> = {
  deviceTimeErrorStatus: [ALERT.red, ALERT.green]
};

export type AlertStatusFilterId = `${AlertStatus}-${ALERT}`;

export const getAlertStatusFilterId = (status: AlertStatus, alert: ALERT): AlertStatusFilterId => `${status}-${alert}` as AlertStatusFilterId;

export const getAlertStatusFromAlertStatusFilterId = (id: AlertStatusFilterId): AlertStatus => id.split('-')[0] as AlertStatus;

export const isAlertStatusFilterId = (id: string) : id is AlertStatusFilterId => {
  const separatedId = id.split('-');
  if (separatedId.length !== 2) {
    return false;
  }
  const [alertStatus, alert] = separatedId;
  return alertStatuses.includes(alertStatus as AlertStatus) && alertStatusFilters.includes(alert as ALERT);
};

interface UntranslatedAlertStatusFilterOption extends Omit<FilterOption, 'statusValue'> {
  statusValue: (t: TTypedTFunction) => string,
  id: AlertStatusFilterId
}

export const alertStatusFilterValues: UntranslatedAlertStatusFilterOption[] = alertStatuses.map((status) => {
  return (alertStatusFilterOverrides[status] || alertStatusFilters).map((alert) => {
    const alertKey = upperCase(`${status}_${alert}`).split(' ').join('_');
    const filterKey = statusFilterValues.find((s) => s.id === alert).value;
    return {
      id: getAlertStatusFilterId(status, alert),
      value: alertKey,
      statusValue: (t: TTypedTFunction) => `${t(alertKey as keyof ITranslationKeys['filters'], { ns: 'filters' })} (${t(filterKey as keyof ITranslationKeys['translation'], { ns: 'translation' })})`,
      additionalCols: {
        alertLevel: <i className={devicesFilterIcons.status[alert]} />
      }
    };
  });
}).flat();

export const getTranslatedAlertStatusFilterValues = (t: TTypedTFunction, worldSettings: WorldSettings): FilterOption[] => {
  return alertStatusFilterValues.filter(status => isAlertEnabled(getAlertStatusFromAlertStatusFilterId(status.id), worldSettings)).map(val => ({
    ...val,
    value: t(val.value as keyof ITranslationKeys['filters'], { ns: 'filters' }),
    statusValue: val.statusValue(t)
  }));
};

export const alertStatusFilterGroups: FilterGroup[] = alertStatuses.map((status) => {
  const groupKey = upperCase(`${status}_GROUP`).split(' ').join('_');
  const filterIds = (alertStatusFilterOverrides[status] || alertStatusFilters).map((alert) => {
    return getAlertStatusFilterId(status, alert);
  });
  return {
    id: status,
    name: groupKey,
    filterIds
  };
}).flat();

/* some filters are hidden from being edited, such as homeLocation when a user has a home location id set,
  here we can pass unapplicableFilters to avoid applying filters that the user should not have the ability to apply */
export function convertQueryStringToFilter(queryString: string, t: TTypedTFunction, worldSettings: WorldSettings, unapplicableFilters: string[] = []): IFilterResult {
  const params = new URLSearchParams(queryString);
  const filter: IFilterResult = {};

  params.forEach((value, key) => {
    if (!unapplicableFilters.includes(key)) {
      if (key === 'homeLocations') {
        const homeLocations = value.split(',');
        filter[key] = homeLocations.map(item => {
          if (item === '\0') {
            return { ...nullHomeLocationFilter, value: t(nullHomeLocationFilter.value) };
          }
          const [id, value] = item.split('|').map((item) => decode(item));
          return { id, value };
        });
      } else if (key === 'groups') {
        filter[key] = value.split(',').map((group) => {
          return group === '\0' ? { ...nullGroupFilter, value: t(nullGroupFilter.value) } : group;
        });
      } else if (key === 'status') {
        filter[key] = value.split(',').map(id => {
          const status = statusFilterValues.find(value => value.id === id);
          return status && { ...status, value: t(status.value) };
        }).filter(f => f);
      } else if (key === 'alertStatus') {
        filter[key] = value.split(',').map(id => {
          if (isAlertStatusFilterId(id)) {
            const alertStatusEnabled = isAlertEnabled(getAlertStatusFromAlertStatusFilterId(id), worldSettings);
            const alertStatusFilter = alertStatusFilterValues.find(value => value.id === id);
            return alertStatusFilter && alertStatusEnabled && {
              ...alertStatusFilter,
              value: t(alertStatusFilter.value as keyof ITranslationKeys['filters'], { ns: 'filters' }),
              statusValue: !isString(alertStatusFilter.statusValue) && alertStatusFilter.statusValue(t)
            };
          }
          return undefined;
        }).filter(f => f);
      } else {
        filter[key] = value.split(',');
      }
    }
  });
  return filter;
}

import * as React from 'react';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { get } from 'lodash';

import { ModalPopup } from '../../../../components/controls/modalPopup';
import { FilterSelection, IFilterGroup } from '../../../../components/filters/filterSelection';
import { getDeviceListCountAction } from '../../../../services/core/devices';
import { getHomeLocations, getGroups, getModels, getManufacturers, IGroups, IHomeLocation, IGroup, isAlertEnabled } from '../../../../services/config/config';
import { Loading } from '../../../../components/loading/loading';
import { IFilterResult, convertFilterToQuery } from '../../../../components/filters/filterLib';
import {
  statusFilterValues,
  groupFilterValues,
  homeLocationFilterValues,
  getTranslatedAlertStatusFilterValues,
  alertStatusFilterGroups,
  devicesFilterClasses,
  devicesFilterIcons,
  devicesFilterSort
} from '../../../../components/filters/devicesFilterLib';
import { omitShallow } from '../../../../lib/omitShallow';
import { FormActionButtons, ProgressiveActionType } from '../../../../components/controls/formActionButtons';
import { useWorldAction } from '../../../../lib/useWorldAction';
import { RequestInitWithRetry } from '../../../../lib/request';
import { useWorldRequest } from '../../../../lib/useWorldRequest';
import { useWorldSettingsContext } from '../../../../context/worldSettings';
import { AlertStatus } from '../../../../services/config/alertConstants';

import './devices-filter.css';
import { FormControls } from '../../../../components/forms/formControls';
import { ITranslationKeys } from 'components/i18n/keys';

export const deviceListFilters = ['manufacturers', 'models', 'groups', 'homeLocations', 'status', 'alertStatus'] as const;
export type DevicesListFilter = typeof deviceListFilters[number];

export interface IProps {
  showPopup: boolean,
  hiddenFilters: DevicesListFilter[],
  initialFilters: IFilterResult,
  handleFiltersUpdated: (filters: IFilterResult) => void,
  handleCancel: () => void
}

interface ModalFooterProps {
  handleFiltersUpdated: (filters: IFilterResult) => void,
  handleCancel: () => void,
  selectedFilter: IFilterResult
}

function ModalFooter(props: ModalFooterProps) {
  return <FormControls
    onCancel={props.handleCancel}
    onSubmit={() => props.handleFiltersUpdated(props.selectedFilter)}
    mode='apply'
  />;
}

function getInitialAvailableFilters(hiddenFilters: DevicesListFilter[]): IFilterResult {
  const initialAvailableFilters: IFilterResult = {
    manufacturers: [],
    models: [],
    groups: [],
    homeLocations: [],
    status: [],
    alertStatus: []
  };

  return omitShallow(initialAvailableFilters, ...hiddenFilters);
}

export function DevicesFilter(props: IProps) {
  const {
    showPopup,
    initialFilters,
    handleFiltersUpdated,
    handleCancel,
    hiddenFilters
  } = props;

  const { t } = useTranslation(['translation', 'filters']);
  const [selectedFilter, setSelectedFilter] = useState<IFilterResult>({});
  const [{ availableFilters, filtersLoaded }, setAvailableFilters] = useState({
    availableFilters: getInitialAvailableFilters(hiddenFilters),
    filtersLoaded: false
  });
  const { worldSettings } = useWorldSettingsContext();
  const getDeviceListCount = useWorldAction(getDeviceListCountAction);
  //update selection if new initialFilters provided
  useEffect(() => {
    setSelectedFilter(initialFilters);
  }, [initialFilters]);

  const homeLocationFilterVisible = useMemo(() => {
    return !hiddenFilters.includes('homeLocations');
  }, [hiddenFilters]);

  const translatedAlertStatusValues = useMemo(() => {
    return getTranslatedAlertStatusFilterValues(t, worldSettings);
  }, [worldSettings, t]);

  const countUpdateFunction = useCallback(async (filters: IFilterResult) => {
    if (!filtersLoaded || !Object.values(filters).some(filter => filter.length)) { return 0; }
    const filtersMap = convertFilterToQuery(filters);
    const response = await getDeviceListCount(filtersMap);
    return response.count;
  }, [filtersLoaded, getDeviceListCount]);

  const onFiltersLoaded = useCallback((data: [IGroups, string[], string[], IHomeLocation[]]) => {
    const translatedStatusValues = statusFilterValues.map(val => ({ ...val, value: t(val.value as keyof ITranslationKeys['translation'], { ns: 'translation' }) }));
    const translatedGroupFilterValues = groupFilterValues.map(val => ({ ...val, value: t(val.value as keyof ITranslationKeys['translation'], { ns: 'translation' }) }));
    const translatedHomeLocationFilterValues = homeLocationFilterValues.map(val => ({ ...val, value: t(val.value as keyof ITranslationKeys['translation'], { ns: 'translation' }) }));
    const groupsRaw = data[0];
    const models = data[1];
    const manufacturers = data[2];

    const groups = [
      ...get(groupsRaw, 'groups.nodes', []).map((o: IGroup) => o.name),
      ...translatedGroupFilterValues
    ];

    const availableFilters: IFilterResult = {
      groups,
      models,
      manufacturers,
      status: translatedStatusValues,
      alertStatus: translatedAlertStatusValues
    };

    if (homeLocationFilterVisible) {
      const homeLocationsRaw = data[3];
      const homeLocations = homeLocationsRaw.map((o) => ({ id: o.id, value: o.name }));

      availableFilters.homeLocations = [...homeLocations, ...translatedHomeLocationFilterValues];
    }
    setAvailableFilters({ availableFilters, filtersLoaded: true });
  }, [homeLocationFilterVisible, t, translatedAlertStatusValues]);

  const filtersDataFetcher = useCallback(() => {
    if (filtersLoaded) { return; }
    return (options: RequestInitWithRetry) => {
      return Promise.all([
        getGroups()(options),
        getModels()(options),
        getManufacturers()(options),
        homeLocationFilterVisible && getHomeLocations()(options)
      ]);
    };
  }, [filtersLoaded, homeLocationFilterVisible]);

  useWorldRequest(filtersDataFetcher, { initialData: [undefined, [], [], []], onSuccess: onFiltersLoaded });

  const filterGroupsTabOne: IFilterGroup[] = [
    { id: 'manufacturers', filterDisplayName: 'MANUFACTURER', searchable: true },
    { id: 'models', filterDisplayName: 'MODEL', searchable: true },
    { id: 'groups', filterDisplayName: 'GROUP', searchable: true },
    { id: 'homeLocations', filterDisplayName: 'HOME_LOCATION', searchable: true },
    { id: 'status', filterDisplayName: 'STATUS', searchable: false }
  ].map(group => ({ ...group, filterDisplayName: t(group.filterDisplayName as keyof ITranslationKeys['translation'], { ns: 'translation' }) }))
    .filter(group => !hiddenFilters.includes(group.id as DevicesListFilter));

  const filterGroupsTabTwo: IFilterGroup[] = [
    {
      id: 'alertStatus',
      filterDisplayName: t('ALERT_TYPES_FILTER', { ns: 'filters' }),
      additionalCols: [{ id: 'alertLevel', name: t('ALERT_LEVEL_COL', { ns: 'filters' }) }],
      groups: alertStatusFilterGroups.filter(status => isAlertEnabled(status.id as AlertStatus, worldSettings)).map(group => ({ ...group, name: t(group.name as keyof ITranslationKeys['filters'], { ns: 'filters' }) }))
    }
  ];

  const tabs = [
    { id: 'devicesFilterTab1', name: t('FILTER_MODAL_TAB_1', { ns: 'translation' }), filterGroups: filterGroupsTabOne },
    { id: 'devicesFilterTab2', name: t('FILTER_MODAL_TAB_2', { ns: 'translation' }), filterGroups: filterGroupsTabTwo }
  ];

  function handleClose() {
    setSelectedFilter(initialFilters);
    handleCancel();
  }

  return (
    <ModalPopup
      show={showPopup}
      handleClose={handleClose}
      header={t('FILTER_MODAL_HEADER', { ns: 'translation' })}
      subheader={t('FILTER_MODAL_SUBHEADER', { ns: 'translation' })}
      classname="core-devices_list_filters"
      footer={<ModalFooter
        handleFiltersUpdated={handleFiltersUpdated}
        handleCancel={handleClose}
        selectedFilter={selectedFilter}
      />}
    >
      <Loading
        isLoading={!filtersLoaded}
        transparentOverlay={false}>
        <FilterSelection
          countMessage={t('FILTER_COUNT_MESSAGE', { ns: 'translation' })}
          countUpdateFunction={countUpdateFunction}
          filterIcons={devicesFilterIcons}
          filterClasses={devicesFilterClasses}
          filterSort={devicesFilterSort}
          currentFilter={selectedFilter}
          availableFilters={availableFilters}
          onFilterChange={(result) => setSelectedFilter(result)}
          tabs={tabs}
        />
      </Loading>
    </ModalPopup>
  );
}

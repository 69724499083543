import React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { ModalPopup } from '../../../components/controls/modalPopup';
import { Button, Variants } from '../../../components/controls/button';
import { ButtonColours } from '../../app/themes';

import './actionDenied.css';

interface ModalFooterProps {
  handleClose: () => void
}

function ModalFooter(props: ModalFooterProps) {
  const { t } = useTranslation('userManagement');
  const { handleClose } = props;

  return <div className="action-denied-users-list__footer">
    <Button
      colour={ButtonColours.grey}
      dataId="action-denied-return-to-list-button"
      text={t('RETURN_TO_LIST')}
      onClick={handleClose}
      variant={Variants.ghost}
      ariaLabel="action denied close button"
    />
  </div>;
}

export interface IProps {
  header: string,
  subheader: string,
  showPopup: boolean,
  handleClose: () => void
}

export function ActionDenied(props: IProps) {
  const { header, subheader, showPopup, handleClose } = props;

  return (
    <div className="action-denied_popup">
      <ModalPopup
        show={showPopup}
        handleClose={handleClose}
        header={header}
        subheader={subheader}
        classname={`action-denied-users-list`}
        closeDisabled={false}
        footer={<ModalFooter
          handleClose={handleClose}
        />}>
      </ModalPopup>
    </div>
  );
}

import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '@lib/useTypedTranslation';
import { map } from 'lodash';
import styled from 'styled-components';

import { IListCategory } from './list';
import { Search } from '../controls/search';
import { ListCategory } from '../../containers/battery-centric';
import { ButtonColours } from '../../containers/app/themes';
import { Button } from '../controls/button';
import { ExportButton } from '../controls/exportButton';

import './style.css';

const HeaderWithControls = styled.div`
  display: flex;
  background-color: #fff;
  padding: 1rem 2.5rem 1.125rem 2.5rem;
  align-items: center;
  outline: none;
  button[data-id='download-button'] {
    order: 3;
  }
  span[data-id='essentials-header-search_container'] {
    order: 1;
  }
  .filter-switch {
    order: 2;
  }
  .essentials-delete-button {
    order: 4;
  }
`;

const HeaderTitle = styled.div`
  color: #333;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  margin-right: auto;
`;

interface IHeaderProps {
  categories: IListCategory[] | ListCategory[],
  currentCategoryId: string,
  onFilterClicked?: () => void,
  onExportClicked?: () => Promise<string>,
  onSearchClicked?: (i: string) => void,
  headerTitle?: string,
  deleteButtonProps?: {
    enabled: boolean,
    checkedRowIds: string[],
    onButtonClick: () => void
  },
  exportButtonDisabled?: boolean
}

const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {
  const { t } = useTranslation();
  const categories = map(props.categories, (category) => {
    return (
      <Link id={`ebt_${category.id}`} key={`category_header_${category.id}`} to={category.link}>
        <li className={category.id === props.currentCategoryId ? 'active' : 'faded'}>
          <span className={category.icon} /><span className="tabText">{category.title}</span>
        </li>
      </Link>);
  });

  const controls: JSX.Element[] = [];

  if (props.onSearchClicked) {
    controls.push(<Search
      dataId={'essentials-header'}
      key="header-control-search-form"
      onClicked={props.onSearchClicked}
    />);
  }

  if (props.onExportClicked) {
    controls.push(<ExportButton
      key="header-control-export-button"
      exportFunction={props.onExportClicked}
      disabled={props?.exportButtonDisabled}
      filename="export.csv" />);
  }

  if (props.onFilterClicked) {
    controls.push(<Button
      key="header-control-filter-btn"
      className="panel-button filter-switch"
      onClick={props.onFilterClicked}
      text={t('FILTER')}
      iconBeforeText={true}
      iconStyle="fas fa-sliders-h"
      colour={ButtonColours.grey} />);
  }

  if (props.deleteButtonProps) {
    controls.push(<Button
      key="header-control-delete-btn"
      className={'panel-button essentials-delete-button'}
      disabled={!props.deleteButtonProps.enabled}
      onClick={props.deleteButtonProps.onButtonClick}
      text={t('DELETE', { ns: 'translation' })}
      iconBeforeText={true}
      iconStyle="fa fa-trash-alt"
      colour={ButtonColours.grey} />);
  }

  return (
    <div>
      <div className='header-container'>
        <ul className="tabs">
          {categories}
        </ul>
      </div>
      <HeaderWithControls className="header-title-and-controls">
        <HeaderTitle className="title-header">{props.headerTitle}</HeaderTitle>
        {controls}
      </HeaderWithControls>
    </div>);
};

export default Header;

import { isEqual, isUndefined } from 'lodash';
import { useMemo, useState } from 'react';
import {
  Option,
  Group,
  getGroupOptionMapper,
  getHomeLocationOptionMapper,
  getZoneOptionMapper,
  Zone
} from '../controls/optionPickerLib';
import { RequestInitWithRetry } from '../../lib/request';
import { useWorldRequest } from '../../lib/useWorldRequest';
import { getGroups, getHomeLocations, getZones, IGroups, IHomeLocation, IZone } from '../../services/config/config';
import { nullHomeLocation } from '../controls/optionPickerLib';
import { useCurrentUserContext } from '../../context/currentUser';
import { useTranslation } from '@lib/useTypedTranslation';

export interface Filter {
  homeLocation: Option<IHomeLocation>,
  group: Option<Group>,
  zone: Option<Zone>
}

export const useFilterPicker = () => {
  const { t } = useTranslation(['editRecord', 'filters']);
  const user = useCurrentUserContext();

  const [homeLocationOptions, setHomeLocationOptions] = useState<Option<IHomeLocation>[]>([]);
  const [groupOptions, setGroupOptions] = useState<Option<Group>[]>([]);
  const [zoneOptions, setZoneOptions] = useState<Option<Zone>[]>([]);
  const [filter, setFilter] = useState<Filter>({ group: undefined, homeLocation: undefined, zone: undefined });
  const updateFilter = (updated: Filter) => {
    if (isEqual(updated, filter)) { return; }
    setFilter(updated);
  };

  const handleFilterReset = () => {
    updateFilter({
      homeLocation: undefined,
      group: undefined,
      zone: undefined
    });
  };

  const handleZoneSelect = (selected: Option<Zone>) => {
    updateFilter({
      homeLocation: undefined,
      group: undefined,
      zone: selected
    });
  };

  const handleHomeLocationSelect = (selected: Option<IHomeLocation>) => {
    updateFilter({
      homeLocation: selected,
      group: undefined,
      zone: undefined
    });
  };

  const handleGroupSelect = (selected: Option<Group>) => {
    updateFilter({
      homeLocation: undefined,
      group: selected,
      zone: undefined
    });
  };

  const mapGroupsToOptions = useMemo(() => getGroupOptionMapper(t), [t]);
  const mapHomeLocationsToOptions = useMemo(() => getHomeLocationOptionMapper(t), [t]);
  const mapZonesToOptions = useMemo(() => getZoneOptionMapper(t), [t]);
  const zonePickerVisible = !user.homeLocationId && !user.group && !user.zoneId;
  const [optionsDataFetcher, onOptionsSuccess] = useMemo(() => {
    return [
      () => (options: RequestInitWithRetry) => Promise.all([
        zonePickerVisible ? getZones()(options) : [],
        getGroups()(options),
        user.homeLocationId ? [] : getHomeLocations()(options)
      ]),
      ([zones, groups, homeLocations]: [IZone[], IGroups, IHomeLocation[]]) => {
        const availableHomeLocations: IHomeLocation[] = homeLocations?.length ? [nullHomeLocation, ...homeLocations] : [];
        setHomeLocationOptions(mapHomeLocationsToOptions(availableHomeLocations));
        const groupNames = (groups?.groups?.nodes || []).map(g => g.name.trim().toLowerCase()); // groups should be trimmed and lowercase, but we'll double check here
        const availableGroups = groupNames.length ? [null, ...groupNames] : [];
        setGroupOptions(mapGroupsToOptions(availableGroups));
        const availableZones: Zone[] = zones?.length ? zones.map(({ id, name }) => ({ id, name })) : [];
        setZoneOptions(mapZonesToOptions(availableZones));
      }
    ];
  }, [zonePickerVisible, user.homeLocationId, mapGroupsToOptions, mapHomeLocationsToOptions, mapZonesToOptions]);

  const {
    loading: optionsLoading,
  } = useWorldRequest(optionsDataFetcher, { onSuccess: onOptionsSuccess, initialLoading: true });

  const allFilterActive = isUndefined(filter.group) && isUndefined(filter.homeLocation) && isUndefined(filter.zone);
  const groupActive = !isUndefined(filter.group);
  const homeLocationActive = !isUndefined(filter.homeLocation);
  const zoneActive = !isUndefined(filter.zone);
  const homeLocationPickerVisible = !user.homeLocationId;

  return {
    filter,
    optionsLoading,
    homeLocationOptions,
    groupOptions,
    zoneOptions,
    allFilterActive,
    groupActive,
    homeLocationActive,
    zoneActive,
    handleFilterReset,
    handleHomeLocationSelect,
    handleGroupSelect,
    handleZoneSelect,
    homeLocationPickerVisible,
    zonePickerVisible
  };
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { frTranslations } from './components/i18n/french-fr';
import { ukTranslations } from './components/i18n/english-uk';
import { usTranslations } from './components/i18n/english-us';
import { caTranslations } from './components/i18n/french-ca';
import { esTranslations } from './components/i18n/spanish-es';
import { getFormat } from './components/i18n/formatters';
import { ITranslationKeys } from './components/i18n/keys';

// ...

// oh boy don't do this
type UnionToIntersection<U> =
  (U extends any ? (k: U) => void : never) extends ((k: infer I) => void) ? I : never;
type LastOf<T> =
  UnionToIntersection<T extends any ? () => T : never> extends () => (infer R) ? R : never;

// TS4.0+
type Push<T extends any[], V> = [...T, V];

// TS4.1+
type TuplifyUnion<T, L = LastOf<T>, N = [T] extends [never] ? true : false> =
  true extends N ? [] : Push<TuplifyUnion<Exclude<T, L>>, L>;

// ...

type StringKeysNS = Extract<keyof ITranslationKeys, string>;

type NsArray = TuplifyUnion<StringKeysNS>;

export const resources = {
  en: ukTranslations,
  fr: frTranslations,
  'en-US': usTranslations,
  'fr-CA': caTranslations,
  es: esTranslations
} as const;

const ns = Object.keys(resources.en) as NsArray;
export const defaultNS = 'translation' as const;

export const initI18n = (i18nInstance: typeof i18n) => {
  return i18nInstance
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      interpolation: {
        format: getFormat(resources),
        escapeValue: false, // not needed for react as it escapes by default
      },
      resources,

      ns,
      defaultNS,

      lng: 'en',
      fallbackLng: 'en',

      returnObjects: true
    });
};

export const i18nInit = initI18n(i18n);

export default i18n;

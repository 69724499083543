import * as React from "react";
import * as GeoTile from "geotile";

import { LatLng, latLng, LatLngBounds, latLngBounds, Map as LeafletMap } from "leaflet";
import { UseRequestReturn } from "@lib/useRequest";
import { GeoBoundsResponse } from "services/core/maps";
import { MapProps, Map } from "react-leaflet";
import { useLongPress } from "@lib/useLongPress";

type MapType = 'intensity' | 'cluster';

export const MIN_LATITUDE = -85.05112877980659;
export const MAX_LATITUDE = 85.05112877980659;

export const MIN_LONGITUDE = -180;
export const MAX_LONGITUDE = 179.9999999999999;

/** a factor multiplied by the original bounds to determine the additional padded area */
const BOUNDS_FACTOR_PADDING = 0.25;

export const getGeotilePrecisionFromMapZoom = (type: MapType, zoom: number): number => {
  const offset = type === 'intensity' ? 2 : 3;

  return Math.floor(zoom || 0) + offset;
};

const ZOOM_TIMEOUT_MS = 50;

export const useZoom = (mapRef: React.MutableRefObject<Map<MapProps, LeafletMap>>, zoomIncrement: number) => {
  const smoothZoomIn = useLongPress(() => {
    mapRef.current?.leafletElement.zoomIn(zoomIncrement);
  }, ZOOM_TIMEOUT_MS);

  const smoothZoomOut = useLongPress(() => {
    mapRef.current?.leafletElement.zoomOut(zoomIncrement);
  }, ZOOM_TIMEOUT_MS);

  const zoomInDisabled = mapRef.current?.leafletElement?.getZoom() === mapRef.current?.leafletElement?.getMaxZoom();
  const zoomOutDisabled = mapRef.current?.leafletElement?.getZoom() === mapRef.current?.leafletElement?.getMinZoom();

  return {
    smoothZoomIn,
    smoothZoomOut,
    zoomInDisabled,
    zoomOutDisabled
  };
};


// tile padding of 2 for initial bounds request, 4 for cluster data
export const getPaddedTileBounds = (sw: LatLng, ne: LatLng, precision: number, tilePadding: number) => {
  const swTileId = GeoTile.tileIdFromLatLong(Math.max(sw.lat, MIN_LATITUDE), Math.max(sw.lng, MIN_LONGITUDE), precision);
  const neTileId = GeoTile.tileIdFromLatLong(Math.min(ne.lat, MAX_LATITUDE), Math.min(ne.lng, MAX_LONGITUDE), precision);

  const maxTile = Math.pow(2, precision) - 1;

  const [swPrecision, swLat, swLng] = swTileId.split('_');
  const [nePrecision, neLat, neLng] = neTileId.split('_');

  // request full data for partially visible clusters
  const south = Math.min(+swLat + tilePadding, maxTile);
  const west = Math.max(+swLng - tilePadding, 0);
  const north = Math.max(+neLat - tilePadding, 0);
  const east = Math.min(+neLng + tilePadding, maxTile);

  const swTile = GeoTile.tileFromTileId(`${swPrecision}_${south}_${west}`);
  const neTile = GeoTile.tileFromTileId(`${nePrecision}_${north}_${east}`);

  return latLngBounds(latLng(swTile.latitudeSouth, swTile.longitudeWest), latLng(neTile.latitudeNorth, neTile.longitudeEast));
};


/** expand given map bounds to include full geotiles necessary for accurately requesting data to display on the map */
export const getDataRequestBounds = (mapType: MapType, sw: LatLng, ne: LatLng, precision: number) => {
  if (mapType === 'cluster') {
    return getPaddedTileBounds(sw, ne, precision, 4);
  } else {
    // request full data for partially visible tiles
    const swTile = GeoTile.tileFromTileId(GeoTile.tileIdFromLatLong(Math.max(sw.lat, MIN_LATITUDE), Math.max(sw.lng, MIN_LONGITUDE), precision));
    const neTile = GeoTile.tileFromTileId(GeoTile.tileIdFromLatLong(Math.min(ne.lat, MAX_LATITUDE), Math.min(ne.lng, MAX_LONGITUDE), precision));

    return latLngBounds(latLng(swTile.latitudeSouth, swTile.longitudeWest), latLng(neTile.latitudeNorth, neTile.longitudeEast));
  }
};

export const useInitialBoundsSet = (
  mapType: MapType,
  initialBoundsRequest: UseRequestReturn<GeoBoundsResponse>,
  mapRef: React.MutableRefObject<Map<MapProps, LeafletMap>>,
): {
  resetInitialBounds: () => void,
  mapTilesShown: boolean
} => {
  const [initialBoundsMinZoom, setInitialBoundsMinZoom] = React.useState(null);

  const setInitialBounds = React.useCallback(() => {
    if (initialBoundsRequest.data) {
      mapRef.current.leafletElement.setMinZoom(0);

      const { geoBounds: { bottom, left, top, right } } = initialBoundsRequest.data;

      const apiBoundsSw = latLng(Math.max(bottom, MIN_LATITUDE), Math.max(left, MIN_LONGITUDE));
      const apiBoundsNe = latLng(Math.min(top, MAX_LATITUDE), Math.min(right, MAX_LONGITUDE));

      const apiBounds = latLngBounds(apiBoundsSw, apiBoundsNe);
      const apiBoundsZoom = mapRef.current.leafletElement.getBoundsZoom(apiBounds);

      const currentPrecision = getGeotilePrecisionFromMapZoom(mapType, apiBoundsZoom);
      const clientBounds = mapType === 'cluster' ?
        apiBounds
        :
        getDataRequestBounds(mapType, apiBoundsSw, apiBoundsNe, currentPrecision);

      const clientBoundsZoom = mapRef.current.leafletElement.getBoundsZoom(clientBounds);
      const clientPrecision = getGeotilePrecisionFromMapZoom(mapType, clientBoundsZoom);
      const paddedBounds = getPaddedTileBounds(clientBounds.getSouthWest(), clientBounds.getNorthEast(), clientPrecision, 2);

      const minZoom = Math.max(clientBoundsZoom, 0);

      mapRef.current.leafletElement.setMaxBounds(paddedBounds);
      mapRef.current.leafletElement.setMinZoom(minZoom);
      mapRef.current.leafletElement.fitBounds(clientBounds);
      setInitialBoundsMinZoom(minZoom);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBoundsRequest.data]);

  React.useEffect(() => {
    setInitialBounds();
  }, [setInitialBounds]);

  React.useEffect(() => {
    if (initialBoundsMinZoom) {
      mapRef.current.leafletElement.setMinZoom(initialBoundsMinZoom);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBoundsMinZoom]);

  return {
    resetInitialBounds: () => {
      setInitialBoundsMinZoom(null);
      setInitialBounds();
    },
    mapTilesShown: typeof initialBoundsMinZoom === 'number'
  };
};

export const degreesToRads = (deg: number) => (deg * Math.PI) / 180.0;

import * as React from 'react';

import { CoreMapsPage } from '../containers/core/maps/coreMaps';
import { InvalidPage } from '@components/errors/invalidPage';
import { useWorldRoutes } from './parts/allWorldRoutes';
import { useTranslation } from '@lib/useTypedTranslation';
import { usePathBasedParams } from '@lib/usePathBasedParams';
import { getMapIdFromTabTitle, useTabsMap } from '../containers/core/maps/tabs';

export const CoreMapsRoute = () => {
  const { t } = useTranslation('redirect');
  const tabsMap = useTabsMap();
  const { pathBasedParams: { tab } } = usePathBasedParams<{ tab: string }>(/\/core\/maps/);

  const mapId = tab?.toUpperCase().replaceAll('-', '_');
  const mapNotFound = tabsMap
    .findIndex(tab => getMapIdFromTabTitle(tab.title) === mapId) === -1;

  const routes = useWorldRoutes();
  const linkToMaps = routes.core.maps;

  if (mapNotFound) {
    return <InvalidPage
      message={t('MAP_NOT_FOUND')}
      redirect={{
        message: t('REDIRECT_TO_MAPS'),
        path: linkToMaps
      }}
    />;
  }

  return (
    <CoreMapsPage mapId={mapId} />
  );
};

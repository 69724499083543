import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from '@lib/useTypedTranslation';
import styled from 'styled-components';

import { decode } from '../lib/base64';
import { CoreDevice } from '../containers/core/device';
import { useCallback, useMemo } from 'react';
import { getDeviceIds } from '../services/core/devices';
import { Loading } from '../components/loading/loading';
import { InvalidPage } from '../components/errors/invalidPage';
import { useWorldRequest } from '../lib/useWorldRequest';
import { usePathBasedQueryString } from '../lib/usePathBasedQueryString';
import { useWorldRoutes } from './parts/allWorldRoutes';
import { isNull } from 'lodash';
import { usePathBasedParams } from '@lib/usePathBasedParams';

export const CoreDeviceOverviewPage = () => <DevicesPage />;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
`;

const DevicesPage = () => {
  const { pathBasedParams: { tab } } = usePathBasedParams<{ tab: string }>(/\/(core|device)\/devices/);
  const { t } = useTranslation(['translation', 'redirect']);
  const { queryString: search } = usePathBasedQueryString(/\/(core|device)\/devices/);
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const queryId = searchParams.get('id');
  const serialNumber = searchParams.get('sn');
  const assetTag = searchParams.get('assetTag');
  const imei = searchParams.get('imei');
  const deviceId = queryId ? decode(queryId) : undefined;
  const routes = useWorldRoutes();
  const linkToDevices = routes.core.devicesList;

  const identifiers = useMemo(() => {
    return {
      id: deviceId,
      serialNumber: serialNumber ?? undefined,
      assetTag: assetTag ?? undefined,
      imei: imei ?? undefined,
    };
  }, [assetTag, deviceId, imei, serialNumber]);

  const fetchDeviceIdsRequired = useMemo(() => {
    const identifiersCount = Object.values(identifiers).filter(i => typeof i !== 'undefined').length;
    const idAndOtherIdentifiersProvided = identifiers.id && identifiersCount > 1;
    const onlyNonIdIdentifiersProvided = !identifiers.id && identifiersCount > 0;
    return idAndOtherIdentifiersProvided || onlyNonIdIdentifiersProvided;
  }, [identifiers]);

  const deviceIdsFetcher = useCallback(() => {
    if (fetchDeviceIdsRequired) {
      return getDeviceIds(identifiers);
    }
  }, [fetchDeviceIdsRequired, identifiers]);

  const { data: deviceIds, loading } = useWorldRequest(deviceIdsFetcher, { initialLoading: true });
  if (!fetchDeviceIdsRequired) {
    return (
      <CoreDevice tab={tab} deviceId={deviceId} />
    );
  }

  if (loading) {
    return <LoadingContainer><Loading isLoading={loading} transparentOverlay={false}></Loading></LoadingContainer>;
  }

  if (deviceIds && deviceIds.length > 1) {
    return <InvalidPage
      message={t('MULTIPLE_DEVICES_FOUND', { ns: 'translation' })}
      redirect={{
        message: t('REDIRECT_TO_DEVICES', { ns: 'redirect' }),
        path: linkToDevices
      }}
    />;
  }

  return (
    <CoreDevice tab={tab} deviceId={deviceIds && deviceIds.length ? deviceIds[0] : undefined} />
  );
};

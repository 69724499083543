import * as React from 'react';

import { AverageDischargeDeviceSettings } from './averageDischargeDeviceSettings';
import { WorldSettings } from 'services/config/config';

import '../settings.css';

export interface IProps {
  worldSettings: WorldSettings,
  onDataChanged: (data: WorldSettings) => void,
  submitInProgress: boolean,
  dataTimestamp: number
}

export function AverageDischargeSettings(props: IProps) {

  return (<div className="threshold-settings-composition averageDischarge">
    <AverageDischargeDeviceSettings
      timeStamp={props.dataTimestamp}
      onDataChanged={props.onDataChanged}
      worldSettings={props.worldSettings}
      submitInProgress={props.submitInProgress}
    />
  </div>);
}

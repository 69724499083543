import React, { useContext } from 'react';

export const FeatureTogglesContext = React.createContext<FeatureTogglesContextValue>(undefined);

interface FeatureTogglesProviderProps {
  value: FeatureTogglesContextValue,
  children: React.ReactNode
}

export enum ToggleableFeatures {
  hideEnrolmentPage = 'hide-enrolment-page',
  hideUserManagement = 'hide-user-management',
}

export interface FeatureTogglesContextValue {
  hideEnrollmentPage: boolean,
  hideUserManagement: boolean,
  showLocationMapTab: boolean,
  getDropsEnabledOnDevice: (platformType: string) => boolean
}

export const FeatureTogglesProvider = ({ children, value }: FeatureTogglesProviderProps) => {
  return (
    <FeatureTogglesContext.Provider value={value}>
      {children}
    </FeatureTogglesContext.Provider>
  );
};

export function useFeatureTogglesContext() {
  const context = useContext(FeatureTogglesContext);
  if (context === undefined) {
    throw new Error('useFeatureTogglesContext must be used within a FeatureTogglesProvider');
  }
  return context;
}

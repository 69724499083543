import React from 'react';
import { map, get, some, cloneDeep, set, find, findIndex } from 'lodash';
import { CustomValidatedInput } from '../../components/customValidatedInput/customValidatedInput';

import './settings-table.css';

export interface IProps {
  definition: {
    columns?: {
      header?: JSX.Element | string,
      dataSuffix?: (JSX.Element | string),
      dataPrefix?: (JSX.Element | string),
      type?: 'text' | 'warning' | 'problem',
      errorMessage?: string,
      limit?: {
        lower?: number,
        higher?: number
      }
    }[],
    rows?: {
      header?: (JSX.Element | string)
    }[],
    dataPaths: string[]
  },
  data: object[],
  header?: React.ReactNode,
  type?: 'settings',
  dataUpdated: (newData: object[]) => void,
  disabled?: boolean,
  tableId?: string,
  thresholdPrecedence?: 'higher' | 'lower'
}

interface IState {
  data: object[]
}

export class SettingsTable extends React.Component<IProps, IState> {
  private warningPath: string;

  private problemPath: string;

  public constructor(props: any) {
    super(props);
    this.state = { data: props.data };
    this.constructInput = this.constructInput.bind(this);
    this.warningPath = props.definition.dataPaths[findIndex(props.definition.columns, (definition: any) => definition.type === 'warning')];
    this.problemPath = props.definition.dataPaths[findIndex(props.definition.columns, (definition: any) => definition.type === 'problem')];
    this.checkWarningValidity = this.checkWarningValidity.bind(this);
    this.checkProblemValidity = this.checkProblemValidity.bind(this);
    this.getMaxValue = this.getMaxValue.bind(this);
  }

  public render() {
    const haveColumnTitles = some(this.props.definition.columns, (item) => get(item, 'header'));
    const haveRowTitles = some(this.props.definition.rows, (item) => get(item, 'header'));

    let header: JSX.Element;
    if (haveColumnTitles) {
      let columnHeader: JSX.Element;
      if (haveRowTitles) {
        columnHeader = <th scope="col" className="rowTitle">{this.props.header || ''}</th>;
      }
      const columns = map(this.props.definition.columns, (column, i) => <th key={i} scope="col">{column.header}</th>);
      header = <thead><tr>{columnHeader}{columns}</tr></thead>;
    }

    let rows = map(this.props.data, (dataObject, rowIndex) => {
      let rowHeader: JSX.Element;
      if (haveRowTitles) {
        rowHeader = <th scope="row" className="rowTitle">{this.props.definition.rows[rowIndex].header}</th>;
      }

      let dataRows = map(this.props.definition.dataPaths, (path, colIndex) => {
        const suffix = get(this.props.definition, `columns[${colIndex}].dataSuffix`);
        const prefix = get(this.props.definition, `columns[${colIndex}].dataPrefix`);
        return <td key={`${path}_${colIndex}`}>
          {prefix}
          {this.constructInput(dataObject, path, colIndex, rowIndex)}
          {suffix}
        </td>;
      });

      return <tr key={rowIndex}>
        {rowHeader}
        {dataRows}
      </tr>;
    });

    return <table id={this.props.tableId} className={"table settings-table-component " + this.props.type}>
      {header}
      <tbody>
        {rows}
      </tbody>
    </table>;
  }

  private constructInput(dataObject: object, path: string, colIndex: number, rowIndex: number) {
    const type = get(this.props.definition, `columns[${colIndex}].type`);
    const value = get(dataObject, path);
    if (type === 'text') return value;
    return <CustomValidatedInput
      dataKey={path}
      disabled={this.props.disabled}
      errorText={get(this.props.definition, `columns[${colIndex}].errorMessage`)}
      value={value}
      onChange={(newValue: number) => {
        const dataPath = `[${rowIndex}].${path}`;
        const newData = set(cloneDeep(this.props.data), dataPath, newValue);
        this.props.dataUpdated(newData);
      }}
      validationRule={type === 'problem' ? this.checkProblemValidity(rowIndex) : this.checkWarningValidity(rowIndex)}
    />;
  }

  getMaxValue(typeDefinition: string): number {
    return get(find(this.props.definition.columns, (definition: any) => definition.type === typeDefinition), 'limit.higher');
  }

  checkWarningValidity(rowIndex: number): (value: number) => boolean {
    return (value: number): boolean => {
      if ((this.props.data[rowIndex] as any)[this.warningPath] !== (this.state.data[rowIndex] as any)[this.warningPath]) {
        const maxWarning = this.getMaxValue('warning');
        const problemValue = get((this.props.data[rowIndex] as any), [this.problemPath]);

        return (this.props.thresholdPrecedence === 'higher' ? (value >= 1) && (value <= maxWarning - 1) && (value < problemValue) : (value > 1) && (value <= maxWarning) && (value > problemValue));
      } else return true;
    };
  }

  checkProblemValidity(rowIndex: number): (value: number) => boolean {
    return (value: number): boolean => {
      if ((this.props.data[rowIndex] as any)[this.problemPath] !== (this.state.data[rowIndex] as any)[this.problemPath]) {
        const maxProblem = this.getMaxValue('problem');
        const warningValue = get((this.props.data[rowIndex] as any), [this.warningPath]);

        return (this.props.thresholdPrecedence === 'higher' ? (value <= maxProblem) && (value > warningValue) : (value >= 1) && (value <= maxProblem - 1) && (value < warningValue));
      } else return true;
    };
  }
}

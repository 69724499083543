import { useState } from 'react';
import { TTypedTFunction, useTranslation } from '@lib/useTypedTranslation';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { deleteHomeLocationsAction, HomeLocationDeleteFailureData } from '../../services/homeLocations/homeLocations';
import { DeleteDialogue } from '../../components/delete/deleteDialogue';
import { IHomeLocation } from '../../services/config/config';
import { ModalPopup } from '../../components/controls/modalPopup';
import { ButtonColours } from '../app/themes';
import { Button, Variants } from '../../components/controls/button';
import { fontWeight } from '../app/globalStyle';
import { useWait } from '../../lib/wait';
import { useWorldAction } from '../../lib/useWorldAction';
import { useWorldRoutes } from '../../routes/parts/allWorldRoutes';

import './deleteHomeLocations.css';
import { capitalize } from 'lodash';

const DeleteHomeLocationReasonsList = styled.div`
  padding: 1rem 0 1rem 1rem;

  & > div > b {
    font-weight: ${fontWeight.semiBold};
  }

  & > div:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

const DeleteHomeLocationReasons = styled.div`
  &:not(:first-child) {
    margin-top: 2rem;
  }
`;

const StyledLink = styled(Link)`
  color: #07c;
  font-size: 0.875rem;
  font-weight: 600;
  border: 1px solid #c2d1e0;
  line-height: 1.3rem;
  border-radius: 3px;
  padding: 0.375rem 0.75rem;
  white-space: nowrap;
  display: inline-block;
`;

export interface Props {
  showPopup: boolean,
  homeLocations: IHomeLocation[],
  onClose: () => void,
  onDelete: () => void
}
export enum FailureType {
  DEVICE = 'device',
  USER = 'user',
  ZONE = 'zone'
}

type FailureDataCount = Record<FailureType, number>;

const ns = 'deleteHomeLocations' as const;
const ns2 = 'translation' as const;
const ns3 = 'deleteDialogue' as const;
const ns4 = 'zones' as const;
const ns5 = 'userManagement' as const;

export const getTitles = (params:{ t: TTypedTFunction<"deleteHomeLocations" | "translation" | "zones" | "userManagement">, count: number, typeName: FailureType }) => {
  const { t, typeName, count } = params;
  switch (typeName) {
  case FailureType.DEVICE:
    return {
      header: t(`FAILURE_HEADER_DEVICES`, { ns: ns, count }),
      footer: t(`FAILURE_FOOTER_DEVICES`, { ns: ns }),
      counts: t(`DEVICE`, { ns: ns2, count }).toLowerCase()
    };
  case FailureType.USER:
    return {
      header: t(`FAILURE_HEADER_USERS`, { ns: ns, count }),
      footer: t(`FAILURE_FOOTER_USERS`, { ns: ns }),
      counts: t(`USER`, { ns: ns5, count }).toLowerCase()
    };
  case FailureType.ZONE:
    return {
      header: t(`FAILURE_HEADER_ZONE`, { ns: ns, count }),
      footer: t(`FAILURE_FOOTER_ZONE`, { ns: ns }),
      counts: t(`ZONE`, { ns: ns4, count }).toLowerCase()
    };
  default:
    return null;
  }
};

export function DeleteHomeLocations(props: Props) {
  const { t } = useTranslation([ns, ns2, ns3]);
  const { showPopup, onClose, onDelete, homeLocations } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(false);
  const [homeLocationsToDelete, setHomeLocationsToDelete] = useState<IHomeLocation[]>();
  const [failureData, setFailureData] = useState<HomeLocationDeleteFailureData>();
  const linkToBulkActions = useWorldRoutes().bulkActions;

  const deleteHomeLocations = useWorldAction(deleteHomeLocationsAction);

  const { wait } = useWait();

  const count = homeLocationsToDelete?.length || 0;
  if (showPopup && !count && homeLocations.length) {
    setHomeLocationsToDelete(homeLocations);
  } else if (!showPopup && count) {
    setHomeLocationsToDelete(undefined);
  }

  const getHomeLocationName = (id: string): string => {
    return homeLocationsToDelete.find(location => location.id === id)?.name;
  };

  async function handleDelete() {
    setIsDeleting(true);
    let success = false;
    const response = await deleteHomeLocations(homeLocations.map(h => h.id));
    // 5 second delay to allow replication of deletion to ES
    await response.onSuccess(async () => wait(5000).then(resetState), true);
    response
      .onSuccess(() => success = true)
      .onFailure(() => setIsDeleting(false))
      .onExpectedFailure((failure) => setFailureData(failure.data))
      .onUnexpectedFailure(() => setError(true));
    onDelete();
    return success;
  }

  const resetState = () => {
    setIsDeleting(false);
    setError(false);
    setFailureData(undefined);
  };

  const handleClose = () => {
    resetState();
    onClose();
  };

  const errorText = t('ERROR_CUSTOM_DELETE', { ns: ns3, item: t('HOME_LOCATION', { ns: ns2, count }) });

  const subheader = count === 1
    ? t('SUBHEADER_SINGLE', { ns, location: homeLocationsToDelete[0].name })
    : t('SUBHEADER', { ns, count });

  const deleteDialogue = failureData ? null : (
    <DeleteDialogue
      show={showPopup}
      subheader={subheader}
      warning={t('MESSAGE', { ns, count })}
      isDeleting={isDeleting}
      error={error && errorText}
      checkboxRequired={count > 1}
      handleDelete={handleDelete}
      handleClose={handleClose}
      count={count}
    />
  );

  interface FailureData {
    [key: string]: {
      [id: string]: number
    }
  }

  interface Props {
    count: number,
    failureData: FailureData,
    t: TTypedTFunction,
    getHomeLocationName: (id: string) => string,
    typeName: FailureType
  }
  const DeleteReasonsSection: React.FC<Props> = ({ count, failureData, t, getHomeLocationName, typeName }) => {
    return (
      <DeleteHomeLocationReasons data-id={`deleteHomeLocationsFailureReasons${capitalize(typeName)}`}>
        <div data-id={`deleteHomeLocationsFailureReasons${capitalize(typeName)}Header`}>
          {getTitles({ t, count, typeName }).header}
        </div>
        <DeleteHomeLocationReasonsList data-id={`deleteHomeLocationsFailureReasons${capitalize(typeName)}List`}>
          {Object.entries(failureData[`${typeName}Counts`]).map(([id, itemCount]) => (
            <div key={id}>{getHomeLocationName(id)} <b>({itemCount} {getTitles({ t, count: itemCount, typeName }).counts})</b></div>
          ))}
        </DeleteHomeLocationReasonsList>
        <div data-id={`deleteHomeLocationsFailureReasons${capitalize(typeName)}Footer`}>
          {getTitles({ t, count, typeName }).footer}
        </div>
      </DeleteHomeLocationReasons>
    );
  };
  const deviceFailureCount = failureData?.deviceCounts ? Object.entries(failureData.deviceCounts).length : 0;
  const userFailureCount = failureData?.userCounts ? Object.entries(failureData.userCounts).length : 0;
  const zoneFailureCount = failureData?.zoneCounts ? Object.entries(failureData.zoneCounts).length : 0;

  const data: FailureDataCount = {
    [FailureType.DEVICE]: deviceFailureCount,
    [FailureType.USER]: userFailureCount,
    [FailureType.ZONE]: zoneFailureCount
  };

  const failureDialogue = !failureData ? null : (
    <ModalPopup
      show={showPopup}
      handleClose={handleClose}
      header={t('FAILURE_HEADER', { ns, count })}
      classname="delete-home-locations-failure"
      closeDisabled={true}
      footer={<div className='footer'>
        {deviceFailureCount ? <StyledLink to={linkToBulkActions} className="bulk-actions-link">{t('VIEW_BULK_ACTIONS', { ns })}</StyledLink> : null}
        <Button
          colour={ButtonColours.grey}
          variant={Variants.ghost}
          dataId="deleteHomeLocationsCancel"
          text={t('CANCEL', { ns: ns2 })}
          onClick={handleClose}
        /></div>}>
      <div data-id="deleteHomeLocationsFailure">
        {Object.values(FailureType).map((typeName) => (
          !data[typeName] ? null : (
            <DeleteReasonsSection
              key={typeName}
              t={t}
              count={data[typeName]}
              failureData={failureData}
              getHomeLocationName={getHomeLocationName}
              typeName={FailureType[typeName.toUpperCase() as keyof typeof FailureType]}
            />
          )))
        }
      </div>
    </ModalPopup>
  );

  return failureData ? failureDialogue : deleteDialogue;
}

import React, { useContext, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { get, groupBy, isEqual, pick } from 'lodash';

import { AmChart, coreTheme } from '../../../../../../components/chart/amChart';
import { IDeviceDataUsage } from '../../../../../../services/core/devicePerformance';
import { NoSelectionOverlay } from '../../../../../../components/card/noSelectionOverlay';
import { addHoverAnimation } from '../../../../../../components/chart/onHoverAnimation';
import { DeviceEvent } from '../../../../../../services/core/eventsTypes';
import { EditEvents, EditEventsAction, useEditEventsDispatch } from '../events/edit-events';
import { deviceEventFilterGroupNames, IDeviceEventFilterState } from '../events/deviceEventsTypes';
import { getDeviceEventFilterGroups } from '../events/deviceEvents';
import { addEventOverlaysToColumnChart } from './lib/eventOverlays';
import { EventsShown } from '../events-shown';
import { DeviceInfoContext } from '../../../index';
import { ChartName, chartNames } from './lib/chartNames';
import { useFeatureTogglesContext } from '../../../../../../context/featureToggles';
import { drawYAxis } from '../../../../../../lib/dataUsageChart';

import './charts.css';
import '../events/deviceEvents.css';
import { formatTooltipDayWithHourRange } from '@components/chart/tooltips';

interface IProps {
  classname: string,
  data: IDeviceDataUsage[],
  events: DeviceEvent[],
  eventFilters: IDeviceEventFilterState,
  dispatchEventFilters: React.Dispatch<EditEventsAction<ChartName>>,
  visible: boolean,
  onMount: (chart: am4charts.XYChart) => am4charts.XYChart
}

export function DataUsage24Hours(props: IProps) {
  const { classname, eventFilters, dispatchEventFilters, onMount } = props;
  const featureToggles = useFeatureTogglesContext();
  const { platformType } = useContext(DeviceInfoContext);
  const ns = 'translation';
  const { t } = useTranslation(['performance', ns]);
  const [chartDataLoading, setChartDataLoading] = useState(false);
  const [chartReady, setChartReady] = useState(false);

  const {
    handleUpdate, handleApply, handleApplyAll, handleCancel
  } = useEditEventsDispatch(dispatchEventFilters, chartNames.dataUsage24Hours, setLoadingTrue);

  const title = t('DATA_USAGE_HOUR_WIFI_MOBILE', { ns: 'performance' });
  const data = getData();

  function setLoadingTrue() {
    setChartReady(false);
    setChartDataLoading(true);
  }

  function onReady() {
    if (!chartDataLoading) {
      setChartReady(true);
    }
  }

  return (
    <div className={`core-device_performance-${classname}-data-usage ${chartReady ? 'chart-ready' : ''}`}>
      <div className="chart-header">
        <NoSelectionOverlay noSelectionText={t('NO_DATA_AVAILABLE', { ns })} show={!props.data.length} />
        <div className="chart-title">{title}</div>
        <div className="chart-edit-events">
          <EventsShown eventFilters={eventFilters.appliedEventFilters} />
          <EditEvents
            subheader={`‘${title}’`}
            eventGroupNames={deviceEventFilterGroupNames}
            eventFilterGroups={getDeviceEventFilterGroups(platformType, featureToggles)}
            eventFilterState={eventFilters.currentEventFilters}
            handleUpdate={handleUpdate}
            handleApply={handleApply}
            handleCancel={handleCancel}
            handleApplyAll={handleApplyAll}
          />
        </div>
      </div>
      <div className="chart-and-legend-container">
        <div className="chart-container">
          <AmChart
            tag={`performance-${classname}-data-usage-chart`}
            chartProvider={createChart}
            dataProvider={() => { setChartDataLoading(false); return data; }}
            onUpdate={onDataUpdated}
            link={pick(props, 'data', 'events', 'visible')}
            onDataValidated={onReady}
            onMount={onMount}
            cypressId="last24HoursOrSelectedDateDataUsage"
          />
        </div>
      </div>
    </div>
  );

  /* istanbul ignore next */
  function createChart(this: any, id: string) {
    am4core.useTheme(coreTheme);

    const chart: am4charts.XYChart = am4core.create(id, am4charts.XYChart);
    chart.data = props.data;
    chart.paddingTop = 40;
    chart.minHeight = 240;

    function createSeries(field: string, name: string, colour: string) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = 'date';
      series.sequencedInterpolation = true;

      series.stacked = true;

      series.columns.template.fill = am4core.color(colour);
      series.columns.template.stroke = am4core.color(colour);
      series.columns.template.width = am4core.percent(90);
      series.columns.template.tooltipHTML = '';

      series.columns.template.adapter.add("tooltipHTML", (_, target) => {
        const data: any = target.tooltipDataItem.dataContext;
        const newDate = formatTooltipDayWithHourRange(data.tooltipDate);
        return `<div class="tooltip-container_data-usage" data-date="${data.tooltipDate}" data-seriesName="${field}"><span class="data-usage-${classname}-tooltip-title">${newDate}</span>
        </br><span class="data-usage-${classname}-tooltip-text"><div class="chart-key chart-key--data-mobile"></div>&nbsp;${t('MOBILE_DATA')}: {mobile.formatNumber('#.00b')}</span>
        </br><span class="data-usage-${classname}-tooltip-text"><div class="chart-key chart-key--data-wifi"></div>&nbsp;${t('WIFI_DATA')}: {wifi.formatNumber('#.00b')}</span>
        </br><span class="data-usage-${classname}-tooltip-text total">&nbsp;${t('TOTAL_DATA')}: {valueY.total.formatNumber('#.00b')}</span></div>`;
      });

      series.dummyData = {
        colour: am4core.color(colour)
      };

      addHoverAnimation(series, chart);
      return series;
    }

    const dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    dateAxis.dataFields.category = 'date';
    dateAxis.cursorTooltipEnabled = false;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.labels.template.location = 0.5;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.calculateTotals = true;
    valueAxis.contentAlign = 'right';
    drawYAxis(props.data, valueAxis);

    const series1 = createSeries('mobile', t('MOBILE_DATA_LINES', { ns: 'performance' }), '#49a1a9');
    createSeries('wifi', t('WIFI_DATA_LINES', { ns: 'performance' }), '#2c678c');
    addEventOverlaysToColumnChart(series1, chart, dateAxis, t, formatTooltipDayWithHourRange);

    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    chart.legend.labels.template.fontWeight = '400';

    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 15;
    markerTemplate.height = 15;

    markerTemplate.disposeChildren();
    const legendItem = markerTemplate.createChild(am4core.Rectangle);
    legendItem.fill = am4core.color('#49a1a9');
    legendItem.width = 13;
    legendItem.height = 13;

    legendItem.adapter.add("fill", function (_, target) {
      if (target.dataItem && target.dataItem.dataContext && (target.dataItem.dataContext as any).dummyData) {
        return (target.dataItem.dataContext as any).dummyData.colour;
      }
    });

    chart.legend.itemContainers.template.clickable = false;
    chart.legend.itemContainers.template.focusable = false;
    chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;

    return chart;
  }

  /* istanbul ignore next */
  function onDataUpdated(chart: am4charts.XYChart, chartData: any[], prevProps?: any, currentProps?: any) {
    if (!isEqual(get(currentProps, 'link.events'), get(prevProps, 'link.events'))) {
      addEventOverlaysToColumnChart(chart.series.values[0] as am4charts.ColumnSeries, chart, chart.xAxes.values[0] as am4charts.CategoryAxis, t, formatTooltipDayWithHourRange);
      if (!get(currentProps, 'link.visible')) {
        (chart as any).eventsUpdatedWhileHidden = true;
      }
    }
    // If the chart has just become visible and was updated while hidden,
    // invalidate it to force a redraw, as it may have redrawn incorrectly.
    if (get(currentProps, 'link.visible') && !get(prevProps, 'link.visible') && (chart as any).eventsUpdatedWhileHidden) {
      chart.deepInvalidate();
      delete (chart as any).eventsUpdatedWhileHidden;
    }
    return chart;
  }

  function getData() {
    if (!props.data.length) {
      return [];
    }
    const eventsByHour = groupBy(props.events, (event) => moment.utc(event.local).startOf('hour').valueOf());
    return props.data.map(({ date, wifi, mobile }) => {
      return {
        date: moment.utc(parseInt(date)).format('LT'),
        tooltipDate: date,
        wifi,
        mobile,
        events: get(eventsByHour, date, [])
      };
    });
  }
}

import { ITranslationKeys } from "./keys";

// istanbul ignore next
export const ukTranslations: ITranslationKeys = {
  translation: {
    "AND": 'and',
    "BATTERY": "battery",
    "BATTERY_other": "batteries",
    "BATTERY_WITH_COUNT": "{{count}} battery",
    "BATTERY_WITH_COUNT_other": "{{count}} batteries",
    "DEVICE": "Device",
    "DEVICE_other": "Devices",
    "DEVICES_LIST_TITLE": "Device List",
    "GROUP": "Group",
    "GROUP_other": "Groups",
    "GROUP_WITH_COUNT": "{{count}} group",
    "GROUP_WITH_COUNT_other": "{{count}} groups",
    "MAPS": "Maps",
    "NO_GROUP": "No group",
    NO_GROUP_title: 'No Group',
    NO_HOME_LOCATION: 'No Home Location',
    "BATTERIES_IN_GROUP": "$t(batteryWithCount, {'count': {{batteries}} }) in $t(groupWithCount, {'count': {{groups}} })",
    "LICENSED": "Licensed",
    "PURCHASE": "Purchase",
    "PRODUCT_SELECTION": "Product Selection",
    "ADMIN": "Admin",
    "OF": "of",
    "DOWNLOAD": "Download",
    "ALL": "All",
    "NO_PROBLEMS": "No Problems",
    "HEALTH_WARNING": "Health Warning",
    "POOR_HEALTH": "Poor Health",
    "CYCLE_COUNT_WARNING": "Cycle Count Warning",
    "HIGH_CYCLE_COUNT": "High Cycle Count",
    "LAST_TIME_SEEN_STATEMENT": "{{timePeriod}}",
    "VIEW_IN_CORE": "View In Core",
    "VIEW_IN_SMART_SERVICE": "View In Smart Service",
    "FILTERS": "Filters",
    "APPLY": "Apply",
    "CANCEL": "Cancel",
    "CONFIRM": "Confirm",
    "DELETE": "Delete",
    "NEW": "New",
    "FILTER": "Filter",
    "EDIT": "Edit",
    "EXPORT": "Export",
    "SEARCH": "Search",
    "TO": "to",
    "INFORMATION": "Information",
    "CHARGE_LEVEL": "Charge Level",
    "FILTER_STATUS_TEXT": "{{filterName, lowercase}} found with following filters:",
    "FILTER_STATUS_TEXT_other": "{{filterName, lowercase}} found with following filters:",
    "ACROSS": "across",
    "ALL_CATEGORIES": "all categories",
    "IN_NEED_OF": "in need of",
    "REPLACEMENT": "replacement",
    "WITH": "with",
    "WARNINGS": "warnings",
    "ENROLLMENT": "Enrolment",
    "SETTINGS": "Settings",
    "ABOUT": "About",
    "HELP_CENTER": "Help Center",
    "SIGN_OUT": "Sign Out",
    "TO_LOWERCASE": "{{text, lowercase}}",
    "NOUN_AGREEMENT_HOURS": "Last recorded {{value}} hours",
    "NOUN_AGREEMENT_DAYS": "Last recorded {{value}} days",
    "EDIT_FILTERS": "Edit Filters",
    "CLEAR_ALL": "Clear All",
    "LOADING": "Loading",
    "MORE": "more",
    "MB": "MB",
    "GB": "GB",
    "BATTERY_FIRST_CAPITAL": "Battery",
    "BATTERY_FIRST_CAPITAL_other": "Batteries",
    "IMEI": "IMEI",
    "CPU": "CPU",
    "IDLE": "Idle",
    "PROMPT_MESSAGE": "Leave this page? Changes that you made may not be saved.",
    FIRST_SEEN: "First Seen",

    // CHARTS
    "NO_DATA_AVAILABLE": "No Data Available",
    "RADIO_OFF": "Radio Off",
    MOBILE: "Mobile",
    WIFI: "Wi-Fi",
    MOBILE_AND_WIFI: "Mobile & Wi-Fi",
    DATA: "Data",

    // PRODUCT SUBTITLES
    "ELEMEZ_BATTERY_SUBTITLE": "Essentials - Track, Identify and replace batteries before they impact your business.",
    "PANASONIC_BATTERY_SUBTITLE": "Monitor - Track, Identify and replace batteries before they impact your business.",
    "ELEMEZ_CORE_V2_SUBTITLE": "Core - Visualise and act upon key performance metrics, events and trends across the estate.",
    "PANASONIC_CORE_V2_SUBTITLE": "Smart Service - Visualise and act upon key performance metrics, events and trends across the estate.",

    // CORE SELECTOR BAR
    "OVERVIEW": "Overview",
    "DEVICES": "Devices",
    "APPLICATIONS": "Applications",
    "ANOMALIES": "Anomalies",
    "PERFORMANCE": "Performance",

    DEVICE_NOT_FOUND: 'No device was found matching the information provided',
    MULTIPLE_DEVICES_FOUND: 'More than one device was found matching the information provided',

    //CORE DEVICE OVERVIEW TAB
    "ALERTS": " Alerts ({{count}})",
    "CONNECTIONS_AND_ASSETS": "Connections and Assets",
    "LAST_CONTACT": "Last Contact",
    "LAST_KNOWN_WIFI": "Last Known Wi-Fi Connection",
    "NAME_SSID": "Name (SSID)",
    "ADDRESS_BSSID": "Address (BSSID)",
    "UPDATED": "Updated",
    "STATUS": "Status",
    "STATUS_other": "Statuses",
    "SERIAL": "Battery Serial Number",
    "SERIAL_list": "Battery {{ batteryNumber }} Serial Number",
    "SIM_SERIAL": "SIM Serial",
    "SIM_SERIAL_list": "SIM {{ simNumber }} Serial",
    "OPERATOR": "SIM Operator",
    "OPERATOR_list": "SIM {{ simNumber }} Operator",
    "OPERATOR_CODE": "Operator Code",
    "SYSTEM": "System Information",
    "OPERATING_SYSTEM": "Operating System",
    "BUILD_VERSION": "Build Version",
    "FIRMWARE_VERSION": "Firmware Version",
    "TIMEZONE": "Timezone",
    "TIME": "Time",
    "GROUPS": "Groups",
    "REPLACE": "Replace",
    "WARNING": "Warning",
    "GOOD": "Good",
    "ELEMEZ_VERSION": "Elemez Version",
    "EXTENSION_VERSION": "Elemez Extension Version",
    "STORAGE": "Storage",
    "AVAILABLE": "Available",
    "TOTAL_SPACE": "Total Space",
    "USED": "Used",
    "TOTAL_RAM": "Total RAM",
    "COMPUTER_NAME": "Computer Name",
    "NO_SMART_BATTERY_DETECTED": "No smart battery detected",
    "NO_SIM_DETECTED": "No SIM card detected",
    "SIM": "SIM",

    //OVERVIEW PAGE
    "PROBLEM": "Problem",
    "WELCOME_TITLE": "Welcome to Elemez",
    "ESTATE_ALERT_COUNT": "Issues detected in your device estate. <1>{{totalWithAlerts, localiseNumber}}</1> of your <3>{{total, localiseNumber}}</3> devices is<5>currently alerting.</5>",
    "ESTATE_ALERT_COUNT_other": "Issues detected in your device estate. <1>{{totalWithAlerts, localiseNumber}}</1> of your <3>{{total, localiseNumber}}</3> devices are<5>currently alerting.</5>",
    "ESTATE_ALERT_COUNT_SIMPLE": "<0>{{totalWithAlerts, localiseNumber}}</0> of your <2>{{total, localiseNumber}}</2> devices is<4>alerting.</4>",
    "ESTATE_ALERT_COUNT_SIMPLE_other": "<0>{{totalWithAlerts, localiseNumber}}</0> of your <2>{{total, localiseNumber}}</2> devices are<4>alerting.</4>",
    "NO_ISSUES_DETECTED": "No issues detected in your device estate.",
    "VIEW_DEVICES": "View All Devices",

    //DEVICES LIST
    "FILTER_MODAL_HEADER": "Device Filters",
    "FILTER_MODAL_SUBHEADER": "Filter your list of devices by the categories and characteristics below",
    FILTER_MODAL_TAB_1: `Device Details`,
    FILTER_MODAL_TAB_2: `Alert Types`,
    "FILTER_COUNT_MESSAGE": "Devices matching your filters",
    "FILTER_NONE_SELECTED_MESSAGE": "No filters selected",
    "FILTER_APPLY": "Apply filters",
    "MANUFACTURER": "Manufacturer",
    "MANUFACTURER_other": "Manufacturers",
    "MODEL": "Model",
    "MODEL_other": "Models",
    "SERIAL_NUMBER": "Serial Number",
    "ASSET_TAG": "Asset Tag",
    "HOME_LOCATION": "Home Location",
    "HOME_LOCATION_other": "Home Locations",
    "ENROLLMENT_DATE": 'Enrolment Date',
    "LAST_SEEN": "Last Seen",
    "MOBILE_DATA": "Mobile Data",
    "MOBILE_DATA_TOOLTIP_TEXT": "The recorded $t(MOBILE_DATA) for the last 30 days.",
    "WIFI_DATA": "Wi-Fi Data",
    "WIFI_DATA_TOOLTIP_TEXT": "The recorded $t(WIFI_DATA) for the last 30 days.",
    "TOTAL_DATA": "Total Data",
    "MATCHING_SEARCH": "matching your search",
    "SEARCH_MATCH_STATEMENT": "result matching your search",
    "SEARCH_MATCH_STATEMENT_other": "results matching your search",
    "NAME": "Name",
    "NAME_REQUIRED": "Name - <1>Required</1>",
    "JOB_TITLE_REQUIRED": "Job title - <1>Required</1>",
    "VERSION": "Version",
    "SIZE": "Size",
    "SIZE_TOOLTIP_TEXT": "Application sizes are available for devices using Android 8 or higher.",
    "LAST_UPDATED": "Last Updated",
    "LAST_USED": "Last Used",
    "AVERAGE_DAILY_USAGE": "Avg Daily Usage",
    "AVERAGE_DAILY_CPU_USAGE": "Avg Daily CPU Usage",
    "USER_EXPERIENCE": "User Experience",
    "LOCATION": "Location",
    "LOCATION_UPDATED": "Location Updated",
    "LAST_LOCATION_LOCK": "Last Location Lock",
    "LOCATION_UPDATED_TOOLTIP": "This is the time that the agent last recorded the device location fix.",
    "LAST_LOCATION_LOCK_TOOLTIP": "This is the time that the last location fix was acquired by the device.",
    "UTILISATION": "Utilisation",
    "BATTERY_ESTATE_OVERVIEW_TITLE": "Battery $t(ESTATE_OVERVIEW_TABLE_TITLE)",
    "DEVICE_ESTATE_OVERVIEW_TITLE": "Device $t(ESTATE_OVERVIEW_TABLE_TITLE)",
    "ESTATE_OVERVIEW_SUBTITLE": "{{count, localiseNumber}} Total {{label}}",
    "ESTATE_OVERVIEW_TABLE_TITLE": "Estate Overview",
    "ESTATE_OVERVIEW_TABLE_SUBTITLE": "Device status breakdown of your Groups and Home Locations.",
    "CORE_ESTATE_OVERVIEW_TABLE_SUBTITLE": "Estate Alerts Breakdown",
    "NO": "no",
    "ALERTS_TEXT": "alerts",
    "NO_SELECTION_TEXT": "No {{item}} Selected",
    "DEVICE_STATUS_HEADER": "Device Status",
    "DAYS_TEXT": "Day",
    "DAYS_TEXT_other": "Days",
    "GROUPS_FILTER_HEADER": "Groups Filter",
    "HOMELOCATIONS_FILTER_HEADER": "Home Locations Filter",
    "IN_PROGRESS": "In progress",

    "CLOSE": "Close",

    "MOBILE_DATA_USED": "Mobile Data Used",
    "WIFI_DATA_USED": "Wi-Fi Data Used",

    "AVERAGE_DISCHARGE": "Average Discharge",
    "DEVICE_NAME": "Device Name",
    "OS_VERSION": "OS Version",
    "BIOS_VERSION": "BIOS Version",
    "CLIENT_VERSION": "Client Version",
    "EXTENSION_VERSION_DEVICE": "Extension Version",
    "STORAGE_USED": "Storage Used",

    // Pagination bar
    JUMP_TO_PAGE: 'Jump to page',
    PER_PAGE: 'Per page',
    GO: 'Go',
    RESULT: "result",
    RESULT_other: "results",

    CHECKBOX_MESSAGE: "Yes, I understand.",

    //Processes Tab
    PROCESSES: "Processes",

    //What's New
    WHATS_NEW: "What's New",

    DEVICE_LOCAL_TIME: "Device Local Time{{timeZone}}"

  },

  anomaly: {
    // Anomalies page
    ANOMALIES: "$t(translation:ANOMALIES)",
    RESULT: "result",
    RESULT_other: "results",
    DESCRIPTION: "Description",
    LAST_AFFECTED: "Last Affected",
    LAST_AFFECTED_TOOLTIP: "The number of devices that had this property and this alert type when the detection algorithm was last executed.",
    LAST_AFFECTED_TOOLTIP_LABEL: "About last affected",
    TOTAL_AFFECTED: "Total Affected",
    TOTAL_AFFECTED_TOOLTIP: "The number of unique devices that had this property and this alert type over the time period for this anomaly.",
    TOTAL_AFFECTED_TOOLTIP_LABEL: "About total affected",
    HIGHEST_AFFECTED: "Highest Affected",
    HIGHEST_AFFECTED_TOOLTIP: "The highest concurrent number of devices with this property and this alert type seen over the time period for this anomaly.",
    HIGHEST_AFFECTED_TOOLTIP_LABEL: "About highest affected",
    OCCURRENCES: "Occurrences",
    OCCURRENCES_TOOLTIP: "How many times the number of devices with this property and this alert type triggered an anomaly warning over the time period.",
    OCCURRENCES_TOOLTIP_LABEL: "About occurrences",
    FIRST_DETECTED: "First Detected",
    FIRST_DETECTED_TOOLTIP: "The date at which an anomaly warning was first triggered for this property and alert type combination.",
    FIRST_DETECTED_TOOLTIP_LABEL: "More about first detected",
    LAST_DETECTED: "Last Detected",
    LAST_DETECTED_TOOLTIP: "The date at which an anomaly warning was last triggered for this property and alert type combination.",
    LAST_DETECTED_TOOLTIP_LABEL: "About last detected",
    PROPERTY: "Property",
    PROPERTY_other: "Properties",
    THIS_PROPERTY: "this property",
    THIS_PROPERTY_other: "these properties",
    ALERT_TYPE: 'Alert Type',
    LESS_THAN_ONE_PERCENT: "Less than 1%",
    ACTIVE: "active",
    ALL: "All",
    ONE_IN_EVERY_STATEMENT: "1 in every {{ratio}}",
    HAS: "has",
    HAS_other: "have",
    DEVICES_WITH_ALERT_TYPE_STATEMENT: "{{active}} devices {{has}} this alert type.",
    DEVICES_WITH_PROPERTY_AND_ALERT_TYPE_STATEMENT: "{{active}} devices with this property {{has}} this alert type.",
    DEVICES_WITH_PROPERTY_AND_ALERT_TYPE_STATEMENT_other: "{{active}} devices with these properties {{has}} this alert type.",
    MORE_THAN_EXPECTED_STATEMENT: "Devices with this property have approximately {{multiplier}}x more alerts of this type than expected.",
    MORE_THAN_EXPECTED_STATEMENT_other: "Devices with these properties have approximately {{multiplier}}x more alerts of this type than expected.",
    ANOMALY_FILTER_MODAL_HEADER: "Anomaly Filter",
    ANOMALY_FILTER_MODAL_SUBHEADER: "Filter your list of anomalies by alert type below",
    ANOMALY_DETECTION_NOT_SUPPORTED: "There are currently fewer than 50 devices in this world. This feature requires a minimum of 50 devices present in this world to operate.",
    ANOMALY_DETECTION_NOT_SUPPORTED_disabled: "Anomaly detection is disabled. This feature requires a minimum of 50 devices present to operate and can be enabled via settings.",

    ANOMALY_DETECTION_SUPPORT_WARNING: "Note that this feature requires a minimum of 50 devices present in this world to operate.",

    // Anomaly details
    ANOMALY_HISTORY: "Anomaly History",
    ANOMALY_DEVICE_CHART_TITLE: "Devices affected in the last 90 days",
    HISTORY: "History",
    DEVICES_AFFECTED_ANOMALY_RAISED: "Devices Affected (Anomaly Raised)",
    DEVICES_AFFECTED_ANOMALY_NOT_RAISED: "Devices Affected (Anomaly Not Raised)",

    // Anomaly Devices
    ANOMALY_DEVICES_TABLE_TITLE: "List of devices affected by this anomaly on {{date}}",
    ANOMALY_DEVICES_TITLE: "Affected Devices List",
    ANOMALY_DEVICES_DOWNLOAD_FILE_NAME: "anomaly({{description}})_devices",
    ANOMALY_DEVICES_LIST_TITLE: "$t(translation:DEVICES_LIST_TITLE)",

    AVERAGE_DISCHARGE_STATUS: `High average hourly discharge`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS: `Access point not at home location`,
    DEVICE_LOCATION_DISTANCE_STATUS: `Distance away from home location`,
    DEVICE_LOCATION_TIME_STATUS: `Time away from home location`,
    DEVICE_TIME_ERROR_STATUS: `Time error`,
    DEVICE_USER_EXPERIENCE_DROP_STATUS: `Drop`,
    DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS: `Low power`,
    DEVICE_USER_EXPERIENCE_REBOOT_STATUS: `Reboot`,
    DEVICE_UTILISATION_IDLE_STATUS: `$t(translation:UTILISATION) idle`,
    DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS: `Out of contact`,
    DEFAULT_ALERT: `Devices Alert`
  },

  redirect: {
    REDIRECT_TO_ANOMALIES: 'View list of all anomalies',
    REDIRECT_TO_APPLICATIONS: 'View list of all applications',
    REDIRECT_TO_DEVICES: 'View list of all devices',
    REDIRECT_TO_MAPS: 'View all maps',
    REDIRECT_TO_PROCESSES: 'View list of all processes',
    REDIRECT_TO_ZONES: "View list of all zones",

    ANOMALY_NOT_FOUND: 'No anomaly was found matching the information provided',
    APPLICATION_NOT_FOUND: 'No application was found matching the information provided',
    MAP_NOT_FOUND: 'No map was found matching the information provided',
    PROCESS_NOT_FOUND: 'No process was found matching the information provided',
  },

  accessDenied: {
    THIS_PAGE: 'this page',
    THIS_PRODUCT: 'this product',
    THIS_WORLD: 'this world',
    ACCESS_DENIED_PREFIX: 'You do not have permission to access',
    ACCESS_DENIED_TO_PAGE: '$t(ACCESS_DENIED_PREFIX) $t(THIS_PAGE).',
    ACCESS_DENIED_TO_PRODUCT: '$t(ACCESS_DENIED_PREFIX) $t(THIS_PRODUCT).',
    ACCESS_DENIED_TO_WORLD: '$t(ACCESS_DENIED_PREFIX) $t(THIS_WORLD).',
    CONTACT_ADMIN_MESSAGE: 'For more information, please contact your System Administrator.',
    RETURN_TO_PRODUCT_SELECTION: 'Return to Product Selection',
    RETURN_TO_WORLD_SELECTION: 'Return to World Selection'
  },
  worldSelection: {
    TITLE: 'World Selection',
    SELECT_A_WORLD: 'Please select the world you would like to view'
  },
  editRecord: {
    TITLE: "Edit Record",
    SUBHEADER: "Edit the asset tag, home location and groups of this device.",
    ERROR_MESSAGE: 'The following value failed to update: {{fields, list}}. Please try again.',
    ERROR_MESSAGE_other: 'The following values failed to update: {{fields, list}}. Please try again.',
    ASSET_TAG_VALIDATION_ERR: "Asset tags must be a maximum of {{maxLength}} characters.",
    GROUP_VALIDATION_ERR_COMMAS: 'Groups must not include commas.',
    GROUP_VALIDATION_ERR_LENGTH: 'Groups must be a maximum of {{maxLength}} characters.',
    NO_HOME_LOCATION: "No home location",
    GROUP_REMOVE: 'Click to remove group',
    GROUP_PLACEHOLDER: 'Add a group'
  },

  editEvents: {
    // EDIT EVENTS MODAL
    OPEN_BUTTON: 'Show Events',
    FILTER_BUTTON: 'Filter Events',
    DISPLAY_ALL: "Show All Events",
    ICON: "Icon",
    APPLICATIONS_GROUP: "Application",
    BATTERY_GROUP: "Battery",
    POWER_GROUP: "Power",
    USER_EX_GROUP: "User Experience",
    NETWORK: "Network",
    APPLY_BUTTON: "Apply",
    OR: "or",
    TIME_GROUP: "Time Synchronisation",
    MODAL_HEADER: "Display Event Markers",
    MODAL_TABLE_HEADER: "Filter Events List",
    MODAL_SUBHEADER: "Edit the event types displayed on the performance charts.",
    MODAL_TABLE_SUBHEADER: "Filter the events displayed on the performance page events list.",
    EDITING: "Editing",
    APPLY_ALL_BUTTON: "Apply to All",
    RADIO_GROUP: "Radio",

    // EDIT EVENTS MODAL DEVICE EVENTS
    APPLICATION_INSTALLED: "App Install",
    APPLICATION_UNINSTALLED: "App Uninstall",
    APPLICATION_UPDATED: "App Update",
    BATTERY_SWAP: "Battery Swap",
    DEVICE_ON_POWER: "On Charge",
    DEVICE_OFF_POWER: "Off Charge",
    DEVICE_LOW_POWER: "Low Power",
    DEVICE_REBOOTED: "Reboot",
    DEVICE_DROP: "Drop",
    TIME_ERROR: "Time Error",
    TIME_RECOVERY: "Time Recovery",
    NETWORK_AVAILABLE: "Network Available",
    NETWORK_LOST: "Network Lost",
    NETWORK_CHANGED: "Network Change",
    BEARER_CHANGED: "Bearer Change",
    SIM_CHANGED: "SIM Change",
    AIRPLANE_MODE_OFF: "Airplane Mode Off",
    AIRPLANE_MODE_ON: "Airplane Mode On",
    MOBILE_RADIO_ON: "Mobile Radio On",
    MOBILE_RADIO_OFF: "Mobile Radio Off"
  },
  "formControls": {
    "DELETE": "Delete",
    "DELETE_other": "Delete ({{count}})",
    "SAVE": "Save",
    "SAVE_other": "Save ({{count}})",
    "REVIEW": "Review",
    "REVIEW_other": "Review ({{count}})",
    "APPLY": "Apply",
    "APPLY_other": "Apply ({{count}})",
  },
  devicesList: {
    "DEVICE_ACROSS": "device across",
    "DEVICE_ACROSS_other": "devices across",
    "ELEMEZ_COREv2_CATEGORIES": "all categories",
  },
  deleteDialogue: {
    CONFIRMATION_QUESTION: "Are you sure?",
    CANCEL_DELETION: "No, cancel deletion",
    ERROR: "$t(error:SOMETHING_WRONG_RETRY)",
    ERROR_CUSTOM_DELETE: "$t(error:SOMETHING_WRONG) One or more {{ item, lowercase }} may not have been deleted. Please try again."
  },
  deleteSmartBatteries: {
    SUBHEADER: "{{count}} battery will be deleted.",
    SUBHEADER_other: "{{count}} batteries will be deleted.",
    MESSAGE: "You will no longer be able to view this battery and its individual analytics.",
    MESSAGE_other: "You will no longer be able to view these batteries and their individual analytics.",
    CONFIRM_BATTERIES_DELETE: "Delete Battery",
    CONFIRM_BATTERIES_DELETE_other: "Delete Batteries"
  },
  deleteDevices: {
    SUBHEADER: "{{count}} device will be deleted.",
    SUBHEADER_other: "{{count}} devices will be deleted.",
    MESSAGE: "You will no longer be able to view this device and its individual analytics.",
    MESSAGE_other: "You will no longer be able to view these devices and their individual analytics.",
    CONFIRM_DELETE: "Yes, delete this device",
    CONFIRM_DELETE_other: "Yes, delete these devices"
  },
  deleteUsers: {
    SUBHEADER: "{{count}} user will be deleted.",
    SUBHEADER_other: "{{count}} users will be deleted.",
    MESSAGE: "You will no longer be able to see this user and they will no longer be able to log in.",
    MESSAGE_other: "You will no longer be able to see these users and they will no longer be able to log in.",
    CONFIRM_DELETE: "Yes, delete this user",
    CONFIRM_DELETE_other: "Yes, delete these users"
  },
  deleteHomeLocations: {
    SUBHEADER_SINGLE: "‘{{ location }}’ will be deleted.",
    SUBHEADER: "{{ count }} home location will be deleted.",
    SUBHEADER_other: "{{ count }} home locations will be deleted.",
    MESSAGE: "You will no longer be able assign devices or users to this home location.",
    MESSAGE_other: "You will no longer be able assign devices or users to these home locations.",
    CONFIRM_DELETE: "Yes, delete this home location",
    CONFIRM_DELETE_other: "Yes, delete these home locations",
    FAILURE_HEADER: "Unable to delete home location",
    FAILURE_HEADER_other: "Unable to delete home locations",
    FAILURE_HEADER_DEVICES: "The following home location cannot be deleted because it has devices assigned to it:",
    FAILURE_HEADER_DEVICES_other: "The following home locations cannot be deleted because they have devices assigned to them:",
    FAILURE_FOOTER_DEVICES: "You can change the home location of these devices individually or as a bulk action.",
    FAILURE_HEADER_USERS: "The following home location cannot be deleted because it has users assigned to it:",
    FAILURE_HEADER_USERS_other: "The following home locations cannot be deleted because they have users assigned to them:",
    FAILURE_FOOTER_USERS: "You can change the home locations of these users individually.",
    FAILURE_HEADER_ZONE: "The following home location cannot be deleted because it is assigned to a Zone:",
    FAILURE_HEADER_ZONE_other: "The following home locations cannot be deleted because they are assigned to a Zone:",
    FAILURE_FOOTER_ZONE: "You can remove home locations from these zones individually.",
    VIEW_BULK_ACTIONS: "View Bulk Actions"
  },
  timeState: {
    TODAY: "Today",
    YESTERDAY: "Yesterday",
    HOUR: "hour",
    HOUR_other: "hours",
    MIN: "min",
    MINUTE: "minute",
    MINUTE_other: "minutes",
    HOURS_24: "24 Hours",
    HOURS_48: "48 Hours",
    DAYS_7: "7 Days",
    LESS_THAN_A_MONTH: "Less than a month",
    MONTH: "month",
    MONTH_other: "months",
    YEAR: "year",
    YEAR_other: "years",
    HOUR_COUNT: '{{ count }} Hour',
    HOUR_COUNT_other: '{{ count }} Hours',
    DAY_COUNT: '{{ count }} Days',
    DAY_COUNT_other: '{{ count }} Days',
    OVER_THE_LAST_DAYS: 'over the last {{ count }} days',
    IN_THE_LAST_F: 'in the last',
    IN_THE_LAST_F_other: 'in the last',
    IN_THE_LAST_M: 'in the last',
    IN_THE_LAST_M_other: 'in the last',
    LAST_N_DAYS: 'Last $t(timeState:DAY_COUNT_other, { "count": {{count}} })',
    LAST_N_HOURS: 'Last $t(timeState:HOUR_COUNT_other, { "count": {{count}} })',
    MORE_THAN_90_DAYS_AGO: 'More than 90 days ago',
    OVER_THE_PAST_X_DAYS: 'over the past {{ count }} days',
    OVER_THE_PAST_X_HOURS: 'over the past {{ count }} hours'
  },
  distance: {
    METRE: 'metre',
    METRE_other: 'metres',
    KILOMETRE: 'kilometre',
    KILOMETRE_other: 'kilometres',
    YARD: 'yard',
    YARD_other: 'yards',
    MILE: 'mile',
    MILE_other: 'miles',
    SHORT_metric: '$t(distance:METRE)',
    SHORT_imperial: '$t(distance:YARD)',
    SHORT_metric_other: '$t(distance:METRE_other)',
    SHORT_imperial_other: '$t(distance:YARD_other)',
    LONG_metric: '$t(distance:KILOMETRE)',
    LONG_imperial: '$t(distance:MILE)',
    LONG_ABBREVIATION_imperial: 'mi',
    LONG_ABBREVIATION_metric: 'km',
    LONG_metric_other: '$t(distance:KILOMETRE_other)',
    LONG_imperial_other: '$t(distance:MILE_other)'
  },
  performance: {
    "DEVICE_PERFORMANCE_DESCRIPTION": "Current and historic events and metrics presented in the local timezone for this device{{timeZone}}.",
    "VIEW_LATEST_RECORDS": "View latest records",
    "SELECTED_DATE": "Selected date",
    "LAST_24HOURS_TEXT": "Last 24 hours",
    "LAST_30DAYS_TEXT": "Last 30 days",
    "LAST_KNOWN_BATTERY": "Last Known Battery",
    "LAST_KNOWN_BATTERIES": "Last Known Batteries",
    "PRINT_REPORT": "Print Report",
    "NOW": "Now",
    "AT": "at",
    "DEVICES_USED": 'Devices Used',
    "WIFI_DATA_USED": "$t(translation:WIFI_DATA_USED)",
    "MOBILE_DATA_USED": "$t(translation:MOBILE_DATA_USED)",
    "DROPS": "Drops",
    "REBOOTS": "Reboots",
    "LOW_POWER": "Low Power Events",
    "BATTERY_SWAPS": "Battery Swaps",
    "TIME_ERRORS": "Time Errors",
    "IN_USE": "In Use",
    "DEVICE_MINUTES_USAGE_PER_HOUR": "Device Minutes Usage Per Hour",
    "LESS_THAN_ONE_MINUTE_USAGE": "Less than one minute usage",
    "MOBILE_DATA": "Mobile Data",
    "WIFI_DATA": "Wi-Fi Data",
    "MOBILE_DATA_LINES": "Mobile Data",
    "WIFI_DATA_LINES": "Wi-Fi Data",
    "TOTAL_DATA": "Total Data",
    "DATA_USAGE_HOUR_WIFI_MOBILE": "Data Usage Per Hour (Wi-Fi + Mobile)",
    "DEVICE_CHARGE_LEVEL": "Device Charge Level",
    "CHARGE_LEVEL": "Charge Level",
    "BATTERY_SN": "Battery SN",
    REBOOT_TYPE_USER: 'User Initiated',
    REBOOT_TYPE_SYSTEM: 'System Initiated',
    "DEVICE_DATA_USAGE_PAST_30DAYS": "Device Data Usage over the past 30 Days",
    "DEVICE_UTILISATION_PAST_30_DAYS": "Device Minutes Used over the past 30 Days",
    "AVG_HOURLY_DISCHARGE": "Avg Hourly Discharge",
    "AVG_HOURLY_DISCHARGE_LINES": "Avg Hourly Discharge",
    "AVERAGE_HOURLY_DISCHARGE_PAST_30DAYS": "Average Hourly Discharge over the past 30 Days",
    "EVENTS_HEADER": "Event ({{count}})",
    "EVENTS_HEADER_other": "Events ({{count}})",
    "EVENTS_FOOTER": "event detected during this time period",
    "EVENTS_FOOTER_other": "events detected during this time period",
    "SHOW_EVENTS_TEXT": "View Events List",
    "HIDE_EVENTS_TEXT": "Hide Events List",
    "EVENT_TYPE": "Event Type",
    "EVENT_TIME": "Event Time",
    "EVENT_INFORMATION": "Event Information",
    "UPDATED_VERSION_TEXT": "updated to version",
    "EVENTS_SHOWN": "Events shown",
    "ALL_EVENTS": "All Events",
    "NONE": "none",
    "UNINSTALLED_TEXT": "uninstalled",
    "INSTALLED_TEXT": "installed",
    "TOOLTIP_TEXT": "View the events list for all",
    "OUT": "Out",
    "IN": "In",
    "OLD": "Old",
    "NEW": "New",
    "RESET_ZOOM": "Reset Zoom",
    PERFORMANCE_CHART_TITLE_DEVICES_USAGE: `Utilisation of '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_DEVICES_USAGE_withFilter: `Utilisation of '{{ filter }}' $t(translation:DEVICE_other, lowercase) {{ period }}`,
    PERFORMANCE_CHART_TITLE_DATA_USAGE: `Data Usage of '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_REBOOTS: `Reboots of '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_LOW_POWER: `Low Power Events of '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_TIME_ERRORS: `Time Errors of '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_BATTERY_SWAPS: `Battery Swaps of '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_DROPS: `Drops of '{{ filter }}' {{ period }}`
  },
  deviceLocation: {
    "DEVICE_LOCATION": "Location",
    "DEVICE_LOCATION_DESCRIPTION": "Locate your device and its home location. Updated:",
    "MAP_KEY": "Map Key:",
    "THIS_DEVICE": "This Device",
    "DEVICE_ACCURACY_RADIUS": "Device Accuracy Radius",
    "HOME_LOCATION_RADIUS": "Home Location Radius",
    "LOCATION_UNKNOWN": "Location Unknown",
    "ACCURACY": "Accuracy",
    "SOURCE": "Source"
  },
  deviceProcesses: {
    "DEVICE_PROCESS": "Process",
    "DEVICE_PROCESS_other": "Processes",
    //Title
    "PROCESSES_TITLE": "Processes",
    "PROCESSES_SUBTITLE": "Select a process from the list below to view its history, usage, and events.",
    "PROCESSES_ON_DEVICE": "Processes on this device",
    //table headings
    "PROCESS_NAME": "Process Name",
    "PRODUCT_NAME": "Product Name",
    "PRODUCT_VERSION": "Product Version",
    "FILE_VERSION": "File Version",
    //footer statement
    "DEVICE_PROCESS_TOTAL": "process on this device",
    "DEVICE_PROCESS_TOTAL_other": "processes on this device",
    //overview labels
    "DETECTED_ON_DEVICE": "Detected running on this device",
    "DETECTED_ON_DEVICE_other": "Detected running on this device",
    "USED_PROCESSES": "Used in the past {{days}} days",
    "USED_PROCESSES_other": "Used in the past {{days}} days",
    //search
    "USED_TODAY": "Used today",
    "USED_TODAY_other": "Used today",
    "USAGE": "Usage",
    "CPU_USAGE": "CPU Usage",
    "CPU": "$t(translation:CPU)",
    "USAGE_TITLE_daily": "Daily Usage {{ timePeriod }}",
    "USAGE_TITLE_hourly": "Hourly Usage {{ timePeriod }}",
    "CPU_USAGE_TITLE_daily": "Daily CPU Usage {{ timePeriod }}",
    "CPU_USAGE_TITLE_hourly": "Hourly CPU Usage {{ timePeriod }}"
  },
  applications: {
    "SHARED_ID": "Shared User ID",
    "SHARED_ID_TOOLTIP_TEXT": "A shared user ID lets groups of related applications work together. Apps with a shared user ID will also share the same values for data usage and size.",
    "APPLICATION": "application",
    "APPLICATION_other": "applications"
  },
  deviceApplications: {
    "OVERVIEW_TITLE": "Applications Overview",
    "OVERVIEW_SUBTITLE": "Select an application from the list below to view its history, usage, and events.",
    "APPLICATION_ON_DEVICE": "Application on this device",
    "APPLICATION_ON_DEVICE_other": "Applications on this device",
    get APPLICATION_ON_DEVICE_OVERVIEW() { return this.APPLICATION_ON_DEVICE; },
    get APPLICATION_ON_DEVICE_OVERVIEW_other() { return this.APPLICATION_ON_DEVICE_other; },
    "DEVICE_APPLICATION_TOTAL": "application on this device",
    "DEVICE_APPLICATION_TOTAL_other": "applications on this device",
    "INSTALLED_APPLICATIONS": "Installed in the past 30 days",
    "INSTALLED_APPLICATIONS_other": "Installed in the past 30 days",
    "UPDATED_APPLICATIONS": "Updated in the past 30 days",
    "UPDATED_APPLICATIONS_other": "Updated in the past 30 days",
    "USED_APPLICATIONS": "Used in the past 14 days",
    "USED_APPLICATIONS_other": "Used in the past 14 days",
    "SHARED_ID": "$t(applications:SHARED_ID)",
    "SHARED_ID_TOOLTIP_TEXT": "$t(applications:SHARED_ID_TOOLTIP_TEXT)",
    "USED_TODAY": "Used today",
    "USED_TODAY_other": "Used today",
    "DAILY_USAGE_PAST_30DAYS": "Daily Usage over the past 30 days",
    "DATA_USAGE_TITLE_daily": "Daily Data Usage {{ timePeriod }}",
    "DATA_USAGE_TITLE_hourly": "Hourly Data Usage {{ timePeriod }}",
    "PACKAGE_NAME": "Package Name"
  },
  coreApplications: {
    "NAME": "Name",
    "SHARED_ID": "$t(applications:SHARED_ID)",
    "SHARED_ID_TOOLTIP_TEXT": "$t(applications:SHARED_ID_TOOLTIP_TEXT)",
    "VERSION_COUNT": "Versions",
    "INSTALLED_COUNT": "Instances",
    "CURRENT_VERSION_COUNT": "Current Installed Versions",
    "CURRENT_INSTALLED_COUNT": "Current Installed Instances",
    "LATEST_VERSION": "Latest Version",
    "LAST_USED": "$t(translation:LAST_USED)",
    "USED_COUNT": "Used (Devices)",
    "USED_COUNT_TOOLTIP_TEXT": "The number of devices that have used this application in the last 30 days.",
    "FIRST_INSTALLED": "First Installed",
    "MOBILE_DATA_USED": "$t(translation:MOBILE_DATA_USED)",
    "TOTAL_MINUTES_USAGE": "Total Minutes Usage",
    "WIFI_DATA_USED": "Wi-Fi Data Used",
    "AVERAGE_MINUTES_USED": "Average Minutes Usage Per Day",
    "VERSION_FILTER": " with version {{ version }} of this application",
    "GROUP_FILTER": "devices in Group {{ name }}",
    "ZONE_FILTER": "devices in Zone {{ name }}",
    "HOME_LOCATION_FILTER": "devices in Home Location {{ name }}",
    "NO_GROUP_FILTER": "devices with No Groups",
    "NO_HOME_LOCATION_FILTER": "devices with No Home Location",
    "SECTION_TITLE_OVERVIEW": `Application Overview for {{ filter }}`,
    "SECTION_TITLE_OVERVIEW_withVersion": `Application Overview for {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_DATA_USAGE": `Application data usage across {{ filter }}`,
    "SECTION_TITLE_DATA_USAGE_withVersion": `Application data usage across {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_DEVICE_USAGE": `Application utilization across {{ filter }}`,
    "SECTION_TITLE_DEVICE_USAGE_withVersion": `Application utilization across {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_MINUTES_USAGE": `Application minutes usage across {{ filter }}`,
    "SECTION_TITLE_MINUTES_USAGE_withVersion": `Application minutes usage across {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_APPLICATION_DEVICES": `{{ filter }} with this application`,
    "SECTION_TITLE_APPLICATION_DEVICES_withVersion": `{{ filter }}{{versionFilter}}`,
    "ALL_DEVICES": `All devices`,
    get ALL_DEVICES_lowercase() { return `${this.ALL_DEVICES.toLowerCase()}`; },
    "PERFORMANCE": "Performance",
    "DEVICES_USED": 'Devices Used',
    "MINUTES_USED": `Minutes Used`,
    "ALL_APPLICATION_VERSIONS": "$t(translation:ALL)",
    "ALL_APPLICATIONS": "All Applications",
    "USER_APPLICATIONS": "User Applications",
    "SYSTEM_APPLICATIONS": "System Applications",
    "APP_VERSION": "App Version",
    "APP_VERSION_filter": "App Version:",
    "APPLICATION_DEVICES_LIST_TITLE": "$t(translation:DEVICES_LIST_TITLE)",
    "APP_LAST_USED": "App Last Used",
    "MOBILE_DATA_FULL": "App $t(translation:MOBILE_DATA)",
    "WIFI_DATA_FULL": "App $t(translation:WIFI_DATA)",
    "WIFI_DATA_TOOLTIP_TEXT": "The Wi-Fi data used by this application on the device in the last 30 days.",
    "MOBILE_DATA_TOOLTIP_TEXT": "The mobile data used by this application on the device in the last 30 days.",
    "VERSIONS_TOOLTIP": "List of application versions seen during the selected time period",
    "SHARED_USER_ID_INFO": "Note that the data usage values shown here and in the Data Usage chart on this page are aggregate values for all of the applications and their versions using the shared user ID \"{{ sharedUserId }}\"",
    "SECTION_TITLE_SHARED_USER_ID": `Aggregate Data Usage for applications on {{ filter }} using the shared user ID "{{ sharedUserId }}"`
  },
  coreProcesses: {
    "PROCESS": "Process",
    "DEVICE_LIST": "Device List",
    "PROCESS_other": "Processes",
    "PROCESSES_TITLE": "Windows Processes",
    "PRODUCT_NAME": "Product Name",
    "PROCESS_NAME": "Process Name",
    "ACTIVE_DEVICES": "Active Devices",
    "VERSIONS": "Versions",
    "LATEST_PRODUCT_VERSION": "Latest Product Version",
    "LATEST_FILE_VERSION": "Latest File Version",
    "USED_DEVICES": "Used Devices",
    "SUBHEADER": "Processes seen running in the estate in the past 30 days",
    "LAST_USED": "Last Used",
    "ACTIVE_DEVICES_TOOLTIP_TEXT": "The number of devices where this process instance has been seen running in the last 30 days.",
    "USED_COUNT_TOOLTIP_TEXT": "The number of devices where this process instance has had some user interaction in the last 30 days.",
    "PERFORMANCE": "Performance",
    "ALL_PROCESS_VERSIONS": "$t(translation:ALL)",
    "DEVICES_USED": 'Devices Used',
    "ALL_DEVICES": `All devices`,
    get ALL_DEVICES_lowercase() { return `${this.ALL_DEVICES.toLowerCase()}`; },
    "GROUP_FILTER": "devices in Group {{ name }}",
    "ZONE_FILTER": "devices in Zone {{ name }}",
    "HOME_LOCATION_FILTER": "devices in Home Location {{ name }}",
    "NO_GROUP_FILTER": "devices with No Groups",
    "NO_HOME_LOCATION_FILTER": "devices with No Home Location",
    "VERSION_FILTER": " with version {{ version }} of this process",
    "SECTION_TITLE_CPU_USAGE": `Process CPU usage across {{ filter }}`,
    "SECTION_TITLE_CPU_USAGE_withVersion": `Process CPU usage across {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_USAGE": `Process usage across {{ filter }}`,
    "SECTION_TITLE_USAGE_withVersion": `Process usage across {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_UTILIZATION": `Process utilization across {{ filter }}`,
    "SECTION_TITLE_UTILIZATION_withVersion": `Process utilization across {{ filter }}{{versionFilter}}`,
    "DEVICE_LIST_TITLE": `{{filter}} with this process`,
    "DEVICE_LIST_TITLE_withVersion": `{{filter}}{{versionFilter}}`,
    "MINUTES_USED": `Minutes Used`,
    "CPU_MINUTES_USED": 'CPU Minutes Used',
    "VERSION_TOOLTIP": "List of Product Version / File Version combinations seen during the selected time period"
  },
  alerts: {
    "NO_ALERTS": "No issues currently detected on this device",
    "DEVICE_LOCATION_DISTANCE_STATUS_metric": "This device is over <1>{{threshold}} $t(distance:KILOMETRE_other)</1> away from its home location",
    "DEVICE_LOCATION_DISTANCE_STATUS_imperial": "This device is over <1>{{threshold}} $t(distance:MILE_other)</1> away from its home location",
    "DEVICE_LOCATION_TIME_STATUS": "This device has been away from its home location for over <1>{{threshold}} hours</1>",
    "NO_LOCATION_ALERT": "No location problems detected with this device",
    "NO_UTILISATION_ALERT": "No utilisation problems detected with this device",
    "NO_USER_EXPERIENCE_ALERT": "No user experience problems detected with this device",
    "DEVICE_UTILISATION_OUT_OF_CONTACT_HOUR_ZERO_STATUS": "This device has been <1>out of contact</1> for less than an hour",
    "DEVICE_UTILISATION_OUT_OF_CONTACT_HOUR_STATUS": "This device has been <1>out of contact</1> for {{hoursSpentOutOfContact}} hour",
    "DEVICE_UTILISATION_OUT_OF_CONTACT_HOUR_STATUS_other": "This device has been <1>out of contact</1> for {{hoursSpentOutOfContact}} hours",
    "DEVICE_UTILISATION_OUT_OF_CONTACT_DAY_STATUS": "This device has been <1>out of contact</1> since {{lastUpdated}} (<5>{{daysSpentOutOfContact}} day</5>)",
    "DEVICE_UTILISATION_OUT_OF_CONTACT_DAY_STATUS_other": "This device has been <1>out of contact</1> since {{lastUpdated}} (<5>{{daysSpentOutOfContact}} days</5>)",
    "DEVICE_IDLE_DAY_STATUS": "This device has been <1>idle</1> for <3>{{configDays}}</3> or more <5>days</5>",
    "HOURS": "hours",
    "DAYS": "{{thresholdPeriod}} days",
    "DEVICE_REBOOT_STATUS": "This device has been <1>rebooted {{deviceEvent}} time</1> during the past ",
    "DEVICE_REBOOT_STATUS_other": "This device has been <1>rebooted {{deviceEvent}} times</1> during the past ",
    "DEVICE_DROP_STATUS": "This device has been <1>dropped {{deviceEvent}} time</1> during the past ",
    "DEVICE_DROP_STATUS_other": "This device has been <1>dropped {{deviceEvent}} times</1> during the past ",
    "DEVICE_LOW_POWER_STATUS": "This device has had <1>{{deviceEvent}} low power event</1> during the past ",
    "DEVICE_LOW_POWER_STATUS_other": "This device has had <1>{{deviceEvent}} low power events</1> during the past ",
    "TIME_ERROR_STATUS": "This device is in time error",
    "AVG_DISCHARGE_STATUS": "This device has experienced a <1>high average discharge of {{discharge}}%</1> over the last 30 days",
    "THIS_DEVICE_IS": "This device is",
    "ON_A_KNOWN_NETWORK": "on a known network",
    "ON_AN_UNKNOWN_NETWORK": "on an unknown network",
    "AT_ACCESS_POINT_LOCATION_NAME": "at",
    "NOT_BELONGING_TO_HOME_LOCATION": "not belonging to its home location"
  },
  agreements: {
    EULA_FULL: 'End User License Agreement',
    THE_EULA_FULL: 'the End User License Agreement',
    EULA_ABBREV: 'EULA',
    THE_EULA_ABBREV: 'the EULA',
    DPA_FULL: 'Data Processing Agreement',
    THE_DPA_FULL: 'the Data Processing Agreement',
    DPA_ABBREV: 'DPA',
    THE_DPA_ABBREV: 'the DPA',
    BOTH_EULA_DPA: 'both the EULA and DPA',
    OPEN_IN_NEW_TAB: 'Open {{v}} in a new tab',
    AGREE_TERMS: 'I agree to {{v}} terms and conditions',
    AGREEMENT_DESCRIPTION: 'By accepting this agreement you warrant that you have the authority to accept and bind your organisation to its content. Clicking the Accept button is considered equivalent to your signature on this agreement.',
    AGREEMENT_DESCRIPTION_other: 'By accepting these agreements you warrant that you have the authority to accept and bind your organisation to their content. Clicking the Accept button is considered equivalent to your signature on these agreements.',
    AGREEMENTS_READ: 'Please <1>ensure you have carefully read</1> {{v}} before accepting:',
    RETURN_LOG_IN: 'Return to Log In',
    ACCEPT: 'Accept',
    ERROR: '$t(error:SOMETHING_WRONG_RETRY)',
    EULA_AND_DPA_DESCRIPTION: 'Your {{v}}'
  },
  enrollment: {
    SECTION_TITLE: 'Enrolment',
    SECTION_SUBTITLE: 'Enrolment',
    TOKEN_HEADER: 'Elemez Token',
    TOKEN_SUBHEADER: 'The token is required if you are distributing and installing the Elemez agent using an MDM or EMM.',
    NO_TOKEN_TEXT: 'No token available',
    TOKEN_COPY_SUCCESS: "Token copied",
    TOKEN_COPY_FAIL: "Copy failed"
  },
  batteryEssentials: {
    NO_SELECTION_HEADER: "Welcome to {{title}}",
    BATTERY_NOT_FOUND: "Battery Not Found",
    BATTERY_NOT_FOUND_BODY: "The battery you are looking for has not been found. Please select a battery from the list below.",
    NO_SELECTION_BODY_UPPER: "Select a battery from the list below to view more information about its health and charge level history.",
    NO_SELECTION_BODY_LOWER: "Each battery record is accompanied by information on the last known device it was reported to be in.",
    NO_BATTERY_SELECTED: "No Battery Selected",
    BATTERY_STATUS_HEADER: "Battery Status",
    BATTERY_IDENTIFIERS: "Battery Identifiers",
    PART_NUMBER: "Part Number",
    MANUFACTURE_DATE: "Manufacture Date",
    HEALTH: "Health",
    CAPACITY: "Capacity (mAh)",
    CYCLE_COUNT: "Cycle Count",
    AGE: "Age",
    LAST_KNOWN_DEVICE: "Last Known Device",
    GROUPS_FILTER_HEADER: "Groups Filter",
    UNDER_WARRANTY_FILTER_HEADER: "Warranty Filter",
    UNDER_WARRANTY: "Under Warranty",
    CHARGE_LEVEL_STATEMENT: "Charge level over the {{timePeriod, lowercase}}",
    TIME_PERIOD: "Time Period:",
    BATTERY_SERIAL_NUMBER: "Battery Serial No.",
    BATTERY_PART_NUMBER: "Battery Part No.",
    VIEW_IN_DEVICES: "View in Devices",
    All_BATTERIES_TITLE: "All Batteries",
    GOOD_BATTERIES_TITLE: "Good Batteries",
    PROBLEM_BATTERIES_TITLE: "Batteries with a problem",
    WARNING_BATTERIES_TITLE: "Batteries with a warning",
    BATTERY_INFORMATION: "Battery Information"
  },
  batteryEssentialsDeviceView: {
    NO_SELECTION_BODY: "Select a device from the list below to view more information about its status and average discharge.",
    NO_DEVICE_SELECTED: "No Device Selected",
    DEVICE_NOT_FOUND: "Device Not Found",
    DEVICE_NOT_FOUND_BODY: "The device you are looking for has not been found. Please select a device from the list below.",
    GROUPS_FILTER_HEADER: "Groups Filter",
    DEVICE_INFORMATION: "Device Information",
    HIGH_DISCHARGE: "High Discharge",
    LAST_KNOWN_BATTERY: "Last Known Battery",
    LAST_KNOWN_BATTERIES: "Last Known Batteries",
    VIEW_IN_BATTERIES: "View in Batteries",
    AVERAGE_HOURLY_DISCHARGE: "Avg Hourly Discharge",
    DEVICE_HOURLY_DISCHARGE_STATEMENT: "Device average hourly discharge per day",
    DEVICE_DISCHARGE: "Discharge",
    AVERAGE_DISCHARGE: "Avg Discharge",
    All_DEVICES_TITLE: "All Devices",
    GOOD_DEVICES_TITLE: "Good Devices",
    WARNING_DEVICES_TITLE: "Devices with a warning",
    PROBLEM_DEVICES_TITLE: "Devices with a problem"
  },
  estateOverview: {
    DEVICES_UNAFFECTED: "Devices Unaffected",
    ALERTING_PERCENTAGE: "Alerting Percentage",
    PERCENTAGE_AFFECTED: "Percentage Affected",
    DEVICES_AFFECTED: "Devices Affected",
    PROBLEM_DEVICES: "Problem Devices",
    WARNING_DEVICES: "Warning Devices",
    GOOD_DEVICES: "Good Devices",
    TOTAL_DEVICES: "Total Devices",
    DEFAULT_FILTER: "Any alert type",
    ACCESS_POINT_FILTER: "Access point not belonging to their home location",
    TIME_ERROR_FILTER: "In time error",
    FILTER_TYPES: "Alert Type Filters",
    SUBTITLE: 'Groups & Home Locations with "{{ text }}" alerts',
    NO_ALERTS: "No groups or home locations with alerts",
    HOME_LOCATIONS_AND_GROUPS: "home locations and groups",
    get NO_GROUP() { return `No ${ukTranslations.translation.GROUP.toLowerCase()}`; },
    HOME_LOCATION_GROUP: "$t(translation:GROUP) / $t(translation:HOME_LOCATION)",
    TYPE: "Type",
    FILTER_TOGGLE_SUBTITLE: "Show alert filters",
  },
  alertOverview: {
    TITLE: 'Alerts ({{ alertCount }})',
    NO_ALERTS: 'No alerts in your estate',
    REBOOT_STATUS: 'have a high number of reboots',
    TIME_ERR_STATUS: 'are currently in time error',
    AVR_DISCHARGE_STATUS: 'have a high average hourly discharge',
    LOW_POWER_STATUS: 'have a high number of low power events',
    LOCATION_DISTANCE_STATUS: 'are far away from their home location',
    LOCATION_TIME_STATUS: 'have been away from their home location for a while',
    DROP_STATUS: 'have a high number of drops',
    LOCATION_ACCESS_POINT_STATUS: 'are on an access point not belonging to their home location',
    IDLE_STATUS: 'have been idle for a while',
    OUT_OF_CONTACT_STATUS: 'have been out of contact for a while',
    OF_DEVICES: 'of devices'
  },
  error: {
    SOMETHING_WRONG: 'Sorry, something went wrong.',
    SOMETHING_WRONG_RETRY: '$t(error:SOMETHING_WRONG) Please try again.',
    RELOAD_PAGE: 'Reload the Page'
  },
  maps: {
    VERY_POOR_NO_SERVICE_TAB: 'Very Poor/No Service',
    VERY_POOR_NO_SERVICE_HEADING: 'Areas of Very Poor Signal or No Service',
    VERY_POOR_NO_SERVICE_SUBHEADING: 'Identify areas where devices have been more likely to receive a very poor signal or no service',
    VERY_POOR_NO_SERVICE_INTENSITY: 'Very Poor/No Service Readings',
    LOCATION: 'Location',
    TOTAL_COUNT: 'Total Readings',
    CONFIG_GRID_SHOWN: 'Grid Shown',
    CONFIG_GRAYSCALE: 'Grayscale',
    CONFIG_LEGEND: 'Legend',
    CONFIG_TOOLTIP: 'Tooltip on Hover',
    CONFIG_DISCRETE: 'Show Bands',
    NO_SERVICE_HEADING: 'Areas of No Service',
    NO_SERVICE_SUBHEADING: 'Identify areas where devices have been more likely to have no service',
    NO_SERVICE_TAB: 'No Service',
    NO_SERVICE_INTENSITY: 'No Service Readings',
    COVERAGE_TAB: 'Coverage',
    COVERAGE_HEADING: 'Areas of Coverage',
    COVERAGE_SUBHEADING: 'Network coverage by SIM provider and network type',
    COVERAGE_INTENSITY: 'Coverage Readings',
    SIM_PROVIDERS: 'SIM Providers',
    ALL_SIM_PROVIDERS: 'All SIM Providers',
    DEVICES_HEADING: 'Devices',
    LOCATION_SUBHEADING: '$t(maps:LOCATION)',
    LOCATION_TAB: '$t(maps:LOCATION)',
    CLUSTER_PROMPT: 'Select a cluster to view devices in that cluster.',
    LOCATION_COLLECTION_INFO: `<0>{{ totalCount }}</0> devices match the filter, <2>{{ withLocationCount }}</2> have location information and can be displayed on the map`,
  },
  settings: {
    SAVE_CHANGES: '$t(translation:SAVE_CHANGES)',
    CLEAR_CHANGES: 'Clear Changes',
    SAVE_SUCCESS: 'Successfully Updated',
    SAVE_FAIL: 'Operation failed',
    LAST_DAYS: '$t(timeState:IN_THE_LAST_M_other)',
    LAST_HOURS: '$t(timeState:IN_THE_LAST_F_other)',
    REBOOTS_TITLE: 'Reboots - Global Threshold',
    REBOOTS_MESSAGE: 'reboot',
    REBOOTS_MESSAGE_other: 'reboots',
    REBOOTS_POPOVER: 'A reboot alert is generated when the number of device reboots equals or exceeds the threshold within the specified time period',
    OUT_OF_CONTACT_TITLE: 'Utilisation - Out of Contact Threshold',
    OUT_OF_CONTACT_MESSAGE: 'The device has been out of contact for',
    OUT_OF_CONTACT_POPOVER: 'Alert generated when the number of days the device has been out of contact for exceeds the threshold',
    OUT_OF_CONTACT_UNIT: 'day',
    OUT_OF_CONTACT_UNIT_other: 'days',
    USAGE_TITLE: 'Utilisation - Idle Threshold',
    USAGE_MESSAGE: 'The device has been idle for',
    USAGE_POPOVER: 'Alert generated when the number of days the device is idle for equals or exceeds the threshold',
    USAGE_UNIT: 'day',
    USAGE_UNIT_other: 'days',
    LOW_POWER_EVENTS_TITLE: 'Low Power Events - Global Threshold',
    LOW_POWER_EVENTS_MESSAGE: 'low power event',
    LOW_POWER_EVENTS_MESSAGE_other: 'low power events',
    LOW_POWER_EVENTS_POPOVER: 'A low power alert is generated when the number of low power events equals or exceeds the threshold within the specified time period',
    DROPS_TITLE: 'Drops - Global Threshold',
    DROPS_MESSAGE: 'drop',
    DROPS_MESSAGE_other: 'drops',
    DROPS_POPOVER: 'A drop alert is generated when the number of device drops equals or exceeds the threshold within the specified time period',
    TIME_TITLE: 'Location - Check In Global Threshold',
    TIME_MESSAGE: 'The device has not been detected in its home location for',
    TIME_POPOVER: 'Alert generated when the time the device has been away from its home location equals or exceeds the threshold',
    TIME_UNIT: '$t(timeState:HOUR)',
    TIME_UNIT_other: '$t(timeState:HOUR_other)',
    DISTANCE_TITLE: 'Location - Distance Global Threshold',
    DISTANCE_MESSAGE: 'The device is farther than',
    DISTANCE_POPOVER: `Alert generated when the device's distance from its home location equals or exceeds the threshold`,
    DISTANCE_UNIT: `$t(distance:LONG, { "context": "{{context}}", "count": {{count}} }) away from its home location`,
    BATTERY_WARNING_HEADER: 'Warning Threshold',
    BATTERY_PROBLEM_HEADER: 'Problem Threshold',
    BATTERY_HEALTH_TITLE: 'Health Thresholds',
    BATTERY_HEALTH_POPOVER: `Alert generated when 'Health' percentage equals or falls below the threshold.`,
    BATTERY_HEALTH_WARNING_ERROR: 'Warning Threshold should be between 2 and 99 and more than the Problem Threshold.',
    BATTERY_HEALTH_PROBLEM_ERROR: 'Problem Threshold should be between 1 and 98 and less than the Warning Threshold.',
    BATTERY_HEALTH_ROW_TITLE: 'All batteries reporting health',
    BATTERY_CYCLE_COUNT_TITLE: 'Cycle Count Thresholds',
    BATTERY_CYCLE_COUNT_POPOVER: `Alert generated when the cycle count equals or exceeds each threshold. Only applies to batteries not reporting 'Health'.`,
    BATTERY_CYCLE_COUNT_WARNING_ERROR: 'Warning Threshold should be between 1 and 9998 and less than the Problem Threshold.',
    BATTERY_CYCLE_COUNT_PROBLEM_ERROR: 'Problem Threshold should be between 2 and 9999 and more than the Warning Threshold.',
    BATTERY_CYCLE_COUNT_MANUFACTURER: '$t(translation:MANUFACTURER)',
    BATTERY_CYCLE_COUNT_PART: '$t(batteryEssentials:PART_NUMBER)',
    AVG_DISCHARGE_TITLE: 'Device Average Discharge Threshold',
    AVG_DISCHARGE_MESSAGE: 'The average hourly discharge is over',
    AVG_DISCHARGE_POPOVER: 'Alert generated when average discharge equals or exceeds the threshold.',
    AVG_DISCHARGE_UNIT: '%',
    PERIOD_IN_DAYS: 'Period (in days)',
    THRESHOLD_WARNING: 'Warning Threshold',
    THRESHOLD_PROBLEM: 'Problem Threshold',
    THRESHOLD_PERIOD_ERROR: 'Period Threshold should be between {{ min }} and {{ max }}',
    THRESHOLD_WARNING_ERROR: 'Warning Threshold should be between {{ min }} and {{ max }} and less than the Problem Threshold',
    THRESHOLD_PROBLEM_ERROR: 'Problem Threshold should be between {{ min }} and {{ max }} and greater than the Warning Threshold',
    TAB_ALARMS: 'Alarms',
    TAB_ALERTS: 'Alerts',
    TAB_BATTERY: 'Battery',
    TAB_LOCATION: 'Location',
    TAB_UTILISATION: 'Utilisation',
    TAB_UX: 'User Experience',
    MAIN_TITLE: '$t(translation:SETTINGS)',
    ALERT_ACTIVE: "Alert Active",
    BATTERY_ALERTS: "Battery Alerts",
    HOME_LOCATION_WARNING_ALERT_TOOLTIP_TEXT: "Warning alert generated when a device is connected to a known network that does not belong to its assigned home location.",
    HOME_LOCATION_PROBLEM_ALERT_TOOLTIP_TEXT: "Problem alert generated when a device is connected to an unknown network.",
    HOME_LOCATION_NETWORK_ALERT: "Home Location Network Alert",
    HOME_LOCATION_NETWORK_ALERT_WARNING_TEXT: "The device is connected to a known network, not belonging to its own home location.",
    HOME_LOCATION_NETWORK_ALERT_PROBLEM_TEXT: "The device is connected to an unknown network.",
    PROBLEM_ALERT: "Problem Alert:",
    WARNING_ALERT: "Warning Alert:",
    DAILY_RUN_TIME: "Daily run time:",
    ANOMALY_DETECTION: "Anomaly Detection",
    LOCATION_INFORMATION_TITLE: "Location Information Collection and Reporting",
    LOCATION_WINDOWS_ENABLED: "Enabled for Windows Devices",
    LOCATION_ANDROID_ENABLED: "Enabled for Android Devices",
    DROPS_ENABLED_TITLE: "Drop Detection",
    DROPS_ANDROID_ENABLED: "Enabled for Android Devices",
    ENABLE_ANOMALY_DETECTION: "Enable Anomaly Detection",
    SELECT_HOUR_LABEL: "Select hour",
    SELECT_AM_OR_PM_LABEL: "Select am or pm",
    SELECT_TIME_ZONE_LABEL: "Select time zone",
    PLATFORM_DEVICE_IDENTIFIER_TITLE: "Primary Device Identifier",
    PLATFORM_DEVICE_IDENTIFIER_INFORMATION: "Select the primary value by which you identify devices in your estate.",
    PLATFORM: "Platform",
    LOCATION_INFO_QUESTION: "Location Settings Confirmation",
    LOCATION_INFO_TURNING_OFF: 'When disabling location data collection and reporting, historical location data that has already been collected will not be deleted automatically. Please contact B2M Support if you require historical location data to be deleted.',
    LOCATION_INFO_TURNING_ON: 'By enabling the collection and reporting of device location data you are consenting to the processing of location data for devices in your world. Please ensure that you are authorized to provide this consent on behalf of your organization and that the recording of device location data is legally permissible in the jurisdictions and devices covered under this service.  Also note that, if you disable location data collection at a future time, historical location data that has already been collected will not be deleted automatically.  Please contact B2M Support if you require historical location data to be deleted.',
    MOBILE_SIGNAL_REPORTING_TITLE: "Mobile Signal Levels Collection and Reporting",
    MOBILE_SIGNAL_REPORTING_ANDROID: "Enabled for Android Devices",
    MOBILE_SIGNAL_REPORTING_WINDOWS: "Enabled for Windows Devices",
  },

  alarmThresholds: {
    DEVICE_STATUS_ALARM: 'Any alert type',
    DEVICE_LOCATION_TIME_STATUS_ALARM: `$t(filters:DEVICE_LOCATION_TIME_STATUS_RED)`,
    DEVICE_TIME_ERROR_STATUS_ALARM: `$t(filters:DEVICE_TIME_ERROR_STATUS_RED)`,
    DEVICE_LOCATION_DISTANCE_STATUS_ALARM: `$t(filters:DEVICE_LOCATION_DISTANCE_STATUS_RED)`,
    AVERAGE_DISCHARGE_STATUS_ALARM: `$t(filters:AVERAGE_DISCHARGE_STATUS_YELLOW)`,
    DEVICE_USER_EXP_DROP_STATUS_ALARM: `$t(filters:DEVICE_USER_EXPERIENCE_DROP_STATUS_RED)`,
    DEVICE_USER_EXP_LOW_POWER_STATUS_ALARM: `$t(filters:DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_RED)`,
    DEVICE_USER_EXP_REBOOT_STATUS_ALARM: `$t(filters:DEVICE_USER_EXPERIENCE_REBOOT_STATUS_RED)`,
    DEVICE_IDLE_STATUS_ALARM: `$t(filters:DEVICE_UTILISATION_IDLE_STATUS_RED)`,
    DEVICE_OUT_OF_CONTACT_STATUS_ALARM: `$t(filters:DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_RED)`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS_ALARM: `$t(filters:DEVICE_LOCATION_ACCESS_POINT_STATUS_RED)`,
    HEADER: 'Alarms Configuration',
    HEADER_TOOLTIP: `Alarms are triggered when the percentage of alerting devices within a Group or Home Location equals or exceeds the configured threshold. <1>Current alarms can be viewed on the 'Overview' page within the 'Estate Alerts Breakdown' table.</1>`,
    MAJOR_ALARM_THRESHOLD: 'Major Alarm Threshold',
    CRITICAL_ALARM_THRESHOLD: 'Critical Alarm Threshold',
    MAJOR_ALARM_THRESHOLD_TOOLTIP: `Major alarms are the first level of alarm. They are displayed as yellow highlights within the 'Estate Alerts Breakdown' on the 'Overview' page.`,
    CRITICAL_ALARM_THRESHOLD_TOOLTIP: `Critical alarms are the most severe level of alarm. They are displayed as red highlights within the 'Estate Alerts Breakdown' on the 'Overview' page.`,
    ALERT_TYPE: 'Alert Type',
    ALARM_ACTIVE: 'Alarm Active',
    CRITICAL_THRESHOLD_VALIDATION_ERROR: 'Critical alarm threshold should be between 2 and {{ max }} and greater than the Major alarm threshold',
    MAJOR_THRESHOLD_VALIDATION_ERROR: 'Major alarm threshold should be between 1 and {{ max }} and less than the Critical alarm threshold'
  },

  userManagement: {
    USER_MANAGEMENT: "User Management",
    USER: "user",
    USER_other: "users",
    USERS_LIST_TITLE: "Users",
    EMAIL_ADDRESS: "Email Address",
    ROLE: "Role",
    CUSTOM_VIEW: "Custom View",
    CUSTOM_VIEW_LIST_TOOLTIP: "The devices a user can view can be limited by selecting the Home Location, Group or Zone that you want them to have access to during user edit or creation.",
    EDIT_CUSTOM_VIEW_TOOLTIP: "You can limit which devices this user can view by selecting the Home Location, Group or Zone that you want them to have access to.",
    CUSTOM_VIEW_LIST_ZONE: "Zone: {{ zone }}",
    CUSTOM_VIEW_LIST_GROUPS_HOME_LOCATIONS: "Home Location: <1>{{ homeLocation }}</1>, Group: <3>{{ group }}</3>",
    ALL_HOME_LOCATIONS: "All Home Locations",
    ALL_GROUPS: "All Groups",
    PRODUCT_ACCESS: "Product Access",
    WORLD_ACCESS: "World Access",
    VIEWER: "Viewer",
    EDITOR: "Editor",
    MANAGER: "Manager",
    ADMIN: "Administrator",
    ALL_DEVICES: "$t(filters:ALL_DEVICES)",
    ALL_WORLDS: "All Worlds",
    EDIT_USER: "Edit User",
    EDIT_USER_DESCRIPTION: "Edit the details and permissions of this user",
    ADD_USER: "New User",
    ADD_USER_DESCRIPTION: "Add a new user and configure their permissions.",
    EDIT_DENIED_HEADER: "Cannot edit selected user",
    DELETE_DENIED_HEADER: "Cannot delete selected user",
    DELETE_DENIED_HEADER_other: "Cannot delete selected users",
    EDIT_DENIED_SUBHEADER: "It is not possible to edit your own user account.",
    DELETE_DENIED_SUBHEADER: "It is not possible to delete your own user account.",
    TOOLTIP_VIEWER_TEXT: "the user can view all estate assets only.",
    TOOLTIP_EDITOR_TEXT: "the user can view all estate assets, edit the group membership, home location and asset tag for a device, remove a device from the estate and remove a battery from the estate.",
    TOOLTIP_MANAGER_TEXT: "the user has the same capabilities as the Editor role and can also configure world alert and alarms settings and manage the home location table.",
    TOOLTIP_ADMIN_TEXT: "the user has the same capabilities as the Manager role and can also create, edit and remove user accounts.",
    TOOLTIP_WORLD_ACCESS: "Non-Admin users can be given access to more than one world if required.  Admin users always have access to all worlds.",
    EMAIL_EXISTS_ERROR: "A user with this email address already exists.",
    INVALID_EMAIL_ERROR: "Please enter valid email address.",
    RETURN_TO_LIST: "Return to user list",
    USER_NOT_FOUND: "User does not exist",
  },

  filters: {
    SELECT: 'Select',
    SELECT_HOME_LOCATION: '$t(filters:SELECT) $t(translation:HOME_LOCATION)',
    SELECT_GROUP: '$t(filters:SELECT) $t(translation:GROUP)',
    SELECT_ZONE: '$t(filters:SELECT) $t(zones:ZONE)',
    ALERT_TYPES_FILTER: `Alert Types`,
    ALERT_TYPES_FILTER_other: `$t(filters:ALERT_TYPES_FILTER)`,
    ALERT_LEVEL_COL: `Alert Level`,
    AVERAGE_DISCHARGE_STATUS_GROUP: `Average Discharge`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS_GROUP: `Network`,
    DEVICE_LOCATION_DISTANCE_STATUS_GROUP: `$t(translation:LOCATION) (Distance)`,
    DEVICE_LOCATION_TIME_STATUS_GROUP: `$t(translation:LOCATION) (Time)`,
    DEVICE_TIME_ERROR_STATUS_GROUP: `$t(editEvents:TIME_ERROR)`,
    DEVICE_USER_EXPERIENCE_DROP_STATUS_GROUP: `$t(performance:DROPS)`,
    DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_GROUP: `$t(editEvents:DEVICE_LOW_POWER)`,
    DEVICE_USER_EXPERIENCE_REBOOT_STATUS_GROUP: `$t(performance:REBOOTS)`,
    DEVICE_UTILISATION_IDLE_STATUS_GROUP: `$t(translation:IDLE)`,
    DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_GROUP: `Out of Contact`,
    AVERAGE_DISCHARGE_STATUS_RED: `High average hourly discharge`,
    AVERAGE_DISCHARGE_STATUS_YELLOW: `$t(filters:AVERAGE_DISCHARGE_STATUS_RED)`,
    AVERAGE_DISCHARGE_STATUS_GREEN: `No average hourly discharge alert`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS_RED: `On an unknown network`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS_YELLOW: `On a known network not belonging to home location`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS_GREEN: `No network alert`,
    DEVICE_LOCATION_DISTANCE_STATUS_RED: `Far away from home location`,
    DEVICE_LOCATION_DISTANCE_STATUS_YELLOW: `$t(filters:DEVICE_LOCATION_DISTANCE_STATUS_RED)`,
    DEVICE_LOCATION_DISTANCE_STATUS_GREEN: `No location (distance) alert`,
    DEVICE_LOCATION_TIME_STATUS_RED: `Away from home location for a while`,
    DEVICE_LOCATION_TIME_STATUS_YELLOW: `$t(filters:DEVICE_LOCATION_TIME_STATUS_RED)`,
    DEVICE_LOCATION_TIME_STATUS_GREEN: `No location (time) alert`,
    DEVICE_TIME_ERROR_STATUS_RED: `Currently in time error`,
    DEVICE_TIME_ERROR_STATUS_GREEN: `No time error alert`,
    DEVICE_USER_EXPERIENCE_DROP_STATUS_RED: `High number of drops`,
    DEVICE_USER_EXPERIENCE_DROP_STATUS_YELLOW: `$t(filters:DEVICE_USER_EXPERIENCE_DROP_STATUS_RED)`,
    DEVICE_USER_EXPERIENCE_DROP_STATUS_GREEN: `No drops alert`,
    DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_RED: `High number of low power events`,
    DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_YELLOW: `$t(filters:DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_RED)`,
    DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_GREEN: `No low power alerts`,
    DEVICE_USER_EXPERIENCE_REBOOT_STATUS_RED: `High number of reboots`,
    DEVICE_USER_EXPERIENCE_REBOOT_STATUS_YELLOW: `$t(filters:DEVICE_USER_EXPERIENCE_REBOOT_STATUS_RED)`,
    DEVICE_USER_EXPERIENCE_REBOOT_STATUS_GREEN: `No reboots alert`,
    DEVICE_UTILISATION_IDLE_STATUS_RED: `Idle for a while`,
    DEVICE_UTILISATION_IDLE_STATUS_YELLOW: `$t(filters:DEVICE_UTILISATION_IDLE_STATUS_RED)`,
    DEVICE_UTILISATION_IDLE_STATUS_GREEN: `No idle alert`,
    DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_RED: `Out of contact for a while`,
    DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_YELLOW: `$t(filters:DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_RED)`,
    DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_GREEN: `No out of contact alert`,
    ALL_DEVICES: `All Devices`,
    FILTER_BY: `Filter by:`,
    TIME_PERIOD: `Time Period:`,
    SELECT_TIME_PERIOD: `Time Period`,
  },

  homeLocations: {
    HOME_LOCATION_MANAGEMENT: "$t(translation:HOME_LOCATION) Management",
    UPDATE_TITLE: "Edit Home Location",
    CREATE_TITLE: "New Home Location",
    DELETE_TITLE: "Delete Home Location",
    DELETE_TITLE_other: "Delete Home Locations",
    UPDATE_TITLE_TOOLTIP: "Edit the details of your home location. Changes to the latitude, longitude and radius may affect current location based devices alerts.",
    CREATE_TITLE_TOOLTIP: "Create a geographical home location for your devices that can be used when configuring location based device alerts.",
    DETAILS_TITLE: "Details",
    THRESHOLDS_TOOLTIP: "Alert generated when the device's distance from its home location equals or exceeds the threshold.",
    THRESHOLDS_TITLE: "Distance Alert Thresholds",
    GLOBAL_THRESHOLDS: "Use global thresholds",
    CUSTOM_THRESHOLDS: "Custom thresholds",
    LATITUDE: "Latitude",
    LONGITUDE: "Longitude",
    ADDRESS_LINE: "Address Line {{number}}",
    LOCALITY: "Locality",
    POSTCODE: "ZIP / Postcode",
    COUNTRY: "Country",
    RADIUS: "Radius",
    RADIUS_WITH_UNIT: `Radius ($t(distance:SHORT_{{context}}_other))`,
    WARNING_THRESHOLD: "Warning Threshold",
    PROBLEM_THRESHOLD: "Problem Threshold",
    WARNING_THRESHOLD_WITH_UNIT: `Warning Threshold ($t(distance:LONG_ABBREVIATION_{{context}}))`,
    PROBLEM_THRESHOLD_WITH_UNIT: `Problem Threshold ($t(distance:LONG_ABBREVIATION_{{context}}))`,
    GLOBAL: "Global",
    LATITUDE_VALIDATION_ERROR_RANGE: "Latitude must be between -90 and 90",
    LONGITUDE_VALIDATION_ERROR_RANGE: "Longitude must be between -180 and 180",
    RADIUS_VALIDATION_ERROR_RANGE: "Radius must be greater than 0",
    WARNING_THRESHOLD_VALIDATION_ERROR_RANGE: "Warning Threshold must be greater than or equal to 0.001",
    WARNING_THRESHOLD_VALIDATION_ERROR_RELATIVE: "Warning Threshold must be less than the Problem Threshold",
    WARNING_THRESHOLD_VALIDATION_ERROR_MAX: "Warning Threshold must be less than or equal to {{ max }}",
    PROBLEM_THRESHOLD_VALIDATION_ERROR_RANGE: "Problem Threshold must be greater than or equal to 0.002",
    PROBLEM_THRESHOLD_VALIDATION_ERROR_RELATIVE: "Problem Threshold must be greater than the Warning Threshold",
    PROBLEM_THRESHOLD_VALIDATION_ERROR_MAX: "Problem Threshold must be less than or equal to {{ max }}",
    ERROR_ALREADY_EXISTS: "A home location with that name already exists."
  },

  forms: {
    REQUIRED_FIELDS: `Required fields`
  },

  bulkActions: {
    BULK_ACTIONS: "Bulk Actions",
    HOME_LOCATION_CHANGE: "Home location change",
    SELECT_HOME_LOCATION: "Select home location",
    MOVE: "Move",
    MOVE_DEVICES_TEXT: "Move all devices in",
    MOVE_STATUS_TEXT: "Updating home locations. Please wait.",
    MOVE_CONFIRMATION_TEXT: "The home location of the <1>{{count}} device</1> in '{{from}}' will be changed to '{{to}}'.",
    MOVE_CONFIRMATION_TEXT_other: "The home location of all <1>{{count}} devices</1> in '{{from}}' will be changed to '{{to}}'.",
    MOVE_VALIDATION_ERR_TEXT: "Please select different home location.",
    MOVE_SUCCESS_TEXT: "Successfully moved devices to '{{location}}'.",
    MOVE_ERROR_TEXT: "An error occurred, some devices may not have been moved. Please try again."
  },

  zones: {
    MANAGE_ZONES: "Manage Zones",
    ZONE: "Zone",
    ZONE_other: "Zones",
    ZONES: "Zones",
    AND_GROUPS: `AND $t(translation:GROUP_other)`,
    AND_HOMELOCATIONS: 'AND $t(translation:HOME_LOCATION_other)',
    NEW_ZONE: "New Zone",
    EDIT_ZONE: "Edit Zone",
    ZONE_NAME: "Zone Name",
    GROUPS1_TAB_HEADER: "Select group(s) of devices to add to the zone.",
    GROUPS2_TAB_HEADER: "Add groups, to the zone, that the devices must ALSO belong to.",
    HOMELOCATIONS_TAB_HEADER: "Select home location(s) to add to the zone.",
    ZONE_TAB_DESCRIPTION: "Please assign at least one group or one home location to create this zone.",
    ZONE_OPTIONS_TEXT_GROUPS1: "Any device that is in the group(s):",
    ZONE_OPTIONS_TEXT_GROUPS2: "AND that is also in the group(s):",
    ZONE_OPTIONS_TEXT_HOMELOCATIONS: "AND that is also in the home location(s):",
    ZONE_OPTIONS_TEXT_HOMELOCATIONS_without_groups: 'Any device that is in the home location(s):',
    ZONE_SUMMARY: "Zone Summary",
    NAME_VALIDATION_ERROR: "Invalid name, please use alphanumeric only without spaces.",
    NAME_EXISTS_ERROR: "Zone with that name already exists.Please enter a different name.",
    SAVE_FAILED_ERROR: "The action failed for this zone, please try again.",
    OPTION_SELECTION_ERROR: "Selected options should not exceed more than 20.",
    EDIT_ZONE_NAVIGATION_TEXT: "Select a Zone from the list to edit",
    SUBHEADER_SINGLE: "‘{{ zone }}’ will be deleted.",
    SUBHEADER: "{{ count }} zone will be deleted.",
    SUBHEADER_other: "{{ count }} zones will be deleted.",
    MESSAGE: "You will no longer be able assign groups or home locations to this zone.",
    MESSAGE_other: "You will no longer be able assign groups or home locations to these zones.",
    CONFIRM_DELETE: "Yes, delete this zone",
    CONFIRM_DELETE_other: "Yes, delete these zones",
    FAILURE_HEADER: "Unable to delete zone",
    FAILURE_HEADER_other: "Unable to delete zones",
    FAILURE_HEADER_USERS: "The following zone cannot be deleted because it has users assigned to it:",
    FAILURE_HEADER_USERS_other: "The following zones cannot be deleted because they have users assigned to them:",
    FAILURE_FOOTER_USERS: "You can change the zones of these users individually.",
    ZONE_NOT_FOUND: "Zone does not exist"
  },

  deviceNetwork: {
    NETWORK: 'Network',
    SIGNAL_TAB_TITLE: 'Signal',
    '2G': '2G',
    '3G': '3G',
    '4G': '4G',
    '5G': '5G',
    SIGNAL_LEVEL: 'Signal Level',
    CHANGE_SIGNAL_LEVEL: 'Change signal level',
    SIGNAL: 'Signal',
    'LAT/LONG': 'Lat/Long',
    OPERATOR: 'Operator',
    VERY_POOR: 'Very Poor',
    POOR: 'Poor',
    MODERATE: 'Moderate',
    FAIR: 'Fair',
    GOOD: 'Good',
    GREAT: 'Great',
    BAR_COUNT: '{{ count }} bar',
    BAR_COUNT_other: '{{ count }} bars',
    NO_SERVICE: 'No Service',
    EDIT_EVENTS_MODAL_HEADER: 'Edit the event types displayed on the signal level.'
  },

  deviceCoverage: {
    DEVICE_COVERAGE_TAB_TITLE: 'Service'
  },

  aboutPopup: {
    CONTENT_HEADER: 'Elemez from B2M Solutions',
    CONTENT_HEADER_PANASONIC: 'Panasonic Smart Essentials is powered by Elemez from B2M Solutions',
    COPYRIGHT: 'Copyright \u00A9 2015 - {{ currentYear }} Biz2mobile Ltd. All Rights Reserved.',
    HEADER: "About Elemez",
    HEADER_PANASONIC: "About Panasonic Smart Essentials",
    LICENSE_HEADER: "Open Source Software"
  }

};

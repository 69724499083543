import * as React from 'react';
import { ZeroSignalTooltipContent } from './zeroSignal';
import { NoServiceTooltipContent } from './noService';
import { IntensityMap } from './shared/index';
import { getNoServiceIntensity, getZeroSignalLevelIntensity } from '../../../services/core/maps';

export enum MapData {
  poorNoSignal = 'VERY_POOR_NO_SERVICE',
  noService = 'NO_SERVICE',
}

type DeviceCoverageProps = {
  deviceId: string,
  mapData: MapData,
  timePeriod: number
};

export const DeviceCoverageMap: React.FC<DeviceCoverageProps> = ({ deviceId, mapData, timePeriod }) => {
  const fetchNoServiceMapData = React.useCallback((boundingLatLong: string, geotilePrecision: number) => {
    return getNoServiceIntensity({
      boundingLatLong,
      geotilePrecision,
      deviceId,
      timePeriod,
    });
  }, [deviceId, timePeriod]);

  const fetchZeroSignalMapData = React.useCallback((boundingLatLong: string, geotilePrecision: number) => {
    return getZeroSignalLevelIntensity({
      boundingLatLong,
      geotilePrecision,
      deviceId,
      timePeriod,
    });
  }, [deviceId, timePeriod]);

  if (mapData === MapData.noService) {
    return <IntensityMap
      deviceId={deviceId}
      fetchMapData={fetchNoServiceMapData}
      TooltipContent={NoServiceTooltipContent}
    />;
  } else {
    return <IntensityMap
      deviceId={deviceId}
      fetchMapData={fetchZeroSignalMapData}
      TooltipContent={ZeroSignalTooltipContent}
    />;
  }
};

import { ISortResult } from "services/shared/common";
import { DataPoint } from "../../components/maps/intensityMaps/shared/types";
import { getTimePeriodParams } from "../../lib/dateParams";
import { RequestInitWithRetry, request } from "../../lib/request";

export interface IZeroSignalLevelParams {
  groups?: string[],
  homeLocations?: string[],
  deviceId?: string,
  zoneId?: string,
  boundingLatLong: string,
  geotilePrecision?: number,
  timePeriod: number,
  operatorName?: string
}

export interface IZeroSignalDataPoint {
  geotile?: string,
  totalCount: number | null,
  zeroSignalCount: number | null,
  zeroSignalPercentage: number | null
}

export type ZeroSignalLevelApiResponse = IZeroSignalDataPoint[];

export type ZeroSignalLevelResponse = Array<DataPoint & { totalCount: number, zeroSignalCount: number }>;

export function getZeroSignalLevelIntensity({ timePeriod, ...params }: IZeroSignalLevelParams) {
  return async (options: RequestInitWithRetry): Promise<ZeroSignalLevelResponse> => {
    const { from, to } = getTimePeriodParams(timePeriod);

    const input = {
      path: `/api/core/maps/zeroSignalLevelIntensity`,
      body: {
        ...params,
        from,
        to
      }
    };

    const output = await request<ZeroSignalLevelApiResponse>(input, options);

    return output.map(dataPoint => {
      const { zeroSignalPercentage: percentage, ...rest } = dataPoint;
      return { percentage, ...rest };
    });
  };
}

export interface ISignalLevelGeoBoundsParams {
  groups?: string[],
  homeLocations?: string[],
  deviceId?: string,
  zoneId?: string
}

export interface IGeoBounds {
  top: number,
  left: number,
  bottom: number,
  right: number
}

export type GeoBoundsResponse = {
  geoBounds: IGeoBounds
} | null;

export function getSignalLevelGeoBounds(params: ISignalLevelGeoBoundsParams) {
  return async (options: RequestInitWithRetry): Promise<GeoBoundsResponse> => {
    const input = {
      path: `/api/core/maps/signalLevelGeoBounds`,
      body: {
        ...params
      }
    };

    return request<GeoBoundsResponse>(input, options);
  };
}

export interface INoServiceParams {
  groups?: string[],
  homeLocations?: string[],
  deviceId?: string,
  zoneId?: string,
  boundingLatLong: string,
  geotilePrecision?: number,
  timePeriod: number,
  operatorName?: string
}

interface INoServiceDataPoint {
  geotile?: string,
  totalCount: number,
  noServiceCount: number | null,
  noServicePercentage: number | null
}

export type NoServiceApiResponse = INoServiceDataPoint[];

export type NoServiceResponse = Array<DataPoint & { totalCount: number, noServiceCount: number }>;

export function getNoServiceIntensity({ timePeriod, ...params }: INoServiceParams) {
  return async (options: RequestInitWithRetry): Promise<NoServiceResponse> => {
    const { from, to } = getTimePeriodParams(timePeriod);

    const input = {
      path: `/api/core/maps/noServiceIntensity`,
      body: {
        ...params,
        from,
        to
      }
    };

    const output = await request<NoServiceApiResponse>(input, options);

    return output.map(dataPoint => {
      const { noServicePercentage: percentage, ...rest } = dataPoint;
      return { percentage, ...rest };
    });
  };
}

export interface ICoverageParams {
  boundingLatLong: string,
  geotilePrecision: number,
  timePeriod: number,
  groups?: string[],
  homeLocations?: string[],
  deviceId?: string,
  zoneId?: string,
  operatorName?: string,
  bearerType: string
}


interface ICoverageDataPoint {
  geotile?: string,
  totalCount: number,
  coverageCount: number | null,
  coveragePercentage: number | null
}

export type CoverageApiResponse = ICoverageDataPoint[];

export type CoverageResponse = Array<DataPoint & { totalCount: number, coverageCount: number }>;

export function getCoverageIntensity({ timePeriod, ...params }: ICoverageParams) {
  return async (options: RequestInitWithRetry): Promise<CoverageResponse> => {
    const { from, to } = getTimePeriodParams(timePeriod);

    const input = {
      path: `/api/core/maps/coverageIntensity`,
      body: {
        ...params,
        from,
        to
      }
    };

    const output = await request<CoverageApiResponse>(input, options);

    return output.map(dataPoint => {
      const { coveragePercentage: percentage, ...rest } = dataPoint;
      return { percentage, ...rest };
    });
  };
}

export interface ISimProvidersParams {
  deviceId?: string,
  timePeriod: number
}

export type SimProvidersResponse = string[];

export function getSimProviders({ timePeriod, ...params }: ISimProvidersParams) {
  return async (options: RequestInitWithRetry): Promise<SimProvidersResponse> => {
    const { from, to } = getTimePeriodParams(timePeriod);

    const input = {
      path: `/api/core/maps/simProviders`,
      body: {
        from,
        to,
        ...params,
      }
    };

    const output = await request<SimProvidersResponse>(input, options);

    return output;
  };
}

export interface IDeviceGeoBoundsParams {
  groups?: string[],
  homeLocations?: string[],
  zoneId?: string
}

export function getDeviceGeoBounds(params: IDeviceGeoBoundsParams) {
  return async (options: RequestInitWithRetry): Promise<GeoBoundsResponse> => {
    const input = {
      path: `/api/core/maps/deviceGeoBounds`,
      body: {
        ...params
      }
    };

    return request<GeoBoundsResponse>(input, options);
  };
}

export interface IDeviceStatsParams {
  groups?: string[],
  homeLocations?: string[],
  zoneId?: string
}

export type DeviceStatsResponse = {
  totalCount: number,
  withLocationCount: number
};

export function getDeviceStats(params: IDeviceStatsParams) {
  return async (options: RequestInitWithRetry): Promise<DeviceStatsResponse> => {

    const input = {
      path: `/api/core/maps/deviceStats`,
      body: { ...params }
    };

    const output = await request<DeviceStatsResponse>(input, options);

    return output;
  };
}


export interface IDeviceClusterParams {
  groups?: string[],
  homeLocations?: string[],
  zoneId?: string,
  boundingLatLong: string,
  geotilePrecision?: number
}

export interface IDeviceCluster {
  keys: string[],
  lat: number,
  lon: number,
  count: number,
  visited: boolean
}

export type DeviceClusterResponse = {
  clusters: IDeviceCluster[],
  precision: number
};

export function getDeviceCluster(params: IDeviceClusterParams) {
  return async (options: RequestInitWithRetry): Promise<DeviceClusterResponse> => {

    const input = {
      path: `/api/core/maps/deviceCluster`,
      body: { ...params }
    };

    const output = {
      clusters: await request<IDeviceCluster[]>(input, options),
      precision: params.geotilePrecision
    };

    return output;
  };
}

export interface IClusterDevicesListParams {
  limit: number,
  offset: number,
  sort?: ISortResult,
  boundingLatLong: string[],
  groups?: string[],
  homeLocations?: string[],
  zoneId?: string
}

export interface IAlertData {
  updated: string,
  deviceStatus: string,
  deviceLocationStatus: string,
  deviceLocationDistanceStatus: string,
  deviceLocationTimeStatus: string,
  deviceLocationAccessPointStatus: string,
  deviceUtilisationStatus: string,
  deviceUtilisationIdleStatus: string,
  deviceUtilisationOutOfContactStatus: string,
  deviceUserExperienceStatus: string,
  deviceUserExperienceRebootStatus: string,
  deviceUserExperienceLowPowerStatus: string,
  deviceUserExperienceDropStatus: string,
  deviceTimeErrorStatus: string,
  averageDischargeStatus: string,
  averageDischarge: number,
  accessPoint: {
    ssid: string,
    bssid: string,
    updated: number,
    homeLocationId: string,
    homeLocation: {
      name: string
    }
  },
  deviceEventCounts: {
    rebootCount: number,
    lowPowerCount: number,
    dropCount: number
  },
  homeLocation: {
    id: string,
    name: string,
    distanceYellow: number,
    distanceRed: number
  }
}

export type ClusterDevicesListItem = {
  id: string,
  serialNumber: string,
  assetTag: string,
  imei: string
} & IAlertData;

export type ClusterDevicesListResponse = {
  list: ClusterDevicesListItem[],
  count: number
};

export function getClusterDevicesList(params: IClusterDevicesListParams) {
  return async (options: RequestInitWithRetry): Promise<ClusterDevicesListResponse> => {

    const { limit, offset, ...rest } = params;

    const input = {
      path: `/api/core/maps/deviceCluster/devicesList`,
      qs: { limit, offset },
      body: { ...rest },
    };

    return await request<ClusterDevicesListResponse>(input, options);
  };
}

export function exportClusterDevicesListAction(options: RequestInitWithRetry) {
  return async (params: IClusterDevicesListParams) => {
    const { limit, offset, ...rest } = params;

    const input = {
      path: `/api/core/maps/deviceCluster/exportDevicesList`,
      qs: { limit, offset },
      body: { ...rest },
    };

    return request(input, options);
  };
}

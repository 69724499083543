import React from "react";
import { useTimeout } from "./useTimeout";

export const useDebounce = (ms: number) => {
  const { addTimeout, timeoutIdsRef } = useTimeout();

  const [isDebouncing, setIsDebouncing] = React.useState(false);

  const addDebounceEvent = (handler: () => Promise<void>) => {
    setIsDebouncing(true);

    if (timeoutIdsRef.current.length > 0) {
      [...timeoutIdsRef.current].forEach(timeoutId => {
        clearTimeout(timeoutId);

        const newTimeoutIds = [...timeoutIdsRef.current];

        const timeoutIndex = newTimeoutIds.indexOf(timeoutId);
        newTimeoutIds.splice(timeoutIndex, 1);

        timeoutIdsRef.current = newTimeoutIds;
      });
    }
    addTimeout(async () => {
      await handler();
      setIsDebouncing(timeoutIdsRef.current.length > 0);
    }, ms);
  };

  return {
    addDebounceEvent,
    isDebouncing
  };
};

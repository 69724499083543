import * as React from 'react';
import { IntensityMap } from './shared';
import { getGeotileLocation } from './shared/utils';
import { ArrayElement } from './shared/types';
import { CoverageResponse, getCoverageIntensity } from '../../../services/core/maps';
import { TooltipContentFC } from './shared/overlayLayer';
import { useTranslation } from '@lib/useTypedTranslation';

export type CoverageDataPoint = ArrayElement<CoverageResponse>;

export type CoverageMapProps = {
  groups?: string[],
  homeLocations?: string[],
  deviceId?: string,
  zoneId?: string,
  simProvider?: string,
  bearerType?: string,
  timePeriod: number
};

export const CoverageTooltipContent: TooltipContentFC<CoverageDataPoint> = props => {
  const { t } = useTranslation(['maps', 'translation']);

  if (props.dataPoint.coverageCount === undefined) {
    return <><p>{t('LOADING', { ns: 'translation' })}</p></>;
  }

  return <>
    <p>{t('LOCATION', { ns: 'maps' })}: {getGeotileLocation(props.dataPoint.geotile)}</p>
    <p>{t('COVERAGE_INTENSITY', { ns: 'maps' })}: {`${props.dataPoint.coverageCount} (${props.dataPoint.percentage}%)`}</p>
    <p>{t('TOTAL_COUNT', { ns: 'maps' })}: {props.dataPoint.totalCount}</p>
  </>;
};

export const CoverageMap: React.FC<CoverageMapProps> = ({ groups, homeLocations, zoneId, deviceId, timePeriod, simProvider, bearerType }) => {
  const fetchMapData = React.useCallback((boundingLatLong: string, geotilePrecision: number) => {
    return getCoverageIntensity({
      boundingLatLong,
      geotilePrecision,
      groups,
      homeLocations,
      zoneId,
      deviceId,
      timePeriod,
      operatorName: simProvider,
      bearerType
    });
  }, [groups, homeLocations, zoneId, deviceId, timePeriod, simProvider, bearerType]);

  return <IntensityMap<CoverageDataPoint>
    TooltipContent={CoverageTooltipContent}
    colorVariant='neutral'
    fetchMapData={fetchMapData}
    groups={groups}
    homeLocations={homeLocations}
    zoneId={zoneId}
    deviceId={deviceId}
    fixMaxBounds
  />;
};

/* istanbul ignore file */
/* react-leaflet incompatible with testing library, tested in cypress! */

import * as React from "react";
import { Circle, LayerGroup, Map, Tooltip } from "react-leaflet";
import classNames from "classnames";

import { DeviceClusterResponse } from "../../../services/core/maps";
import { useClusterProps } from "./utils";
import { ClusterContext } from ".";
import { useSingleClick } from "@lib/useSingleClick";

export interface IClusterProps {
  lat: number,
  lng: number,
  count: number,
  radius: number,
  bounds: string[],
  loading: boolean
}


export const Cluster = (props: IClusterProps) => {
  const { lat, lng, count, radius, loading, bounds } = props;
  const { selectedCluster, setSelectedCluster } = React.useContext(ClusterContext);
  const { handleSingleClick } = useSingleClick();

  const selected = selectedCluster?.join() === bounds.join();

  const onClick = handleSingleClick(() => {
    if (!selected) {
      setSelectedCluster(bounds);
      return;
    }
    setSelectedCluster(null);
  });

  return <Circle
    data-id="cluster"
    data-selected={selected}
    key={`${bounds}/${selected}`}
    center={{ lat, lng }}
    radius={radius}
    className={classNames("cluster", { loading, selected })}
    onclick={onClick}
    data-num={count}
  >
    <Tooltip
      permanent={true}
      offset={[0, 0]}
      direction="bottom"
      className={classNames("cluster-label", { loading, selected })}
    >
      {count}
    </Tooltip>
  </Circle>;
};

interface OverlayLayerProps {
  mapRef: React.MutableRefObject<Map>,
  isLoading: boolean,
  data?: DeviceClusterResponse
}

export const OverlayLayer = ({ data, mapRef, isLoading }: OverlayLayerProps) => {
  const bounds = mapRef.current?.leafletElement?.getBounds();

  const clusterProps = useClusterProps(bounds, data);

  return <LayerGroup>
    {clusterProps.map((clusterProps) => <Cluster {...clusterProps} loading={isLoading} />)}
  </LayerGroup>;
};

import { LatLng, latLng, latLngBounds } from "leaflet";
import * as GeoTile from "geotile";
import tinygradient from "tinygradient";
import tinycolor from "tinycolor2";
import { DataPoint } from "./types";
import { MAX_LATITUDE, MAX_LONGITUDE, MIN_LATITUDE, MIN_LONGITUDE } from "@components/maps/utils";

export const getDiscreteIntensity = (intensity?: number) => {
  if (intensity === undefined) {
    return undefined;
  }

  return Math.floor(intensity / 10) * 10;
};

export const getDiscreteBand = (intensity?: number) => {
  if (intensity === undefined) {
    return undefined;
  }

  const discreteIntensity = getDiscreteIntensity(intensity);

  switch (discreteIntensity) {
  case 0:
    return '0% - 9%';
  case 10:
    return '10% - 19%';
  case 20:
    return '20% - 29%';
  case 30:
    return '30% - 39%';
  case 40:
    return '40% - 49%';
  case 50:
    return '50% - 59%';
  case 60:
    return '60% - 69%';
  case 70:
    return '70% - 79%';
  case 80:
    return '80% - 89%';
  case 90:
  case 100:
    return '90% - 100%';
  }
};

export type ColorVariant = 'negative' | 'neutral';

const gradients = {
  negative: tinygradient([
    { color: tinycolor('rgba(102, 0, 0, 0.7)'), pos: 0 },
    { color: tinycolor('rgba(255, 0, 0, 0.6)'), pos: 0.3 },
    { color: tinycolor('rgba(255, 51, 51, 0.4)'), pos: 0.8 },
    { color: tinycolor('rgba(255, 153, 153, 0.2)'), pos: 1 }
  ]),
  neutral: tinygradient([
    { color: tinycolor('rgba(0, 0, 102, 0.7)'), pos: 0 },
    { color: tinycolor('rgba(0, 0, 255, 0.6)'), pos: 0.3 },
    { color: tinycolor('rgba(51, 51, 255, 0.4)'), pos: 0.8 },
    { color: tinycolor('rgba(153, 153, 255, 0.2)'), pos: 1 }
  ])
};

export const getColorFromIntensity = (variant: ColorVariant, intensity?: number, discrete?: boolean) => {
  if (intensity === undefined) {
    return 'transparent';
  }

  const gradientPosition = 1 - intensity / 100;
  return gradients[variant].rgbAt(gradientPosition).toRgbString();
};

export const getGeotileGridMapData = <TData extends DataPoint>(mapDataResponse: TData[], sw: LatLng, ne: LatLng, precision: number): Partial<TData>[] => {
  const gridGeotiles = GeoTile.tileIdsForBoundingBox({
    north: Math.min(ne.lat, MAX_LATITUDE),
    east: Math.min(ne.lng, MAX_LONGITUDE),
    south: Math.max(sw.lat, MIN_LATITUDE),
    west: Math.max(sw.lng, MIN_LONGITUDE)
  }, precision);

  const result = gridGeotiles.map((tile: any) => {
    // Convert ids used by the GeoTile package to Slippy Geotile names
    const tileParts = tile.split('_');
    const geotile = `${tileParts[0]}/${tileParts[2]}/${tileParts[1]}`;

    const responseDataPoint = mapDataResponse.find(dataPoint => dataPoint.geotile === geotile);

    if (responseDataPoint) {
      return responseDataPoint;
    }

    return {
      geotile,
      percentage: undefined
    } as Partial<TData>;
  });

  return result;
};

export const getGeotileBounds = (geotile: string) => {
  // Convert Slippy Geotile names to ids used by the GeoTile package
  const tileParts = geotile.split('/');
  const tile = GeoTile.tileFromTileId(`${tileParts[0]}_${tileParts[2]}_${tileParts[1]}`);

  return latLngBounds(latLng(tile.latitudeNorth, tile.longitudeWest), latLng(tile.latitudeSouth, tile.longitudeEast));
};

export const getGeotileLocation = (geotile: string) => {
  const tileParts = geotile.split('/');
  const tile = GeoTile.tileFromTileId(`${tileParts[0]}_${tileParts[2]}_${tileParts[1]}`);

  return `${tile.centerLatitude.toFixed(5)}, ${tile.centerLongitude.toFixed(5)}`;
};

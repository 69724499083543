import * as React from "react";
import { useWorldSettingsContext } from "../context/worldSettings";
import { useTranslation } from "./useTypedTranslation";
import { Identifier } from "../services/config/config";

export const useDeviceIdentifier = () => {
  const { t } = useTranslation(['translation']);

  const { worldSettings: { deviceIdentifier } } = useWorldSettingsContext();
  const label = React.useMemo(() => {
    if (deviceIdentifier === Identifier.IMEI) {
      return t('IMEI', { ns: 'translation' });
    }
    if (deviceIdentifier === Identifier.assetTag) {
      return t('ASSET_TAG', { ns: 'translation' });
    }
    return t('SERIAL_NUMBER', { ns: 'translation' });
  }, [deviceIdentifier, t]);

  return {
    label,
    id: deviceIdentifier || Identifier.serialNumber
  };
};

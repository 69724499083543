import React, { useContext, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { get, groupBy, isEqual, pick } from 'lodash';

import { AmChart, coreTheme } from '../../../../../../components/chart/amChart';
import { IDeviceUsage } from '../../../../../../services/core/devicePerformance';
import { NoSelectionOverlay } from '../../../../../../components/card/noSelectionOverlay';
import { addHoverAnimation } from '../../../../../../components/chart/onHoverAnimation';
import { DeviceEvent } from '../../../../../../services/core/eventsTypes';
import { EditEvents, EditEventsAction, useEditEventsDispatch } from '../events/edit-events';
import { deviceEventFilterGroupNames, IDeviceEventFilterState } from '../events/deviceEventsTypes';
import { getDeviceEventFilterGroups } from '../events/deviceEvents';
import { addEventOverlaysToColumnChart } from './lib/eventOverlays';
import { EventsShown } from '../events-shown';
import { DeviceInfoContext } from '../../../index';
import { ChartName, chartNames } from './lib/chartNames';
import { createYAxis } from '../../../../../../lib/usageChartYAxis';
import { useFeatureTogglesContext } from '../../../../../../context/featureToggles';

import './charts.css';
import '../events/deviceEvents.css';
import { formatTooltipDayWithHourRange } from '@components/chart/tooltips';

interface IProps {
  classname: string,
  data: IDeviceUsage[],
  events: DeviceEvent[],
  eventFilters: IDeviceEventFilterState,
  dispatchEventFilters: React.Dispatch<EditEventsAction<ChartName>>,
  visible: boolean,
  onMount: (chart: am4charts.XYChart) => am4charts.XYChart
}

export function DeviceUsage24Hours(props: IProps) {
  const { classname, eventFilters, dispatchEventFilters, onMount } = props;
  const featureToggles = useFeatureTogglesContext();
  const { platformType } = useContext(DeviceInfoContext);
  const { t } = useTranslation(['performance', 'editEvents', 'translation']);
  const [chartDataLoading, setChartDataLoading] = useState(false);
  const [chartReady, setChartReady] = useState(false);

  const {
    handleUpdate, handleApply, handleApplyAll, handleCancel
  } = useEditEventsDispatch(
    dispatchEventFilters, chartNames.deviceUsage24Hours, setLoadingTrue
  );

  const title = t('DEVICE_MINUTES_USAGE_PER_HOUR', { ns: 'performance' });
  const data = getData();

  function setLoadingTrue() {
    setChartReady(false);
    setChartDataLoading(true);
  }

  function onReady() {
    if (!chartDataLoading) {
      setChartReady(true);
    }
  }

  return (
    <div className={`core-device_performance-${classname}-device-usage ${chartReady ? 'chart-ready' : ''}`}>
      <div className="chart-header">
        <NoSelectionOverlay noSelectionText={t('NO_DATA_AVAILABLE', { ns: "translation" })} show={props.data.length === 0} />
        <div className="chart-title">{title}</div>
        <div className="chart-edit-events">
          <EventsShown eventFilters={eventFilters.appliedEventFilters} />
          <EditEvents
            subheader={`‘${title}’`}
            eventGroupNames={deviceEventFilterGroupNames}
            eventFilterGroups={getDeviceEventFilterGroups(platformType, featureToggles)}
            eventFilterState={eventFilters.currentEventFilters}
            handleUpdate={handleUpdate}
            handleApply={handleApply}
            handleCancel={handleCancel}
            handleApplyAll={handleApplyAll}
          />
        </div>
      </div>
      <div className="chart-and-legend-container">
        <div className="chart-container">
          <AmChart
            tag={`performance-${classname}-device-usage-chart`}
            chartProvider={createChart}
            dataProvider={() => { setChartDataLoading(false); return data; } }
            onUpdate={onDataUpdated}
            link={pick(props, 'data', 'events', 'visible')}
            onDataValidated={onReady}
            onMount={onMount}
            cypressId="last24HoursOrSelectedDateDeviceUsage"
          />
        </div>
      </div>
    </div>
  );

  /* istanbul ignore next */
  function createChart(this: any, id: string) {
    am4core.useTheme(coreTheme);

    const chart: am4charts.XYChart = am4core.create(id, am4charts.XYChart);
    chart.data = props.data;
    chart.paddingTop = 40;
    chart.minHeight = 240;

    const dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    dateAxis.dataFields.category = 'date';
    dateAxis.cursorTooltipEnabled = false;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.labels.template.location = 0.5;

    const durationAxis = chart.yAxes.push(new am4charts.DurationAxis());
    const maxDeviceUsage = Math.max(...props.data.map((data) => data.used));
    const maxDeviceUsageHours = maxDeviceUsage / 60 / 60;
    durationAxis.baseUnit = "second";
    durationAxis.renderer.grid.template.disabled = true;
    durationAxis.renderer.labels.template.disabled = true;
    durationAxis.contentAlign = 'right';
    createYAxis(durationAxis, maxDeviceUsageHours, t, true, true);


    const series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.columns.template.fill = am4core.color('#0a5');
    series1.columns.template.stroke = am4core.color('#0a5');
    series1.yAxis = durationAxis;
    series1.dataFields.valueY = 'used';
    series1.dataFields.categoryX = 'date';
    series1.columns.template.width = am4core.percent(90);
    series1.columns.template.tooltipHTML = '';

    series1.columns.template.adapter.add('tooltipHTML', (_, target) => {
      const data: any = target.tooltipDataItem.dataContext;
      const userActivityMinutes = Math.floor((data.used / 60));
      const minutesText = t('MINUTE', { ns: 'timeState', count: userActivityMinutes });
      const date = formatTooltipDayWithHourRange(data.tooltipDate);
      if (userActivityMinutes < 1) {
        return `<span class="device-usage-${classname}-tooltip-title">${date}</span></br><span class="device-usage-${classname}-tooltip-text"><div class="key-green"></div>&nbsp;${t('LESS_THAN_ONE_MINUTE_USAGE', { ns: 'performance' })}</span>`;
      } else {
        return `<span class="device-usage-${classname}-tooltip-title">${date}</span></br><span class="device-usage-${classname}-tooltip-text"><div class="key-green"></div>&nbsp;${t('IN_USE', { ns: 'performance' })}: {valueY.formatDuration("m '${minutesText}'")}</span>`;
      }
    });

    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.text = t('IN_USE', { ns: 'performance' });
    chart.legend.labels.template.fontWeight = '400';

    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 15;
    markerTemplate.height = 15;
    chart.legend.useDefaultMarker = true;

    markerTemplate.disposeChildren();
    const legendItem = markerTemplate.createChild(am4core.Rectangle);
    legendItem.fill = am4core.color('#0a5');
    legendItem.width = 13;
    legendItem.height = 13;

    chart.legend.itemContainers.template.clickable = false;
    chart.legend.itemContainers.template.focusable = false;
    chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;

    addHoverAnimation(series1, chart);
    addEventOverlaysToColumnChart(series1, chart, dateAxis, t, formatTooltipDayWithHourRange);

    return chart;
  }

  /* istanbul ignore next */
  function onDataUpdated(chart: am4charts.XYChart, chartData: any[], prevProps?: any, currentProps?: any) {
    if (!isEqual(get(currentProps, 'link.events'), get(prevProps, 'link.events'))) {
      addEventOverlaysToColumnChart(chart.series.values[0] as am4charts.ColumnSeries, chart, chart.xAxes.values[0] as am4charts.CategoryAxis, t, formatTooltipDayWithHourRange);
      if (!get(currentProps, 'link.visible')) {
        (chart as any).eventsUpdatedWhileHidden = true;
      }
    }
    // If the chart has just become visible and was updated while hidden,
    // invalidate it to force a redraw, as it may have redrawn incorrectly.
    if (get(currentProps, 'link.visible') && !get(prevProps, 'link.visible') && (chart as any).eventsUpdatedWhileHidden) {
      chart.deepInvalidate();
      delete (chart as any).eventsUpdatedWhileHidden;
    }
    return chart;
  }

  function getData() {
    if (!props.data.length) {
      return [];
    }
    const eventsByHour = groupBy(props.events, (event) => moment.utc(event.local).startOf('hour').valueOf());
    return props.data.map(({ date, used }) => {
      return {
        date: moment.utc(parseInt(date)).format('LT'),
        tooltipDate: date,
        used: used || 0,
        events: get(eventsByHour, date, [])
      };
    });
  }
}

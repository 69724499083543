import * as React from 'react';
import { IntensityMap } from './shared';
import { getGeotileLocation } from './shared/utils';
import { ArrayElement } from './shared/types';
import { ZeroSignalLevelResponse, getZeroSignalLevelIntensity } from '../../../services/core/maps';
import { TooltipContentFC } from './shared/overlayLayer';
import { useTranslation } from '@lib/useTypedTranslation';

export type ZeroSignalDataPoint = ArrayElement<ZeroSignalLevelResponse>;

type ZeroSignalMapProps = {
  groups?: string[],
  homeLocations?: string[],
  deviceId?: string,
  zoneId?: string,
  timePeriod: number,
  simProvider?: string
};

export const ZeroSignalTooltipContent: TooltipContentFC<ZeroSignalDataPoint> = props => {
  const { t } = useTranslation(['maps', 'translation']);

  if (props.dataPoint.zeroSignalCount === undefined) {
    return <><p>{t('LOADING', { ns: 'translation' })}</p></>;
  }

  return <>
    <p>{t('LOCATION', { ns: 'maps' })}: {getGeotileLocation(props.dataPoint.geotile)}</p>
    <p>{t('VERY_POOR_NO_SERVICE_INTENSITY', { ns: 'maps' })}: {`${props.dataPoint.zeroSignalCount} (${props.dataPoint.percentage}%)`}</p>
    <p>{t('TOTAL_COUNT', { ns: 'maps' })}: {props.dataPoint.totalCount}</p>
  </>;
};

export const ZeroSignalMap: React.FC<ZeroSignalMapProps> = ({ groups, homeLocations, zoneId, deviceId, timePeriod, simProvider }) => {
  const fetchMapData = React.useCallback((boundingLatLong: string, geotilePrecision: number) => {
    return getZeroSignalLevelIntensity({
      boundingLatLong,
      geotilePrecision,
      groups: groups,
      homeLocations: homeLocations,
      zoneId: zoneId,
      deviceId: deviceId,
      timePeriod: timePeriod,
      operatorName: simProvider
    });
  }, [groups, homeLocations, zoneId, deviceId, timePeriod, simProvider]);

  return <IntensityMap<ZeroSignalDataPoint>
    fetchMapData={fetchMapData}
    TooltipContent={ZeroSignalTooltipContent}
    groups={groups}
    homeLocations={homeLocations}
    zoneId={zoneId}
    deviceId={deviceId}
  />;
};

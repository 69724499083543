import React, { useState, useMemo } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import styled from 'styled-components';

import { DeleteDialogue } from '../../../components/delete/deleteDialogue';
import { ModalPopup } from '../../../components/controls/modalPopup';
import { ButtonColours } from '../../app/themes';
import { Button, Variants } from '../../../components/controls/button';
import { fontWeight } from '../../app/globalStyle';
import { useWait } from '../../../lib/wait';
import { useWorldAction } from '../../../lib/useWorldAction';
import { deleteZonesAction, Zone, ZoneDeleteFailureData } from '../../../services/zones/zones';

const DeleteZoneReasonsList = styled.div`
  padding: 1rem 0 1rem 1rem;

  & > div > b {
    font-weight: ${fontWeight.semiBold};
  }

  & > div:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

const DeleteZoneReasons = styled.div`
  &:not(:first-child) {
    margin-top: 2rem;
  }
`;

export interface Props {
  showPopup: boolean,
  zones: Zone[],
  onClose: () => void,
  onDelete: () => void
}

const ns = 'zones' as const;
const ns2 = 'translation' as const;
const ns3 = 'deleteDialogue' as const;

export function DeleteZones(props: Props) {
  const { t } = useTranslation([ns, ns2, ns3]);
  const { wait } = useWait();
  const { showPopup, onClose, onDelete, zones } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(false);
  const [zonesToDelete, setZonesToDelete] = useState<Zone[]>();
  const [failureData, setFailureData] = useState<ZoneDeleteFailureData>();

  const deleteZones = useWorldAction(deleteZonesAction);

  const count = zonesToDelete?.length || 0;
  if (showPopup && !count && zones.length) {
    setZonesToDelete(zones);
  }

  const getZoneName = (id: string): string => {
    return zonesToDelete.find(zone => zone.id === id)?.name;
  };

  async function handleDelete() {
    setIsDeleting(true);
    let success = false;
    const response = await deleteZones(zones.map(h => h.id));
    // 5 second delay to allow replication of deletion to ES
    await response.onSuccess(async () => wait(5000).then(resetState), true);
    response
      .onSuccess(() => success = true)
      .onFailure(() => setIsDeleting(false))
      .onExpectedFailure((failure) => setFailureData(failure.data))
      .onUnexpectedFailure(() => setError(true));
    onDelete();
    return success;
  }

  const resetState = () => {
    setIsDeleting(false);
    setError(false);
    setFailureData(undefined);
  };

  const handleClose = () => {
    resetState();
    onClose();
  };

  const errorText = t('ERROR_CUSTOM_DELETE', { ns: ns3, item: t('ZONES', { ns: ns, count }) });

  const hasMultiple = count > 1;

  const subheader = useMemo(() => !hasMultiple
    ? t('SUBHEADER_SINGLE', { ns, zone: zonesToDelete?.[0]?.name })
    : t('SUBHEADER', { ns, count }), [zonesToDelete, count, hasMultiple, t]);

  const deleteDialogue = failureData ? null : (
    <DeleteDialogue
      show={showPopup}
      subheader={subheader}
      warning={t('MESSAGE', { ns, count })}
      isDeleting={isDeleting}
      error={error && errorText}
      checkboxRequired={hasMultiple}
      handleDelete={handleDelete}
      handleClose={handleClose}
      count={count}
    />
  );

  const userFailureCount = failureData ? failureData.hasUsers.length : 0;
  const failureDialogue = !failureData ? null : (
    <ModalPopup
      show={showPopup}
      handleClose={handleClose}
      header={t('FAILURE_HEADER', { ns, count })}
      classname="delete-zones-failure"
      closeDisabled={true}
      footer={<div className='footer'>
        <Button
          colour={ButtonColours.grey}
          variant={Variants.ghost}
          dataId="deleteZonesCancel"
          text={t('CANCEL', { ns: ns2 })}
          onClick={handleClose}
        /></div>}>
      <div data-id="deleteZonesFailure">
        {userFailureCount && <DeleteZoneReasons data-id="deleteZonesFailureReasonsUsers">
          <div data-id="deleteZonesFailureReasonsUsersHeader">{t('FAILURE_HEADER_USERS', { ns, count: userFailureCount })}</div>
          <DeleteZoneReasonsList data-id="deleteZonesFailureReasonsUsersList">
            {failureData.hasUsers.map((id) => (
              <div key={id}>{getZoneName(id)}</div>
            ))}
          </DeleteZoneReasonsList>
          <div data-id="deleteZonesFailureReasonsUsersFooter">{t('FAILURE_FOOTER_USERS', { ns })}</div>
        </DeleteZoneReasons>
        }
      </div>
    </ModalPopup>
  );

  return failureData ? failureDialogue : deleteDialogue;
}

import React from 'react';
import { Button, Variants } from '@components/controls/button';
import { Chip } from '@components/controls/chip';
import { DatePicker, ExtendedDatetimepickerProps } from '@components/datePicker/datePicker';
import { ButtonColours } from '../../containers/app/themes';
import moment, { Moment } from 'moment';
import './datePickerButton.css';

export interface IDatePickerButtonProps extends ExtendedDatetimepickerProps {
  isActive: boolean,
  calendarRange: number,
  timeZoneOffset?: number
}

export const DatePickerButton = (props: IDatePickerButtonProps) => {
  const { initialValue, onChange, isActive, value, calendarRange, timeFormat = false, dateFormat = `LLLL 'YY`, timeZoneOffset = 0, ...rest } = props;

  const adjustDate = (day: number) => {
    const newDate = moment.utc(value).add(day, "days");
    onChange(newDate);
  };

  const handleDateChange = (date: string | Moment) => {
    const newDate = moment.utc(date);
    onChange(newDate);
  };

  const handleReset = () => {
    const newDate = moment.utc().add(timeZoneOffset, 'minutes').startOf('day');
    onChange(newDate);
  };

  const isValidDate = (current: Moment) => {
    const to = moment.utc().add(timeZoneOffset, 'minutes');
    return current.isSameOrBefore(to, 'day') && current.isAfter(moment.utc(to).subtract(calendarRange, 'days'), 'day');
  };

  return (
    <DatePicker
      className='core-date-picker_input'
      utc={true}
      dateFormat={dateFormat}
      timeFormat={timeFormat}
      initialValue={initialValue}
      isValidDate={isValidDate}
      onChange={handleDateChange}
      value={value}
      renderInput={(inputProps) => (
        <Chip active={isActive}>
          <div className="chip-date-picker-container">
            <ChevronButton direction="left" onClick={() => adjustDate(-1)} isDisabled={moment.utc(value).isSameOrBefore(moment.utc().subtract(calendarRange - 1, 'days'), 'day')} />
            <div className="core-performance-container-divider" />
            <input {...inputProps} />
            <ResetButton onClick={handleReset} />
            <div className="core-performance-container-divider" />
            <ChevronButton direction="right" onClick={() => adjustDate(1)} isDisabled={moment.utc(value).isSameOrAfter(moment.utc().add(timeZoneOffset, 'minutes'), 'day')} />
          </div>
        </Chip>
      )}
      {...rest}
    />
  );
};

const ChevronButton = ({ direction, onClick, isDisabled }: { direction: 'left' | 'right', onClick: () => void, isDisabled: boolean }) => (
  <Button
    colour={ButtonColours.grey}
    variant={Variants.minimal}
    type="button"
    className={`core-performance_${direction}-chevron-button`}
    dataId={`core-performance_${direction}-chevron-button`}
    iconStyle={`fas fa-chevron-${direction}`}
    ariaLabel={`chevron-${direction}`}
    disabled={isDisabled}
    onClick={onClick}
  />
);

const ResetButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    colour={ButtonColours.grey}
    variant={Variants.minimal}
    type="button"
    className="core-performance_calendar-reset"
    dataId="core-performance_calendar-reset"
    iconStyle="fas fa-undo-alt"
    ariaLabel={`calendar-reset`}
    onClick={onClick}
  />
);

import * as React from 'react';
import { IntensityMap } from './shared';
import { getGeotileLocation } from './shared/utils';
import { ArrayElement } from './shared/types';
import { NoServiceResponse, getNoServiceIntensity } from '../../../services/core/maps';
import { TooltipContentFC } from './shared/overlayLayer';
import { useTranslation } from '@lib/useTypedTranslation';

export type NoServiceDataPoint = ArrayElement<NoServiceResponse>;

type NoServiceMapProps = {
  groups?: string[],
  homeLocations?: string[],
  deviceId?: string,
  zoneId?: string,
  timePeriod: number,
  simProvider?: string
};

export const NoServiceTooltipContent: TooltipContentFC<NoServiceDataPoint> = props => {
  const { t } = useTranslation(['maps', 'translation']);

  if (props.dataPoint.noServiceCount === undefined) {
    return <><p>{t('LOADING', { ns: 'translation' })}</p></>;
  }

  return <>
    <p>{t('LOCATION', { ns: 'maps' })}: {getGeotileLocation(props.dataPoint.geotile)}</p>
    <p>{t('NO_SERVICE_INTENSITY', { ns: 'maps' })}: {`${props.dataPoint.noServiceCount} (${props.dataPoint.percentage}%)`}</p>
    <p>{t('TOTAL_COUNT', { ns: 'maps' })}: {props.dataPoint.totalCount}</p>
  </>;
};

export const NoServiceMap: React.FC<NoServiceMapProps> = ({ groups, homeLocations, zoneId, deviceId, timePeriod, simProvider }) => {
  const fetchMapData = React.useCallback((boundingLatLong: string, geotilePrecision: number) => {
    return getNoServiceIntensity({
      boundingLatLong,
      geotilePrecision,
      groups,
      homeLocations,
      zoneId,
      deviceId,
      timePeriod,
      operatorName: simProvider
    });
  }, [groups, homeLocations, zoneId, deviceId, timePeriod, simProvider]);

  return <IntensityMap<NoServiceDataPoint>
    fetchMapData={fetchMapData}
    TooltipContent={NoServiceTooltipContent}
    groups={groups}
    homeLocations={homeLocations}
    zoneId={zoneId}
    deviceId={deviceId}
  />;
};

import React, { useCallback, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { InitialTableState, useTableReducer } from '../../components/data-table/lib';
import { exportUsersList, getUsersList, IUserData, IUserListResult } from '../../services/users/users';
import { UserCreation } from './user-creation';
import { DeleteUsers } from './compositions/deleteUsers';
import { EditUser } from './compositions/editUser';
import { ActionDenied } from './compositions/actionDenied';
import { useUserSettingsContext } from '../../context/userSettings';
import { useCurrentUserContext } from '../../context/currentUser';
import { useUsersListColumns } from './useUsersListColumns';
import { List } from '../../components/list/list';
import { useWorldRequest } from '../../lib/useWorldRequest';

import './users-list.css';
import useNormalizedHeaderButtons from '../../components/controls/normalizedHeaderButtons';

export const tableName = 'usersList';
export const defaultPageSize = 20;

export function UsersList() {
  const { t } = useTranslation(['userManagement', 'deleteUsers']);
  const { tablePageSizes } = useUserSettingsContext();
  const currentUser = useCurrentUserContext();
  const [showAddUser, setShowAddUser] = useState(false);
  const [showDeleteUsers, setShowDeleteUsers] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [actionDenied, setActionDenied] = useState<'delete' | 'edit'>(undefined);
  const columns = useUsersListColumns();

  const initialTableState: InitialTableState = {
    initialRows: 4,
    limit: tablePageSizes?.[tableName] || defaultPageSize,
  };

  const [tableReducerProperties, tableReducerFunctions] = useTableReducer<IUserData>(tableName, initialTableState);
  const { offset, limit, search, checkedRows, data } = tableReducerProperties;

  const usersListFetcher = useCallback(() => {
    return getUsersList({ limit, offset, search });
  }, [offset, limit, search]);

  const onExportClicked = async (): Promise<string> => {
    return exportUsersList({ search });
  };

  function handleShowDeleteUsers() {
    if (checkedRows.map((index) => data[index].id).includes(currentUser.id)) {
      setActionDenied('delete');
    } else {
      setShowDeleteUsers(true);
    }
  }

  function handleShowEditUsers() {
    if (data[checkedRows[0]].id === currentUser.id) {
      setActionDenied('edit');
    } else {
      setShowEditUser(true);
    }
  }

  const usersListTranslationItems = {
    statusText: {
      key: 'USER',
      ns: 'userManagement'
    },
    tableTitle: {
      key: 'USERS_LIST_TITLE',
      ns: 'userManagement'
    }
  };

  const additionalHeaderButtons = useNormalizedHeaderButtons({
    checkedRows,
    create: {
      dataId: "new-user-button",
      onClick: () => { setShowAddUser(true); }
    },
    delete: {
      dataId: "delete-users-button",
      onClick: handleShowDeleteUsers
    },
    edit: {
      dataId: "open-edit-user-button",
      onClick: handleShowEditUsers
    },
    export: {
      exportFunction: onExportClicked,
      filename: 'userList.csv'
    }
  });

  return (
    <div>
      <ActionDenied
        header={actionDenied === 'delete' ? t('DELETE_DENIED_HEADER', { count: checkedRows.length, ns: 'userManagement' }) : t('EDIT_DENIED_HEADER', { ns: 'userManagement' })}
        subheader={actionDenied === 'delete' ? t('DELETE_DENIED_SUBHEADER', { ns: 'userManagement' }) : t('EDIT_DENIED_SUBHEADER', { ns: 'userManagement' })}
        showPopup={Boolean(actionDenied)}
        handleClose={() => setActionDenied(undefined)}
      />
      <DeleteUsers
        users={checkedRows.map((index) => data[index])}
        showPopup={showDeleteUsers}
        handleClose={() => setShowDeleteUsers(false)}
        onUsersDeleted={tableReducerFunctions.onLoading}
      />
      <UserCreation
        showPopup={showAddUser}
        handleClose={() => setShowAddUser(false)}
        onUserCreation={tableReducerFunctions.onLoading}
      />
      {checkedRows.length === 1 && <EditUser
        showPopup={showEditUser}
        handleClose={() => setShowEditUser(false)}
        handleSave={tableReducerFunctions.onLoading}
        user={data[checkedRows[0]]}
      />}
      <List<IUserData, IUserListResult, 'count', 'list', 'id'>
        dataId='users-list'
        customHeader={false}
        countDataPath='count'
        listDataPath='list'
        additionalHeaderButtons={additionalHeaderButtons}
        hasCheckboxes={true}
        hasFilters={false}
        hasSearch
        translationItems={usersListTranslationItems}
        columns={columns}
        fetcher={usersListFetcher}
        tableReducerFunctions={tableReducerFunctions}
        tableReducerProperties={tableReducerProperties}
        useRequestHook={useWorldRequest}
        options={{ rowIdDataPath: 'id' }}
      />
    </div>
  );
}

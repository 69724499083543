import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import styled from 'styled-components';

import { getAge } from '../../../lib/age';
import { MiniHeader } from '../../../components/details/miniHeader';
import { DetailsItem } from '../../../components/details/detailsItem';
import { LinkButton } from '../../../components/controls/linkButton';
import { encode } from '../../../lib/base64';
import { ViewInCoreButton } from '../../../components/controls/viewInCoreButton';
import { useWorldRoutes } from '../../../routes/parts/allWorldRoutes';
import { useCurrentWorldContext } from '../../../context/currentWorld';
import { Feature } from '../../app/productsWrapper';

interface IProps {
  id: string,
  data: any
}

export interface IViewModel {
  serialNumber: string,
  partNumber: string,
  manufacturer: string,
  manufactureDate: string,
  capacityFactor: string,
  cycleCount: number,
  capacity: string,
  age: string,
  lastSeen: string,
  deviceid: string,
  deviceManufacturer: string,
  deviceModel: string,
  deviceAssetTag: string,
  deviceSerialNumber: string,
  deviceName: string,
  imei: string,
  firstSeen: string
}

const ButtonRow = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
`;

export function BatteryDetailsInformation(props: IProps) {
  const { data } = props;
  const { features } = useCurrentWorldContext();
  const ns = 'batteryEssentials';
  const { t } = useTranslation(['translation', ns]);
  const viewModel: IViewModel = {
    serialNumber: data.serialNumber,
    partNumber: data.partNumber,
    manufacturer: data.manufacturer,
    manufactureDate: data.mfd,
    capacityFactor: data.capacityFactor ? `${Math.round(Number.parseFloat(data.capacityFactor) * 100)}%` : undefined,
    cycleCount: data.cycleCount,
    capacity: (data.presentCapacity && data.ratedCapacity) ? `${data.presentCapacity} / ${data.ratedCapacity}` : undefined,
    age: data.mfd ? getAge(t, moment(data.mfd, "YYYY-MM-DD")) : undefined,
    lastSeen: data.updated ? moment(Number.parseInt(data.updated)).calendar() : undefined,
    deviceid: data.deviceInfo ? data.deviceInfo.id : undefined,
    deviceManufacturer: data.deviceInfo ? data.deviceInfo.manufacturer : undefined,
    deviceModel: data.deviceInfo ? data.deviceInfo.model : undefined,
    deviceAssetTag: data.deviceInfo ? data.deviceInfo.assetTag : undefined,
    deviceSerialNumber: data.deviceInfo ? data.deviceInfo.serialNumber : undefined,
    deviceName: data.deviceInfo ? data.deviceInfo.deviceName : undefined,
    imei: data.deviceInfo ? data.deviceInfo.imei : undefined,
    firstSeen: data.firstSeen ? moment(Number.parseInt(data.firstSeen)).calendar() : undefined
  };
  const deviceId = viewModel.deviceid || '';
  const base64Id = encode(deviceId);
  const linkToBatteryDevices = useWorldRoutes().batteryEssentials.devices;
  const linkToDevices = `${linkToBatteryDevices}?id=${encodeURIComponent(base64Id)}&ts=${moment.utc().valueOf()}`;
  const showViewInDevicesBtn = (
    features.includes(Feature.batteryEssentialsDevices) ||
    features.includes(Feature.panasonicSmartBatteryMonitorDevices)
  );

  return (
    <div className="row details_information h-100">
      <div className="col-md-4 col-sm-12 col-12">
        <MiniHeader title={t('BATTERY_INFORMATION', { ns })} icon="far fa-eye" />
        <DetailsItem data={viewModel} name={t('MANUFACTURER', { ns: 'translation' })} path='manufacturer' />
        <DetailsItem data={viewModel} name={t('PART_NUMBER', { ns })} path='partNumber' />
        <DetailsItem data={viewModel} name={t('SERIAL_NUMBER', { ns: 'translation' })} path='serialNumber' />
        <DetailsItem data={viewModel} name={t('MANUFACTURE_DATE', { ns })} path='manufactureDate' />
        <DetailsItem data={viewModel} name={t('FIRST_SEEN', { ns: 'translation' })} path='firstSeen' />
        <DetailsItem data={viewModel} name={t('LAST_SEEN', { ns: 'translation' })} path='lastSeen' />
      </div>
      <div className="col-md-4 col-sm-12 col-12">
        <MiniHeader title={t('HEALTH', { ns })} icon="fas fa-heartbeat" />
        <DetailsItem data={viewModel} name={t('HEALTH', { ns })} path='capacityFactor' />
        <DetailsItem data={viewModel} name={t('CAPACITY', { ns })} path='capacity' />
        <DetailsItem data={viewModel} name={t('CYCLE_COUNT', { ns })} path='cycleCount' />
        <DetailsItem data={viewModel} name={t('AGE', { ns })} path='age' />
      </div>
      <div className="col-md-4 col-sm-12 col-12">
        <MiniHeader title={t('LAST_KNOWN_DEVICE', { ns })} icon="fas fa-mobile-alt" />
        <DetailsItem data={viewModel} name={t('MANUFACTURER', { ns: 'translation' })} path='deviceManufacturer' />
        <DetailsItem data={viewModel} name={t('MODEL', { ns: 'translation' })} path='deviceModel' />
        <DetailsItem data={viewModel} name={t('SERIAL_NUMBER', { ns: 'translation' })} path='deviceSerialNumber' />
        <DetailsItem data={viewModel} name={t('IMEI', { ns: 'translation' })} path='imei' />
        <DetailsItem data={viewModel} name={t('ASSET_TAG', { ns: 'translation' })} path='deviceAssetTag' />
        <DetailsItem data={viewModel} name={t('DEVICE_NAME', { ns: 'translation' })} path='deviceName' />
        <div className="col">
          <ButtonRow>
            <ViewInCoreButton deviceId={deviceId} />
            {showViewInDevicesBtn &&
              <LinkButton
                dataId="view-in-devices"
                to={linkToDevices}
                external={false}
              >
                {t('VIEW_IN_DEVICES', { ns })}
              </LinkButton>
            }
          </ButtonRow>
        </div>
      </div>
    </div>
  );
}

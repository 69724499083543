import * as React from 'react';
import * as moment from 'moment';

import Card from '@components/card/card';
import { useTranslation } from '@lib/useTypedTranslation';
import { ClusterContext } from './index';
import { CloseButton } from '@components/controls/closeButton';
import { ExportButton } from '@components/controls/exportButton';
import classNames from 'classnames';
import { Loading } from '@components/loading/loading';
import { useWorldRequest } from '@lib/useWorldRequest';
import { exportClusterDevicesListAction, getClusterDevicesList } from '../../../services/core/maps';
import { Link } from 'react-router-dom';
import { encode } from '@lib/base64';
import { useWorldRoutes } from '../../../routes/parts/allWorldRoutes';
import { DataTable } from '@components/data-table/dataTable';
import { SortDirection } from '@components/data-table/types';
import { useWorldAction } from '@lib/useWorldAction';
import { PaginationNavigation } from '@components/data-table/pagination/paginationNavigation';
import { showDeviceStatusTooltip } from '@components/data-table/dataFormatters';
import { useDeviceIdentifier } from '@lib/useDeviceIdentifier';

export const DevicesCard = () => {
  const { t } = useTranslation(['maps', 'translation']);
  const deviceIdentifier = useDeviceIdentifier();
  const routes = useWorldRoutes();

  const { selectedCluster, setSelectedCluster } = React.useContext(ClusterContext);
  const selected = !!selectedCluster;

  const limit = 10;
  const [page, setPage] = React.useState(1);
  const [sortDirection, setSortDirection] = React.useState<SortDirection>();

  const getTableDataFetcher = React.useCallback(() => {
    if (!selectedCluster) {
      return;
    }
    return getClusterDevicesList({
      boundingLatLong: selectedCluster,
      limit,
      offset: (page - 1) * limit,
      sort: sortDirection && {
        field: deviceIdentifier.id,
        order: sortDirection
      }
    });
  }, [selectedCluster, page, deviceIdentifier.id, sortDirection]);
  const { data: tableData, loading: tableLoading } = useWorldRequest(getTableDataFetcher, { initialLoading: true });

  const exportClusterDevicesList = useWorldAction(exportClusterDevicesListAction);
  const onExport = React.useCallback(() => {
    return exportClusterDevicesList({
      boundingLatLong: selectedCluster,
      limit: 1000,
      offset: 0,
      sort: sortDirection && {
        field: deviceIdentifier.id,
        order: sortDirection
      }
    });
  }, [exportClusterDevicesList, selectedCluster, deviceIdentifier.id, sortDirection]);

  const loading = tableLoading;

  return <Card className={classNames('devices-card', { selected })}>
    <div className={'devices-card_header'}>
      <h3>{t("DEVICES_HEADING", { ns: 'maps' })} {selected && tableData?.count && <>({tableData.count})</>}</h3>
      {selected && <div className="devices-card_header-buttons">
        <ExportButton
          className="devices-card_export"
          disabled={loading}
          exportFunction={onExport}
          filename={`clusterDevicesList.csv`}
        />
        <CloseButton
          handleClose={() => setSelectedCluster(null)}
        />
      </div>}
    </div>
    {selected ?
      <div className="devices-card_main">
        <div className="devices-card_inner">
          {loading || !tableData ?
            <Loading isLoading transparentOverlay={false} />
            :
            <div className="devices-card_list">
              <DataTable
                sortedColumnIndex={0}
                sortDirection={sortDirection}
                onSort={(_, direction) => {
                  setPage(1);
                  setSortDirection(direction);
                }}
                columns={[
                  {
                    id: deviceIdentifier.id,
                    dataPath: deviceIdentifier.id,
                    processor: (pdi, { id }) => <Link to={`${routes.core.deviceOverview}?id=${encodeURIComponent(encode(id))}`}>
                      {pdi}
                    </Link>,
                    sortable: true,
                    title: deviceIdentifier.label
                  },
                  {
                    id: 'deviceStatus',
                    dataPath: 'deviceStatus',
                    processor: (value, rowData) => showDeviceStatusTooltip(value, rowData, routes.core.deviceOverview),
                    title: t('STATUS', { ns: 'translation' }),
                    textAlign: 'center',
                    width: '6rem'
                  }
                ]}
                data={tableData.list}
              />
            </div>}
          {!!tableData && <PaginationNavigation
            limit={limit}
            offset={(page - 1) * limit}
            onNewPage={offset => setPage((offset / limit) + 1)}
            total={tableData?.count}
            className='devices-card_pagination'
          />}
        </div>
      </div>
      :
      <p className="devices-card_prompt">{t("CLUSTER_PROMPT", { ns: 'maps' })}</p>}
  </Card>;
};

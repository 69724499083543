import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import { get, isNil } from 'lodash';
import { Link } from 'react-router-dom';

import Spinner from '../../../../../components/loading/loadingSpinner';
import { IPerformanceOverviewData } from '../../../../../services/core/devicePerformance';
import { OverviewDashboard, IOverviewItem } from '../../../../../components/overview/overview';
import { encode } from '../../../../../lib/base64';
import { Button } from '../../../../../components/controls/button';
import { bytesToMegabytes, bytesToGigabytes, roundTo2PlacesFixed } from '../../../../../lib/dataConversion';
import { useWorldRoutes } from '../../../../../routes/parts/allWorldRoutes';

import './devicePerformanceOverview.css';

export interface IProps {
  data: IPerformanceOverviewData,
  dropsAreEnabled?: boolean,
  from?: string,
  to?: string,
  header?: string,
  historic?: boolean
}

type PerformanceOverviewProperties =
  | 'wifiDataUsed'
  | 'mobileDataUsed'
  | 'dropCount'
  | 'rebootCount'
  | 'lowBatteryCount'
  | 'batteryChangedCount'
  | 'timeErrorCount'
  | 'smartBatteries'
  | 'updated';

export function DevicePerformanceOverview(props: IProps): any {
  const ns = 'translation';
  const { t } = useTranslation(['performance', ns]);
  const { data, from, to, header, historic, dropsAreEnabled } = props;
  const lastContact = data && data.updated ? <div className="last-contact">{moment(parseInt(data.updated)).fromNow()}</div> : '-';
  const baseBatteryPath = useWorldRoutes().batteryEssentials.battery;
  function getLabelsArray(): IOverviewItem[] {
    const result = [];
    result.push(
      { label: t('WIFI_DATA_USED', { ns: 'performance' }), value: getDataUsedOrSpinner('wifiDataUsed') },
      { label: t('MOBILE_DATA_USED', { ns: 'performance' }), value: getDataUsedOrSpinner('mobileDataUsed') }
    );
    if (dropsAreEnabled) {
      result.push({ label: t('DROPS', { ns: 'performance' }), value: getValueOrSpinner('dropCount') });
    }
    result.push(
      { label: t('REBOOTS', { ns: 'performance' }), value: getValueOrSpinner('rebootCount') },
      { label: t('LOW_POWER', { ns: 'performance' }), value: getValueOrSpinner('lowBatteryCount') }
    );
    result.push({ label: t('BATTERY_SWAPS', { ns: 'performance' }), value: getValueOrSpinner('batteryChangedCount') });
    result.push({ label: t('TIME_ERRORS', { ns: 'performance' }), value: getValueOrSpinner('timeErrorCount') });
    if (!historic) {
      if (data && data.smartBatteries && data.smartBatteries.length > 1) {
        data.smartBatteries.forEach((_, i) => {
          result.push({
            label: `${t('LAST_KNOWN_BATTERY', { ns: 'performance' })} ${(i + 1)}`,
            value: lastSmartBatteries(i, baseBatteryPath)
          });
        });
      } else {
        result.push({ label: t('LAST_KNOWN_BATTERY', { ns: 'performance' }), value: data ? lastSmartBatteries(0, baseBatteryPath) : <Spinner text='' /> });
      }
      result.push({ label: `${t('LAST_CONTACT', { ns })}`, value: data ? lastContact : <Spinner text='' /> });
    }

    return result;
  }

  const headerText = header || (from && to ? `${from} - ${to}` : '');

  return (
    <div className={`core-device-performance-overview_composition ${data ? 'data-loaded' : ''}`}>
      <div className="core-device-performance-overview_header">
        <div className="header-text">{headerText}</div>
      </div>
      <div className="core-device-performance-content_fixture">
        <OverviewDashboard items={getLabelsArray()} />
      </div>
    </div>
  );

  function getStatusLabel(status: string) {
    if (status === "yellow") return <i className="fas fa-exclamation-triangle yellow_icon enhanced-warning-icon" />;
    if (status === "red") return <i className="fas fa-times-circle red_icon" />;
    if (status === "green") return <i className="fas fa-check green_icon" />;

    return undefined;
  }

  function lastSmartBatteries(index: number, baseBatteryPath: string): JSX.Element {
    if (get(data, 'smartBatteries[0].serialNumber')) {
      const battery = data.smartBatteries[index];
      const base64Id = encode(battery.id);
      return (
        <div>
          <Link to={`${baseBatteryPath}?id=${encodeURIComponent(base64Id)}&ts=${moment().valueOf()}`} className="batteries-list_link">{battery.serialNumber}</Link>
          {getStatusLabel(battery.batteryStatus)}
        </div>
      );
    }
    return <div>-</div>;
  }

  function displayValue(value: any): any {
    if (isNil(value)) {
      return '-';
    }
    return value;
  }

  function getValueOrSpinner(key: PerformanceOverviewProperties) {
    return data ? displayValue(data[key]) : <Spinner text='' />;
  }

  function getDataUsedOrSpinner(key: 'wifiDataUsed' | 'mobileDataUsed') {
    if (!data) {
      return <Spinner text='' />;
    }

    const dataUsed = data[key];
    const oneGigabyte = 1073741824;
    if (!isNil(dataUsed)) {
      if (dataUsed >= oneGigabyte) {
        return `${roundTo2PlacesFixed(bytesToGigabytes(dataUsed))} ${t('GB', { ns })}`;
      }
      return `${roundTo2PlacesFixed(bytesToMegabytes(dataUsed))} ${t('MB', { ns })}`;
    }
    return '-';
  }
}

import { request, RequestInitWithRetry } from '../../../lib/request';
import { ISortResult, VIEW } from '../common';
import { get } from 'lodash';

export interface IBatteryParams {
  statusType: any,
  limit: number,
  offset: number,
  sort?: ISortResult,
  search: string,
  pinnedId?: string,
  filters: IBatteryFilters
}

export interface IBatteryFilters {
  groups?: string[],
  homeLocations?: string[],
  underWarranty: boolean
}

export interface ISmartBatteryStatus {
  id: string,
  serialNumber: string,
  manufacturer?: string,
  updated?: string,
  underWarranty?: boolean,
  partNumber?: string,
  batteryStatus?: string,
  health?: string,
  cycleCount?: number,
  deviceInfo?: {
    deviceName?: string,
    manufacturer?: string,
    model?: string,
    serialNumber?: string,
    imei?: string,
    assetTag?: string,
    group?: string[],
    homeLocationId?: string,
    homeLocation?: {
      id: string,
      name: string
    }
  }
}

export class BatteryCentricService {
  public get = (options: RequestInitWithRetry) => async (params: IBatteryParams): Promise<ISmartBatteryStatus[]> => {
    let result;
    const input = {
      path: `/api/batteryEssentials/getSmartBatteriesStatus`,
      qs: { statusType: params.statusType === VIEW.all ? undefined : params.statusType, limit: params.limit, offset: params.offset, pinnedId: params.pinnedId },
      body: {
        groups: get(params, 'filters.groups'),
        sort: get(params, 'sort'),
        homeLocations: get(params, 'filters.homeLocations'),
        underWarranty: get(params, 'filters.underWarranty'),
        search: get(params, 'search')
      },
      method: 'post'
    };

    result = await request(input, options);
    return result.smartBatteries.nodes;
  };

  public export = (options: RequestInitWithRetry) => async (params: IBatteryParams): Promise<any> => {
    const input = {
      path: `/api/batteryEssentials/exportSmartBatteriesStatus`,
      qs: { statusType: params.statusType === VIEW.all ? undefined : params.statusType, limit: params.limit, offset: params.offset },
      body: {
        groups: get(params, 'filters.groups'),
        sort: get(params, 'sort'),
        homeLocations: get(params, 'filters.homeLocations'),
        underWarranty: get(params, 'filters.underWarranty'),
        search: get(params, 'search')
      },
      method: 'post'
    };

    return await request(input, options);
  };

  public getBatteryEstateData = (options: RequestInitWithRetry) => async (filters: IBatteryFilters, search?: string): Promise<any> => {
    const input = {
      path: `/api/batteryEssentials/getSmartBatteriesWithCount`,
      body: {
        groups: get(filters, 'groups'),
        homeLocations: get(filters, 'homeLocations'),
        underWarranty: get(filters, 'underWarranty'),
        search: search || undefined
      },
      method: 'post'
    };

    return await request(input, options);
  };

  public getSmartBatteryDetails = (options: RequestInitWithRetry) => async (id: string): Promise<any> => {
    const input = {
      path: `/api/batteryEssentials/getSmartBattery`,
      qs: { id },
      method: 'get'
    };

    return await request(input, options);
  };
}
